import { ArrowRight } from 'phosphor-react'
import { Link } from 'react-router-dom'
import { Subtext } from '../../../../Components/Screen/Text/Body'
import { FarmImage } from './FarmImage'
import { TextLeft } from './TextLeft'

export const Header = () => {
  return (
    <section className="flex flex-col items-center justify-around w-full mt-12 md:flex-row  px-2 md:px-20 xl:px-40 gap-12">
      <div className="flex flex-col flex-[2] gap-3 2xl:px-20">
        <TextLeft />
      </div>
      <div className="relative flex flex-col items-center  justify-center mt-5 flex-1">
        <FarmImage />
        <div className="flex flex-col items-start self-start w-full gap-2">
          <Subtext>
            Abra sua conta e registre a vinícola para a
            <Subtext className="!font-bold"> certificação </Subtext>da produção.
          </Subtext>
          <Link
            to="/provenance"
            className="flex flex-row items-center self-end gap-2"
          >
            <Subtext className={'hover:text-attention'}>Como funciona ?</Subtext>

            <ArrowRight size={24} className="text-secondary" />
          </Link>
        </div>
      </div>
    </section>
  )
}
