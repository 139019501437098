import {
  InstagramLogo,
  LinkedinLogo,
  TelegramLogo,
  TwitterLogo,
} from 'phosphor-react'
import { Heading } from '../Screen/Text/Heading'

import { InfoSquare } from './infoSquare'

const size = 48

export default function BePart() {
  return (
    <section className="flex flex-col w-full text-center" id="community">
      <Heading>
        Faça parte da <Heading className="text-attention">Origem RS</Heading>
      </Heading>
      <div className="flex flex-col gap-3 w-full items-center">
        <div className="flex flex-row gap-3 mt-10">
          <InfoSquare
            link={'https://instagram.com/'}
          >
            <InstagramLogo size={size} />
          </InfoSquare>
          <InfoSquare
            link={'https://twitter.com/'}
          >
            <TwitterLogo size={size} />
          </InfoSquare>
        </div>
        <div className="flex flex-row gap-3">
          <InfoSquare
            link={'https://telegram.org/'}
          >
            <TelegramLogo size={size} />
          </InfoSquare>
          <InfoSquare
            link={'https://www.linkedin.com/'}
          >
            <LinkedinLogo size={size} />
          </InfoSquare>
        </div>
      </div>
    </section>
  )
}
