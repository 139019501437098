export const InfoSquare = ({ children, link, ...props }) => {
  return (
    <div
      {...props}
      style={{
        background: 'linear-gradient(135deg, #E5007E 0%, #FFDE8140 100%)',
       
      }}
      className={`bg-primary  relative w-32 h-32 flex justify-center align-middle items-center rounded-2xl transition duration-300 ease-in-out ${link
          ? 'cursor-pointer hover:scale-105 hover:shadow-lg'
          : 'cursor-not-allowed'
        }`}
      onClick={
        link
          ? () => window.open(link, '_blank')
          : () => {
            ''
          }
      }
    >
      {children}
    </div>
  )
}
