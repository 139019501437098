import axios from 'axios'

export const getUserNfts = async (address) => {
  const options = {
    method: 'GET',
    url: `https://${process.env.REACT_APP_alchemy_chain}.g.alchemy.com/nft/v3/${process.env.REACT_APP_alchemy_apiKey}/getNFTsForOwner?owner=${address}&contractAddresses[]=${process.env.REACT_APP_nft_contract}&withMetadata=true&pageSize=100`,
  }

  return axios
    .request(options)
    .then(function (response) {
      const nfts = response?.data?.ownedNfts
      nfts.forEach((nft) => {
        nft.token_id = nft?.tokenId
        nft.token_uri = nft?.tokenUri
        nft.metadata = JSON?.stringify(nft?.raw?.metadata)
        nft.raw.metadata = nft.metadata
      })
      return nfts
    })
    .catch(function (error) {
      console.error(error)
    })
}
