import 'swiper/css'
import 'swiper/css/grid'
import { BackgroundImage } from './BackgroundImage'
import { UserInfo } from './UserInfo'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Grid } from 'swiper'
import { Card } from '../Mobile/Collection'
import NFTList from '../NFTList'
import Footer from '../../../../Components/Footer/Index'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import NFTCard from '../NFTCard'
import { ListCollection } from './ListCollection'
import { FadersHorizontal, GridFour, List } from 'phosphor-react'
import { Text } from '../../../../Components/Screen/Text/Body'
import { useGlobalContext } from '../../../../Context/GlobalContext'
import { returnUserRole } from '../../../../utils/returnUserRole'
import { ButtonOutline } from '../../../../Components/Screen/Button/button'
import { getAllRequests } from '../../../../utils/firebase/requests'
import { useQuery } from '@tanstack/react-query'
import { getAllFarms } from '../../../../utils/firebase/farms'
import { Loading } from '../../../../Components/Screen/Loading/Loading'

export const DesktopPage = (props) => {
  const { userData: data } = useGlobalContext()
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const selected = queryParams.get('selected');
  const selectedDocuments = selected ? selected.split(',') : []
  const {
    options,
    setOptions,
    showType,
    setShowType,
    allNfts,
    nftsCreated,
    maxMediaQuery,
    collection
  } = props

  function handleInfos(farm) {
    if (selectedDocuments?.length > 0) {
      navigate(`/farms/${farm?.id}?selected=${selectedDocuments.join(',')}`)
    } else {
      navigate(`/farms/${farm?.id}`)
    }
  }

  async function submit() {
    if (selectedDocuments?.length === 0) {
      alert('Selecione ao menos um documento')
      return
    } else {
      navigate(`/production/register?selected=${selectedDocuments.join(',')}`)
    }
  }

  const userRole = returnUserRole(data?.role)

  const { data: requests } = useQuery({
    queryKey: ['requests'],
    queryFn: () =>
      getAllRequests()
        .then((items) => {
          return items
        })
    ,
    enabled: !!userRole && userRole === 'Associação',
  })
  const { data: farms } = useQuery({
    queryKey: ['farms'],
    queryFn: () =>
      getAllFarms()
        .then((items) => {
          return items
        })
    ,
    enabled: !!userRole && userRole === 'Associação',
  })

  if (!requests && !farms && userRole === "Associação") return <Loading />

  return (
    <div className="flex flex-col justify-between relative">
      <div className="flex-grow min-h-[1550px]">
        <BackgroundImage {...props} />

        <UserInfo {...props} />

        <div className="absolute left-[26rem] top-[17rem] w-[35rem] lg:w-[w-45rem] 2xl:w-[65rem] xl:w-[50rem] flex flex-col gap-6">
          {userRole === 'Associação' &&
            <div className="flex flex-col gap-6">
              <Text className="text-attention">Solicitações</Text>

              <Text className={"text-yellow-500"}>
                Solicitações pendentes
              </Text>
              {requests?.filter((item) => item.status === 'in_progress')?.length === 0 && <Text>Nenhuma solicitação encontrada</Text>}
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                grid={{ rows: 6, fill: 'row' }}
                modules={[Grid]}
                className="mySwiper w-full h-full"
              >
                {requests?.filter((item) => item.status === 'in_progress')?.map((item) => {
                  return <SwiperSlide key={item.id} className="">
                    <div className="flex select-none items-center justify-between border-b border-b-cinza ">
                      <Text>
                        Produtores:
                        {item.harvestDeclaration.supplier.map((item) => item.name).join(', ')}
                      </Text>

                      <Text
                        className="hover:cursor-pointer hover:text-attention text-gray-50"
                        onClick={() => navigate(`/production/approve/${item.id}`)}
                      >
                        Analisar solicitação
                      </Text>
                    </div>
                  </SwiperSlide>
                })}
              </Swiper>

              <Text className={"text-green-500"}>
                Solicitações aprovadas
              </Text>
              {requests?.filter((item) => item.status === 'approved')?.length === 0 && <Text>Nenhuma solicitação encontrada</Text>}
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                grid={{ rows: 6, fill: 'row' }}
                modules={[Grid]}
                className="mySwiper w-full h-full"
              >
                {requests?.filter((item) => item.status === 'approved')?.map((item) => {
                  return <SwiperSlide key={item.id} className="">
                    <div className="flex select-none items-center justify-between border-b border-b-cinza ">
                      <Text>
                        Produtores:
                        {item.harvestDeclaration.supplier.map((item) => item.name).join(', ')}
                      </Text>

                      <Text
                        className="hover:cursor-pointer hover:text-attention text-gray-50"
                        onClick={() => navigate(`/production/view/${item.id}`)}
                      >
                        Ver documentos
                      </Text>
                    </div>
                  </SwiperSlide>
                }
                )}
              </Swiper>

              <Text className={"text-red-500"}>
                Solicitações rejeitadas
              </Text>
              {requests?.filter((item) => item.status === 'rejected')?.length === 0 && <Text>Nenhuma solicitação encontrada</Text>}
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                grid={{ rows: 6, fill: 'row' }}
                modules={[Grid]}
                className="mySwiper w-full h-full"
              >
                {requests?.filter((item) => item.status === 'rejected')?.map((item) => {
                  return <SwiperSlide key={item.id} className="">
                    <div className="flex select-none items-center justify-between border-b border-b-cinza ">
                      <Text>
                        Produtores:
                        {item.harvestDeclaration.supplier.map((item) => item.name).join(', ')}
                      </Text>
                      <Text className="text-red-500">Motivo: <span className='text-zinc-100'>{item.reason}</span></Text>
                      <Text
                        className="hover:cursor-pointer hover:text-attention text-gray-50"
                        onClick={() => navigate(`/production/view/${item.id}`)}
                      >
                        Ver
                      </Text>
                    </div>
                  </SwiperSlide>
                }
                )}
              </Swiper>
            </div>
          }

          {userRole !== "Associação" && (<>
            <div className="flex gap-3 text-lg w-full justify-between">
              <span
                className={`cursor-pointer ${options === 'NFTCollected' ? 'text-attention' : 'text-white'} ${userRole !== 'Vinícola' && 'hidden'}`}
                onClick={() => setOptions('NFTCollected')}
              >
                Coleção de NFTs
              </span>

              <span
                className={`cursor-pointer ${options === 'CollectionManagement'
                  ? 'text-attention'
                  : 'text-white'
                  }`}
                onClick={() => setOptions('CollectionManagement')}
              >
                Gerenciamento de produção
              </span>
              {userRole === 'Produtor' && <Link to="/farm/register">Registrar produção</Link>}
            </div>

            <div className="flex gap-3  w-full  justify-between">
              <div className="flex gap-5">
                <GridFour
                  size={24}
                  className={`cursor-pointer ${showType === 'Card' ? 'text-white' : 'text-cinza'
                    }`}
                  onClick={() => setShowType('Card')}
                />

                <List
                  size={24}
                  className={`cursor-pointer ${showType === 'List' ? 'text-white ' : 'text-cinza'
                    }`}
                  onClick={() => setShowType('List')}
                />
              </div>

              <Text className="flex flex-row items-center gap-2 ">
                Filtrar por <FadersHorizontal size={24} />
              </Text>
            </div>

            <div className="flex flex-col gap-6 h-full w-full">
              {showType === 'Card' ? (
                <>
                  <div className="flex flex-col gap-6">
                    {options === 'NFTCollected' && (
                      <Swiper
                        slidesPerView={maxMediaQuery ? 2 : 3}
                        spaceBetween={30}
                        grid={{ rows: 2, fill: 'row' }}
                        modules={[Grid]}
                        className="mySwiper w-full h-full"
                      >
                        {allNfts?.map((item) => (
                          <SwiperSlide key={item?.token_id} className="">
                            <NFTCard nft={item} />
                          </SwiperSlide>
                        ))}
                        {allNfts?.length === 0 && (
                          <Text>Nenhum NFT encontrado</Text>
                        )}
                      </Swiper>
                    )}
                    {options === 'NFTCreated' && (
                      <Swiper
                        slidesPerView={maxMediaQuery ? 2 : 3}
                        spaceBetween={30}
                        grid={{ rows: 2, fill: 'row' }}
                        modules={[Grid]}
                        className="mySwiper w-full h-full"
                      >
                        {nftsCreated?.map((item) => (
                          <SwiperSlide key={item?.token_id} className="">
                            <NFTCard nft={item} />
                          </SwiperSlide>
                        ))}
                        {nftsCreated?.length === 0 && (
                          <Text>Nenhum NFT encontrado</Text>
                        )}
                      </Swiper>
                    )}
                    {options === 'CollectionManagement' && (
                      <Swiper
                        slidesPerView={maxMediaQuery ? 2 : 3}
                        spaceBetween={30}
                        grid={{ rows: 2, fill: 'row' }}
                        modules={[Grid]}
                        className="mySwiper w-full h-full"
                      >
                        {collection?.map((item, index) => (
                          <SwiperSlide key={index} className="">
                            <Card
                              item={item}
                              handleInfos={handleInfos}
                              data={data}
                            />
                          </SwiperSlide>
                        ))}
                        {collection?.length === 0 && (
                          <Text>Nenhuma produção encontrada</Text>
                        )}
                      </Swiper>
                    )}
                  </div>
                </>
              ) : (
                showType === 'List' && (
                  <>
                    <div className="flex flex-col gap-6">
                      {options === 'NFTCollected' && (
                        <Swiper
                          slidesPerView={1}
                          spaceBetween={30}
                          grid={{ rows: 6, fill: 'row' }}
                          modules={[Grid]}
                          className="mySwiper w-full h-full"
                        >
                          {allNfts?.map((item) => (
                            <SwiperSlide key={item.token_id} className="">
                              <NFTList nft={item} />
                            </SwiperSlide>
                          ))}
                          {allNfts?.length === 0 && (
                            <Text>Nenhum NFT encontrado</Text>
                          )}
                        </Swiper>
                      )}
                      {options === 'NFTCreated' && (
                        <Swiper
                          slidesPerView={1}
                          spaceBetween={30}
                          grid={{ rows: 6, fill: 'row' }}
                          modules={[Grid]}
                          className="mySwiper w-full h-full"
                        >
                          {nftsCreated?.map((item) => (
                            <SwiperSlide key={item.token_id} className="">
                              <NFTList nft={item} />
                            </SwiperSlide>
                          ))}
                          {nftsCreated?.length === 0 && (
                            <Text>Nenhum NFT encontrado</Text>
                          )}
                        </Swiper>
                      )}
                      {options === 'CollectionManagement' && (
                        <Swiper
                          slidesPerView={1}
                          spaceBetween={30}
                          grid={{ rows: 6, fill: 'row' }}
                          modules={[Grid]}
                          className="mySwiper w-full h-full"
                        >
                          {collection?.map((item, index) => (
                            <SwiperSlide key={index} className="">
                              <ListCollection
                                item={item}
                                handleInfos={handleInfos}
                              />
                            </SwiperSlide>
                          ))}
                          {collection?.length === 0 && (
                            <div>Nenhuma produção encontrada</div>
                          )}
                        </Swiper>
                      )}
                    </div>
                  </>
                )
              )}
            </div></>)}

          {userRole === 'Vinícola' && selectedDocuments?.length > 0 && (
            <ButtonOutline
              onClick={submit}
              className="w-full"
            >
              Gerar documentos {selectedDocuments?.length > 0 ? `(${selectedDocuments?.length})` : "(0)"}
            </ButtonOutline>
          )}
        </div>



      </div>
      <Footer />
    </div>
  )
}
