import QRCode from "qrcode.react"
import { ButtonOutline } from "../../../Components/Screen/Button/button";

export const QrCodeNftComponent = ({ token_id }) => {
    const downloadQR = () => {
        const canvas = document.getElementById("qrcode");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `qrcode-nft-${token_id}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div className="w-full z-50 flex flex-col gap-4 items-center justify-center">
            <QRCode
                id="qrcode"
                size={150}
                value={process.env.REACT_APP_LOCAL_ENV === 'dev' ? `http://localhost:3000/nft/${token_id}` : `https://origemrs.sebraers.com.br/nft/${token_id}`}
            />
            <ButtonOutline onClick={downloadQR}>Download QRCode</ButtonOutline>
        </div>
    )
}
