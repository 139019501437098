import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from './Pages/Home/Index'
// import ArtworksPage from './Pages/farms/Index'
import FarmsViewPage from './Pages/farms/View/Index'
import ErrorPage from './Pages/Error/Error'
import { useGlobalContext } from './Context/GlobalContext'
import LoginPage from './Pages/Login/LoginPage'
import NavbarDesktop from './Components/Navbar/Desktop'
import NavbarMobile from './Components/Navbar/Mobile'
import ProfilePage from './Pages/Profile/Index'
import ProvenancePage from './Pages/Provenance/Provenance'
// import RegisterDocumentsPage from './Pages/Register/Documents/RegisterDocuments'
import RegisterPage from './Pages/Register/Register'
import WhitepaperPage from './Pages/Whitepaper/Whitepaper'
import { MarketplacePage } from './Pages/Marketplace/Marketplace'
import EditProfilePage from './Pages/Profile/EditProfile/Index'
import { ApproveProductionPage } from './Pages/Production/Approve/ApproveProductionPage'
import RegisterFarmPage from './Pages/Farm/Register/Step2/RegisterFarmPage'
import FarmRegisterSuccessPage from './Pages/Farm/Register/Success/FarmRegisterSuccess'
import HarvestRegisterPage from './Pages/Farm/Register/Step3/HarvestRegisterPage'
import ApproveHarvestSuccess from './Pages/Farm/Register/Success/ApproveHarvestSuccess'
import HarvestRegisterSuccess from './Pages/Farm/Register/Success/HarvestRegisterSuccess copy'
import { ViewProductionPage } from './Pages/Production/View/ViewProductionPage'
import NFTViewPage from './Pages/NFT/ViewPage/Index'

export default function RoutesApp() {
  const { mediaQuery } = useGlobalContext()

  return (
    <BrowserRouter>
      <div className={`w-full h-20 bg-gradient-to-r from-black to-black/40`}>
        {mediaQuery === 'mobile' ? <NavbarMobile /> : <NavbarDesktop />}
      </div>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        {/* <Route path="/register/documents" element={<RegisterDocumentsPage />} /> */}
        <Route path="/farm/register" element={<RegisterFarmPage />} />
        <Route path="/farm/register/success" element={<FarmRegisterSuccessPage />} />

        <Route path="/production/register" element={<HarvestRegisterPage />} />
        
        <Route path='/nft/:tokenId' element={<NFTViewPage />} />
        <Route path="/production/register/success" element={<HarvestRegisterSuccess />} />
        <Route path="/production/approve/:requestId" element={<ApproveProductionPage />} />
        <Route path="/production/view/:requestId" element={<ViewProductionPage />} />
        <Route path="/production/approve/success" element={<ApproveHarvestSuccess />} />
        <Route path="/marketplace" element={<MarketplacePage />} />
        <Route path="/whitepaper" element={<WhitepaperPage />} />
        <Route path="/provenance" element={<ProvenancePage />} />
        {/* <Route path="/farms" element={<ArtworksPage />} /> */}
        <Route path="/farms/:artId" element={<FarmsViewPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="/profile/editProfile" element={<EditProfilePage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  )
}
