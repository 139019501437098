import { Infos } from './components/ArtInfos'
import { CircleLight } from '../../../Components/Screen/circleLight'
import { useGlobalContext } from '../../../Context/GlobalContext'
import { WineryModal } from './modals/WineryDocuments'
import { useState } from 'react'
import { returnUserRole } from '../../../utils/returnUserRole'
import { AddWineryInFarm } from '../../Farm/Register/AddWineryInFarm'
import { getWineriesFromFarmId } from '../../../utils/firebase/get-wineries-from-farm-id'
import { AskForHarvestModal } from '../../Production/Register/AskForHarvestModal'
import { getHarvestsFromFarmId } from '../../../utils/firebase/harvests'
import { useQuery } from '@tanstack/react-query'
import { ProductionModal } from './modals/ProductionDocuments'
import { AssociateModal } from './modals/AssociateModal'
import SimpleFooter from '../../../Components/SimpleFooter/Index'
import { Loading } from '../../../Components/Screen/Loading/Loading'

export default function FarmsViewPage() {
  const { userData, farms } = useGlobalContext()
  const [showModalDocuments, setShowModalDocuments] = useState(false)

  const farmsArray = farms && Object.values(farms)
  const filter = window.location.pathname.split('/')[2]
  const farm = farmsArray?.find(farm => farm.id === filter)
  const userRole = returnUserRole(userData?.role)

  const { data: wineries } = useQuery({
    queryKey: ['wineries'],
    queryFn: () =>
      getWineriesFromFarmId(farm?.id).then((wineries) => {
        wineries = wineries.filter(winery => winery.id !== userData?.id)
        return wineries
      })
    ,
    enabled: !!userData?.id && !!farm?.id
  })


  const { data: harvests } = useQuery({
    queryKey: ['harvests'],
    queryFn: () =>
      getHarvestsFromFarmId(farm?.id).then((harvests) => {
        return harvests
      })
    ,
    enabled: !!userData?.id && !!farm?.id
  })

  const image = farm?.crocImage || "https://images.unsplash.com/photo-1553361371-9b22f78e8b1d?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHdpbmV8ZW58MHx8MHx8fDA%3D"

  if (!wineries || !harvests) return <Loading />

  return (
    <div className="bg-preto min-h-[calc(100vh_-_80px)] flex justify-between flex-col relative pt-8">
      <CircleLight position={' top-0 left-0'} />
      <CircleLight position={' top-0 right-0'} />
      <CircleLight position={' bottom-[30%] left-0'} />
      <CircleLight position={' bottom-[30%] right-0'} />

      <div className="relative flex-grow items-center flex flex-col z-20">
        <img src={image}
          alt=''
          className="w-full max-w-[700px] h-96 object-cover object-center rounded-3xl cursor-pointer mb-3"
          title='Clique para ampliar a imagem em outra aba'
          onClick={
            () => window.open(image, "_blank")
          }
        />

        {userRole === 'Produtor' &&
          <div className='flex items-center gap-4 my-4'>
            <AddWineryInFarm farm={farm} />
            <AskForHarvestModal farm={farm} />
          </div>
        }
        <Infos {...{ farm }} />

        {userRole === 'Produtor' && <div className='flex flex-col text-start py-4 min-w-[165px]'>
          <p className="text-white text-2xl font-semibold text-start">Vinícolas associadas: {wineries?.length ?? 0}</p>
          <div className="flex flex-col gap-2">
            {wineries && wineries?.map((winery) => (
              <div key={winery?.id} className="flex flex-col gap-2">
                <p className="text-white text-xl font-semibold">Nome: {winery?.name}</p>
              </div>
            ))}
          </div>
        </div>}



        <div className='pb-20 pt-4'>
          {userRole === 'Produtor' && <ProductionModal farm={farm} showModalDocuments={showModalDocuments} setShowModalDocuments={setShowModalDocuments} harvests={harvests} />}
          {userRole === 'Vinícola' && <WineryModal farm={farm} showModalDocuments={showModalDocuments} setShowModalDocuments={setShowModalDocuments} harvests={harvests} />}
          {userRole === 'Associação' && <AssociateModal farm={farm} harvests={harvests} setShowModalDocuments={setShowModalDocuments} showModalDocuments={showModalDocuments} />}
        </div>

      </div>
      <SimpleFooter />
    </div>
  )
}
