import { useGlobalContext } from '../../../../Context/GlobalContext'
import { useNavigate, useLocation } from 'react-router-dom'
import { Text } from '../../../../Components/Screen/Text/Body'

export default function Collection() {
  const { userData, farms } = useGlobalContext()
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const selected = queryParams.get('selected');
  const selectedDocuments = selected ? selected.split(',') : []

  let collection = []
  const userFarmIds = userData?.farms || []

  if (farms) {
    if (userData?.role !== 3) {
      collection = Object.values(farms).filter(farm => userFarmIds.includes(farm.id));
      if (selectedDocuments.length > 0) {

        collection = collection.map(col => {
          const matchingHarvests = col.harvests.filter(harvest => selectedDocuments.includes(harvest));
          return {
            ...col,
            matchingHarvestsCount: matchingHarvests.length
          };
        });
      }
    } else {
      collection = Object.values(farms)
      if (selectedDocuments.length > 0) {
        collection = collection.map(col => {
          const matchingHarvests = col.harvests.filter(harvest => selectedDocuments.includes(harvest));
          return {
            ...col,
            matchingHarvestsCount: matchingHarvests.length
          };
        });
      }
    }
  } else {
    collection = []
  }

  function handleInfos(farm) {
    if (selectedDocuments?.length > 0) {
      navigate(`/farms/${farm?.id}?selected=${selectedDocuments.join(',')}`)
    } else {
      navigate(`/farms/${farm?.id}`)
    }
  }

  return (
    <section className="py-6 px-4">
      <div className="w-full grid sm:grid-cols-2 md:grid-cols-3 gap-5">
        {collection?.map((item, index) => (
          <Card item={item} handleInfos={handleInfos} key={index} data={userData} />
        ))}
        {collection?.length === 0 && (
          <div className="w-full flex items-center text-white justify-center">
            <Text>Sem produções</Text>
          </div>
        )}
      </div>
    </section>
  )
}

export const Card = ({ item, handleInfos, data }) => {
  return (
    <div className="flex flex-col relative h-96 select-none">
      <img
        src={item?.crocImage || "https://images.unsplash.com/photo-1553361371-9b22f78e8b1d?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHdpbmV8ZW58MHx8MHx8fDA%3D"}
        alt={item?.name}
        className="w-full  rounded-t-lg object-cover object-center h-96"
        loading="eager"
      />
      <div className="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-preto" />
      <div className="flex flex-col absolute z-20 bottom-16  left-0 pl-2 text-xs justify-between text-cinzaclaro">
        <span
          onClick={() => handleInfos(item)}
          className="text-xl font-semibold text-branco cursor-pointer hover:text-attention"
        >
          {item?.name}
        </span>
        <span>Reg.: {item?.matricula || "Não informado"}</span>
        <span>PRA: {item?.pra || "Não informado"}</span>
        <span>CAR: {item?.car || "Não informado"}</span>
      </div>

      <div className="absolute bottom-0 z-30 flex flex-row text-branco font-normal justify-between w-full px-6 py-4 items-center bg-accent rounded-b-lg text-center">
        <div></div>
        <Text
          className="hover:cursor-pointer hover:text-attention text-center"
          onClick={() => handleInfos(item)}
        >
          {item?.matchingHarvestsCount > 0 ? `Selecionadas (${item?.matchingHarvestsCount})` : "Ver"}
        </Text>
      </div>
    </div>
  )
}
