import { Link } from 'react-router-dom'
import { Subtext } from '../Screen/Text/Body'

export default function SimpleFooter() {
  const year = new Date().getFullYear()
  return (
    <footer className="flex w-full static bottom-0 text-center justify-center items-center bg-transparent">
      <Subtext className={'text-cinza'}>
        &copy; {year} - Origem RS - Todos Direitos Reservados -{' '}
        <Link to="#">Termos de uso</Link> -{' '}
        <Link to="#">Política de Privacidade</Link>
      </Subtext>
    </footer>
  )
}
