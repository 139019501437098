import { child, get, ref } from "firebase/database";
import { db } from "../../firebase";

export async function getWineriesFromFarmId(farmId) {
  const dbRef = ref(db);
  const usersSnapshot = await get(child(dbRef, `/users`));
  if (usersSnapshot?.exists()) {
    const usersSnap = usersSnapshot.val();
    const users = Object.values(usersSnap).filter(user => user?.farms?.some(farm => farm === farmId));
    return users;
  } else {
    return []
  }
}

export async function getWineryById(wineryId) {
  const dbRef = ref(db);
  const wineriesSnapshot = await get(child(dbRef, `/users`));
  if (wineriesSnapshot?.exists()) {
    const wineriesSnap = wineriesSnapshot.val();
    const wineries = Object.values(wineriesSnap).filter(winery => winery?.id === wineryId);
    return wineries[0];
  } else {
    return []
  }
}
