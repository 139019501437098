export const OptionRadio = ({ setOptions, options, userRole }) => {
  return (
    <div className="flex flex-row justify-between text-xs font-medium pt-6 px-2">
      <button
        className={`active:text-attention focus:text-attention lg:text-2xl ${options === 'NFTCollected' ? 'text-attention' : ''
          }
        ${userRole !== 'Vinícola' && 'hidden'}
        `}
        onClick={() => {
          setOptions('NFTCollected')
        }}
      >
        Coleção de NFTs
      </button>


      <button
        className={`active:text-attention focus:text-attention lg:text-2xl ${options === 'CollectionManagement' ? 'text-attention' : ''
          }
          
          `}
        onClick={() => {
          setOptions('CollectionManagement')
        }}
      >
        Gerenciamento de coleção
      </button>
    </div>
  )
}
