import seeds from '../../../assets/seeds.json'
import { X, File, FilePlus } from "phosphor-react";
import { Button, ButtonOutline } from "../../../Components/Screen/Button/button";
import { Subheading } from "../../../Components/Screen/Text/Heading";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { auth, db, storage } from "../../../firebase";
import { useGlobalContext } from "../../../Context/GlobalContext";
import { getWineriesFromFarmId } from "../../../utils/firebase/get-wineries-from-farm-id";
import { v4 as uuidv4 } from 'uuid';
import { askForHarvestLog } from "../../../utils/logs/ask-for-harvest";
import { useMutation } from "@tanstack/react-query";
import { getHarvestsFromFarmId } from "../../../utils/firebase/harvests";
import { queryClient } from "../../..";
import { InputCrop } from "../Approve/FirstFormModal";
import { validateSize } from "../../../utils/validateSizeFile";
import { get, ref, update } from 'firebase/database'
import { getDownloadURL, uploadBytesResumable, ref as refStorage } from 'firebase/storage'
import { Loading } from '../../../Components/Screen/Loading/Loading';

export function AskForHarvestModal({ farm }) {
  const { userData } = useGlobalContext()
  const farmId = farm?.id;
  const [selectedWinerys, setSelectedWinerys] = useState('');
  const [addWineyModal, setAddWineyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [winerys, setWinerys] = useState([{}])
  const [seed_type, setSeedType] = useState('')
  const [wine_cellar_id, setWineCellarId] = useState('')
  const [responsible_name, setResponsibleName] = useState('')
  const [crocImage, setCrocImage] = useState(null)
  const [error, setError] = useState('')
  const [uploadProgress, setUploadProgress] = useState(0)

  const mutation = useMutation({
    mutationFn: () => getHarvestsFromFarmId(farmId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['harvests'] })
    },
  })

  function handleModal() {
    setAddWineyModal(!addWineyModal);
  }

  async function saveWinerys() {
    try {
      setIsLoading(true)
      if (selectedWinerys?.length > 0) {
        const harvestId = uuidv4()
        const wineryId = selectedWinerys
        const winerySnapshot = await get(ref(db, `users/${wineryId?.trim()}`));
        const winery = winerySnapshot.val();

        let crocImageUrl = ''
        if (crocImage) {
          const metadata = {
            contentType: crocImage.type,
          }

          const storageRef = refStorage(storage, 'images/' + auth?.currentUser?.uid + '/crocImages/' + harvestId)

          const uploadTask = uploadBytesResumable(storageRef, crocImage, metadata)

          crocImageUrl = await new Promise((resolve, reject) => {
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                // Calculate the progress of the current file
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadProgress(progress)
              },
              (error) => {
                reject(error)
              },
              async () => {
                await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                  resolve(url)
                })
              }
            )
          })
        }

        if (winery?.id) {
          try {
            await update(ref(db, `harvests/${harvestId}`), {
              id: harvestId,
              crocImage: crocImageUrl,
              wineryId,
              farmId,
              responsibleId: userData?.id,
              responsibleName: responsible_name,
              documents: [],
              seed_type,
              status: 'pending',
              wine_cellar_id,
              date: new Date().toISOString()
            })

            await update(ref(db, `farms/${farmId}`), {
              harvests: [...(farm?.harvests || []), harvestId]
            })

            await update(ref(db, `users/${wineryId}`), {
              harvests: [...(winery?.harvests || []), harvestId]
            })

            await askForHarvestLog(wineryId, farmId, harvestId)
            mutation.mutate(harvestId)
          } catch (err) {
            console.error(err)
          }
        }
      }
      setSelectedWinerys([])
      handleModal()
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getWineriesFromFarmId(farm?.id).then((res) => {
      let winerys = res.map((winery) => {
        return {
          name: winery?.name,
          id: winery?.id
        }
      })
      winerys = winerys.filter(winery => winery.id !== userData?.id)
      setWinerys(winerys)
    })

  }, [farm?.id, userData?.id, addWineyModal])

  return (
    <>
      <ButtonOutline onClick={handleModal} type="button" className={"w-[200px]"}>
        Solicitar plantio
      </ButtonOutline>
      {addWineyModal && (
        <div className="modal modal-open w-full backdrop-blur">
          <div className="relative  bg-preto border border-gray-500 rounded-3xl p-4 lg:p-8 w-[90%] max-w-[600px]">

            <X
              size={32}
              onClick={handleModal}
              className="absolute  text-attention right-4 top-4 hover:cursor-pointer z-30"
            />

            <Subheading as="h1" className=" text-attention my-4 text-center">
              Insira os dados da sua produção
            </Subheading>

            <div className="flex flex-col gap-2 overflow-auto h-[90%] justify-between">
              {isLoading ? <Loading className={"h-[50vh]"} /> : <div className="flex flex-col gap-4">
                <Dropdown optionLabel="name" optionValue="id" placeholder="Selecione a vinícola"
                  filter
                  value={selectedWinerys}
                  onChange={(e) => setSelectedWinerys(e.value)}
                  options={winerys}
                  className="calendarInput"
                />
                <Dropdown
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Selecione o tipo da semente"
                  filter
                  options={seeds}
                  value={seed_type}
                  onChange={(e) => setSeedType(e.value)}
                  className="calendarInput mt-4"
                />
                <InputCrop
                  name="Cadastro Vitícola"
                  value={wine_cellar_id}
                  onChange={(e) => setWineCellarId(e.target.value)}
                />
                <InputCrop name="Nome do contato da vinícola"
                  value={responsible_name}
                  onChange={(e) => setResponsibleName(e.target.value)}
                />

                <input
                  type="file"
                  id="crocImage"
                  accept="image/jpg, image/png, image/jpeg, image/tiff"
                  className="hidden"
                  onChange={(e) => {
                    validateSize(e.target.files[0], setError, setCrocImage)
                    setCrocImage(e.target.files[0])
                  }
                  }
                />

                {error && <p className="text-red-500">{error}</p>}

                <div className='flex gap-10 items-center justify-center'>
                  <div className='flex flex-col items-center relative'>
                    {crocImage && (
                      <div className="flex justify-center pt-2">
                        <img
                          src={URL.createObjectURL(crocImage)}
                          alt="preview"
                          className="object-cover object-center w-full h-32"
                          loading="eager"
                        />
                      </div>
                    )}
                    <label
                      htmlFor="crocImage"
                      className="cursor-pointer text-secondary flex items-center flex-col gap-3"
                      onClick={() => setError(null)}
                    >
                      {crocImage ? <File size={40} /> : <FilePlus size={40} />}
                      <Button type="button">
                        Polígono
                      </Button>
                    </label>
                    {uploadProgress < 1 && crocImage &&
                      <X
                        size={28}
                        onClick={() => setCrocImage(null)}
                        className="text-error cursor-pointer absolute -bottom-10"
                      />
                    }


                  </div>
                </div>

              </div>}

              <div className="flex w-full justify-end">
                <ButtonOutline
                  type="button"
                  onClick={saveWinerys}
                  disabled={isLoading || !selectedWinerys || !seed_type || !responsible_name || !crocImage }
                >
                  Solicitar
                </ButtonOutline>
              </div>


            </div>
          </div>
        </div >
      )
      }
    </>
  )

}