import { useEffect, useState } from 'react'
import { Subheading } from '../../../../Components/Screen/Text/Heading'
import { Text } from '../../../../Components/Screen/Text/Body'
import { useGlobalContext } from '../../../../Context/GlobalContext'
import { returnUserRole } from '../../../../utils/returnUserRole'
import { returnStatusHarvest } from '../../../../utils/return-status-harvest'
import { getUserById } from '../../../../utils/firebase/users'
import { ButtonOutline } from '../../../../Components/Screen/Button/button'
import { useNavigate, useLocation } from 'react-router-dom'
import { formatSeedTypeName } from '../../../../utils/format-seed-type-name'

export function WineryModal({ harvests, farm }) {
  const history = useNavigate();
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const selected = queryParams.get('selected');
  const selectedDocumentsProps = selected ? selected.split(',') : []

  const { userData } = useGlobalContext()
  const [documents, setDocuments] = useState([])
  const [userNames, setUserNames] = useState({});

  const [documentsSelected, setDocumentsSelected] = useState(selectedDocumentsProps ?? [])

  const userRole = returnUserRole(userData?.role)
  useEffect(() => {
    if (userRole === 'Produtor') {
      const harvestsFiltered = harvests?.filter(harvest => harvest?.farmId === farm?.id)
      setDocuments(harvestsFiltered || [])
    }

    if (userRole === 'Vinícola') {
      const harvestsFiltered = harvests?.filter(harvest => harvest?.wineryId === userData?.id)
      setDocuments(harvestsFiltered || [])
    }
  }, [farm?.id, harvests, userData?.id, userRole])



  useEffect(() => {
    const fetchUserNames = async () => {
      const newNames = {};
      for (const document of documents) {
        const user = await getUserById(document.wineryId);
        newNames[document.wineryId] = user?.name;
      }
      setUserNames(newNames);
    };

    if (documents?.length > 0) {
      fetchUserNames();
    }
  }, [documents]);



  async function submit() {
    const documentsIds = documents.map(doc => doc.id);
    const updatedDocumentsSelected = selectedDocumentsProps.filter(id => !documentsIds.includes(id));
    const selectedDocumentsSet = new Set([...updatedDocumentsSelected, ...documentsSelected]);
    const selectedDocuments = Array.from(selectedDocumentsSet).join(',');
    if (selectedDocuments?.length === 0) {
      history(`/profile`);
    } else {
      history(`/profile?selected=${selectedDocuments}`);
    }
  }

  async function clearSubmit() {
    // const documentsIds = documents.map(doc => doc.id);
    // const updatedDocumentsSelected = selectedDocumentsProps.filter(id => !documentsIds.includes(id));
    // const selectedDocumentsSet = new Set(updatedDocumentsSelected);
    // const selectedDocuments = Array.from(selectedDocumentsSet).join(',');
    // if (selectedDocuments?.length === 0) {
    //   history(`/profile`);
    // } else {
    //   history(`/profile?selected=${selectedDocuments}`);
    // }
    setDocumentsSelected([])
  }
  return (
    <div className='flex flex-col space-y-6'>
      <Subheading className="text-attention lg:!text-3xl text-center">
        Solicitações ({documents?.length ?? 0})
      </Subheading>
      <div className="w-full">
        {documents?.length > 0 ? (
          documents.map((document) => {
            const crocImage = document?.crocImage ?? 'https://images.unsplash.com/photo-1543051932-6ef9fecfbc80?q=80&w=1924&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
            const status = returnStatusHarvest(document?.status)
            return <div key={document?.id}>
              <div

                className="flex text-center flex-col md:flex-row items-center gap-1 md:gap-6 mb-4 w-full lg:text-xl "
              >
                {crocImage && (
                  <div className="flex justify-center pt-2"
                    onClick={() => window.open(crocImage, '_blank')}
                  >
                    <img
                      src={crocImage}
                      alt="preview"
                      className="object-cover object-center w-32 h-32 hover:cursor-pointer rounded-3xl"
                      title='Clique para visualizar a imagem em tamanho maior'
                      loading="eager" />
                  </div>
                )}
                <div className="flex flex-col flex-1 font-semibold text-sm md:text-lg text-start ">
                  <Text>Vinícola: {userNames[document?.wineryId]} id:{document?.id.slice(0, 4)}</Text>
                  <Text>Responsável na vinícola: {document?.responsibleName}</Text>
                  <Text>Uva: {formatSeedTypeName(document?.seed_type)}</Text>
                  <Text>Cadastro Vitícola: {document?.wine_cellar_id}</Text>
                </div>

                <div className="flex flex-col flex-1 text-xs md:text-base ">
                  <Text className={``}>Status: <span className={`
                    ${document?.status === "approved" && "!text-green-500"}
                    ${document?.status === "pending" && "!text-blue-500"}
                    ${document?.status === "in_progress" && "!text-yellow-500"}
                    ${document?.status === "rejected" && "!text-red-500"}
                    `}>
                    {status}
                  </span>
                  </Text>
                  <Text>Data: {new Date(document?.date).toLocaleDateString()}</Text>
                </div>
                {document?.status === "pending" &&
                  <>
                    <input
                      type="checkbox"
                      name="document"
                      id={document?.id}
                      value={document?.id}
                      checked={documentsSelected.includes(document?.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDocumentsSelected([...documentsSelected, document?.id])
                        } else {
                          setDocumentsSelected(documentsSelected.filter((doc) => doc !== document?.id))
                        }
                      }}
                      className="checkbox checkbox-accent border-attention hover:border-attention checked:hover:border-accent rounded-xl"
                    />
                    <label htmlFor={document?.id}>Selecionar</label>
                  </>
                }
              </div><div className="border-t-2 border-gray-500 w-full my-8"></div>
            </div>
          })
        ) : (
          <div className="flex flex-col items-center justify-center">
            <Text>Sem documentos</Text>
          </div>
        )}
      </div>
      <div className="flex justify-center items-center gap-12">

        <ButtonOutline
          className="w-full max-w-[170px]"
          onClick={() => {
            submit()
          }}
        >
          Ok
        </ButtonOutline>
        {documentsSelected?.length > 0 &&
          <ButtonOutline
            className="w-full max-w-[170px]"
            type="button"
            onClick={clearSubmit}>
            Limpar
          </ButtonOutline>
        }
      </div>
    </div>
  )
}
