import { EnvelopeSimple, X } from 'phosphor-react'
import { useState } from 'react'
import { ButtonOutline } from '../../../Components/Screen/Button/button'
import { InputDiv, Input } from '../../../Components/Screen/Input/Text'
import { Subtext } from '../../../Components/Screen/Text/Body'
import usePasswordReset from '../../../utils/firebase/forgotPassword'

export const ForgotPassModal = (props) => {
  const { setShowModal, showModal } = props
  const [email, setEmail] = useState('')
  const { error, loading, success, sendPasswordResetEmail } = usePasswordReset()

  return (
    showModal && (
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur bg-black/30">
        <div className="relative w-auto my-6 mx-auto max-w-sm ">
          <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-preto outline-none focus:outline-none border-cinza border">
            <div className="flex items-start justify-between p-4">
              <Subtext>
                Iremos enviar um e-mail com um link para redefinir sua senha
              </Subtext>
              <X
                size={32}
                className="text-attention cursor-pointer"
                onClick={() => setShowModal(!showModal)}
              />
            </div>

            <div className="relative p-6 flex-auto">
              <InputDiv>
                <EnvelopeSimple size={24} />
                <Input
                  type="email"
                  value={email}
                  placeholder="E-mail"
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                  required
                />
              </InputDiv>
              {error && (
                <Subtext className={'text-rose-400'}>
                  Ocorreu um erro ao tentar enviar o e-mail de redefinição de
                  senha. Por favor, verifique sua conexão com a internet e tente
                  novamente.
                </Subtext>
              )}
              {loading && <Subtext>Enviando e-mail...</Subtext>}
              {success && (
                <Subtext className={'text-emerald-400'}>
                  E-mail de redefinição de senha enviado com sucesso!
                </Subtext>
              )}
            </div>
            <div className="flex items-center justify-end p-6  rounded-b">
              <ButtonOutline
                type="submit"
                onClick={(e) => {
                  e.preventDefault()
                  sendPasswordResetEmail(email)
                }}
                disabled={loading || success || email === ''}
              >
                Enviar
              </ButtonOutline>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
