import { X } from "phosphor-react";
import { ButtonOutline } from "../../../Components/Screen/Button/button";
import { Subheading } from "../../../Components/Screen/Text/Heading";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { getAllWinerys } from "../../../utils/firebase/get-types-of-user";
import { get, ref, update } from "firebase/database";
import { db } from "../../../firebase";
import { useGlobalContext } from "../../../Context/GlobalContext";
import { registerWineryToFarmLog } from "../../../utils/logs/register-winery-to-farm";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../..";

export function AddWineryInFarm({ farm }) {
  const { userData } = useGlobalContext()
  const farmId = farm?.id;
  const [selectedWinerys, setSelectedWinerys] = useState([]);
  const [addWineyModal, setAddWineyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const [winerys, setWinerys] = useState([{}])
  const mutation = useMutation({
    mutationFn: () => true,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['wineries'] })
    },
  })


  function handleModal() {
    setAddWineyModal(!addWineyModal);
  }

  function addWinery(e) {
    const winery = e.value;
    const isWineryInList = selectedWinerys.some(wineryItem => wineryItem === winery);
    if (isWineryInList) {
      return
    }
    setSelectedWinerys([...selectedWinerys, e.value])
  }

  async function saveWinerys() {
    try {
      setIsLoading(true)
      if (selectedWinerys?.length > 0) {
        selectedWinerys?.forEach(async (wineryId) => {
          // Fetch the winery data from the database using wineryId
          const winerySnapshot = await get(ref(db, `users/${wineryId?.trim()}`));
          const winery = winerySnapshot.val();

          if (winery?.id) {
            try {
              const checkIfWineryIsAlreadyInFarm = winery?.farms?.some(farm => farm === farmId);
              if (checkIfWineryIsAlreadyInFarm) {
                return
              }
              await update(ref(db, `users/${wineryId}`), {
                farms: [...(winery?.farms || []), farmId]
              })
              await registerWineryToFarmLog(wineryId, farmId, userData?.id)
              await mutation.mutateAsync()
            } catch (err) {
              console.error(err)
            }
          }
        })
      }
      setSelectedWinerys([])
      handleModal()
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }


  useEffect(() => {
    getAllWinerys().then((res) => {
      const winerys = res.map((winery) => {
        return {
          name: winery.name,
          id: winery.id
        }
      })
      setWinerys(winerys)
    })

  }, [])

  return (
    <>
      <ButtonOutline onClick={handleModal} type="button" className={"w-[200px]"}>
        {selectedWinerys?.length > 0 ? `Adicionar mais vinícolas (${selectedWinerys?.length} adicionada${selectedWinerys?.length > 1 ? 's' : ''})` : `Adicionar vinícolas`}
      </ButtonOutline>
      {addWineyModal && (
        <div className="modal modal-open w-full backdrop-blur">
          <div className="relative  bg-preto border border-gray-500 rounded-3xl p-4 lg:p-8">

            <X
              size={32}
              onClick={handleModal}
              className="absolute  text-attention right-4 top-4 hover:cursor-pointer z-30"
            />

            <Subheading as="h1" className=" text-attention my-4 text-center">
              Selecione as vinícolas que você deseja adicionar
            </Subheading>

            <div className="flex flex-col gap-2 overflow-auto h-[90%] justify-between">
              <div>

                <Dropdown optionLabel="name" optionValue="id" placeholder="Selecione a vinícola"
                  filter
                  onChange={addWinery}
                  options={winerys}
                  className="calendarInput"
                />
                <div className="flex flex-col gap-3">
                  <div className="flex items-center gap-3">

                    {selectedWinerys?.map((winery, index) => (
                      <span className="flex items-center mt-2 p-2 border border-cinza max-w-fit rounded-3xl" key={index}>{winerys.find((wineryItem) => wineryItem.id === winery)?.name}
                        <X size={16}
                          onClick={() => {
                            const newWinerys = selectedWinerys.filter((wineryItem) => wineryItem !== winery)
                            setSelectedWinerys(newWinerys)
                          }}
                          className="hover:cursor-pointer text-red-500 ml-2 "
                        />
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex w-full justify-end">

                <ButtonOutline
                  type="button"
                  onClick={saveWinerys}
                  disabled={isLoading}
                >Adicionar</ButtonOutline>
              </div>


            </div>
          </div>
        </div>
      )}
    </>
  )

}