import { Subtext } from '../../../Components/Screen/Text/Body'

export const Partner = ({ name, description, image, url }) => {
  return (
    <div className="flex flex-col gap-3 items-center mt-3 " >
      <img src={image} alt={name} className="2xl:max-w-64 rounded-3xl cursor-pointer" onClick={() => window.open(url, '_blank')} />
      <Subtext>{description}</Subtext>
    </div>
  )
}
