import axios from 'axios'

export const refreshNFTMetadata = async ({ token_id }) => {
  const options = {
    method: 'GET',
    url: `https://deep-index.moralis.io/api/v2.2/nft/${process.env.REACT_APP_nft_contract}/${token_id}/metadata/resync`,
    params: {
      chain: process.env.REACT_APP_blockchain_chain,
      format: 'decimal',
    },
    headers: {
      accept: 'application/json',
      'X-API-Key':
        process.env.REACT_APP_moralis_apiKey,
    },
  }

  return axios
    .request(options)
    .then(function (response) {
      const nfts = response?.data
      return nfts
    })
    .catch(function (error) {
      console.error(error)
    })
}
