import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { auth } from '../../../firebase'
import { logout } from '../../../utils/firebase/logout'

export default function DropdownMobile() {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  async function dropdownLogout() {
    await logout()
    setIsOpen(false)
    navigate('/')
  }
  const loginConditional = auth?.currentUser?.uid ? (
    <li onClick={dropdownLogout}>
      <span>Deslogar</span>
    </li>
  ) : (
    <>
      <li onClick={() => setIsOpen(false)}>
        <Link to="/login">Entrar</Link>
      </li>
      <li onClick={() => setIsOpen(false)}>
        <Link to="/register">Cadastrar</Link>
      </li>
    </>
  )
  return (
    <div>
      {isOpen ? (
        <div className="flex flex-col text-sm">
          <MenubarClosed
            className={`hover:cursor-pointer`}
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          />

          <div className="dropdown dropdown-open ">
            <ul className="menu dropdown-content  -right-5 top-7 w-52  rounded-lg flex flex-col justify-center items-center  bg-black">
              {loginConditional}
              <li onClick={() => setIsOpen(false)}>
                <Link to="/">Início</Link>
              </li>
              {/* <li onClick={() => setIsOpen(false)}>
                <Link to="/pecuarist">Pecuário</Link>
              </li> */}
              {/* <li onClick={() => setIsOpen(false)}>
                <Link to="/farms">Vinícolas</Link>
              </li> */}
              <li onClick={() => setIsOpen(false)}>
                <Link to="/marketplace">Marketplace</Link>
              </li>
              <li onClick={() => setIsOpen(false)}>
                <Link to="/provenance">Como funciona</Link>
              </li>

              <li onClick={() => setIsOpen(false)}>
                <Link to="/whitepaper">Whitepaper</Link>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <Menubar
          className={`hover:cursor-pointer `}
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        />
      )}
    </div>
  )
}

const Menubar = ({ className, onClick }) => {
  return (
    <svg
      width="27"
      height="20"
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <rect
        x="0.149536"
        y="0.496338"
        width="26.2948"
        height="3.19482"
        rx="1.59741"
        fill="white"
      />
      <rect
        x="4.87933"
        y="8.4834"
        width="21.565"
        height="3.19482"
        rx="1.59741"
        fill="white"
      />
      <rect
        x="9.67157"
        y="16.4705"
        width="16.7728"
        height="3.19482"
        rx="1.59741"
        fill="white"
      />
    </svg>
  )
}

const MenubarClosed = ({ className, onClick }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <rect
        x="3.19507"
        y="0.994141"
        width="29.139"
        height="4.16271"
        rx="2.08136"
        transform="rotate(45 3.19507 0.994141)"
        fill="white"
      />
      <rect
        x="0.072998"
        y="21.8086"
        width="29.139"
        height="4.16271"
        rx="2.08136"
        transform="rotate(-45 0.072998 21.8086)"
        fill="white"
      />
    </svg>
  )
}
