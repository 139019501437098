import { child, get, ref } from "firebase/database";
import { db } from "../../firebase";

export async function getProducerByFarmId(farmId) {
  const dbRef = ref(db);
  const usersSnapshot = await get(child(dbRef, `/users`));
  const farmsSnapshot = await get(child(dbRef, `/farms`));
  if (usersSnapshot?.exists() && farmsSnapshot?.exists()) {
    const usersSnap = usersSnapshot.val();
    const farmsSnap = farmsSnapshot.val();
    const farm = Object.values(farmsSnap).find(farm => farm?.id === farmId);
    const owner = Object.values(usersSnap).find(user => user?.id === farm?.ownerId);
    return owner;
  } else {
    return []
  }
}

export async function getAllFarms() {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `/farms`));
  if (snapshot?.exists()) {
    const farms = snapshot.val();
    const items = Object.values(farms);
    return items;
  } else {
    return []
  }
}