import { useState } from 'react'
import { Text } from '../../../Components/Screen/Text/Body'

export default function NFTCard({ nft }) {
  const [show, setShow] = useState(false)
  const [errorImage, setErrorImage] = useState(false)
  const item = JSON.parse(nft?.metadata || '{}')

  // function getOpenSeaLink() {
  //   if (process.env.REACT_APP_LOCAL_ENV === "dev") {
  //     return `https://testnets.opensea.io/assets/amoy/${process.env.REACT_APP_nft_contract}/${nft?.token_id}`
  //   }
  //   return `https://opensea.io/assets/matic/${process.env.REACT_APP_nft_contract}/${nft?.token_id}`
  // }

  function viewNFTDetails() {
    window.open('/nft/' + nft?.token_id, '_blank', 'noopener', 'noreferrer')
  }

  return (
    <>
      <div className="flex flex-col h-96 select-none cursor-pointer"
        onClick={viewNFTDetails}
        title='Clique para ver mais detalhes'
      >
        <img
          src={item?.image}
          alt={item?.name}
          className={`w-full h-full rounded-t-lg object-fill object-center relative ${show ? '' : 'animate-pulse bg-white/40'
            }`}
          onLoad={() => setShow(true)}
          onError={() => {
            setErrorImage(true)
            setShow(true)
          }}
          loading="eager"
        />
        {errorImage && (
          <div className="absolute top-[50%] pl-5  z-40 text-white">
            Falha ao carregar, por favor recarregue a página
          </div>
        )}
        {!show && (
          <div className="absolute z-40 text-white top-[50%] pl-5 ">
            Carregando a partir da Blockchain, por favor aguarde...
          </div>
        )}

        {show && (
          <>
            <div className="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-black" />
            <div className="flex flex-col absolute z-20 bottom-16  left-0 pl-2 text-xs">
              {item?.attributes?.map((attribute) => (
                <span
                  className="text-xs text-white"
                  key={attribute?.trait_type}
                >
                  {attribute?.trait_type}: {attribute?.value}
                </span>
              ))}
            </div>
            <Text className="flex flex-row text-branco font-light justify-center w-full py-4 items-center bg-emerald-800 rounded-b-lg absolute bottom-0">
              {item.name || 'Nome do NFT'}
            </Text>
          </>
        )}
      </div>
    </>
  )
}
