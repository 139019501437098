import { Heading } from '../../../Components/Screen/Text/Heading'
import { Partner } from './Partner'

export const Partners = () => {
  return (
    <div className="flex flex-col gap-10 bg-black/20 p-10 rounded-2xl ">
      <Heading>
        Nossos <span className="text-attention"> Afiliados</span>
      </Heading>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 justify-center items-center">
        {partnersList?.map((partner) => (
          <Partner key={partner.name} {...partner} />
        ))}
      </div>
    </div>
  )
}

const partnersList = [
  {
    name: 'Aprovale',
    description:
      '',
    image: require('../assets/aprovale.jpg'),
    url: 'https://www.valedosvinhedos.com.br/'
  },
  {
    name: 'DomCandido',
    description:
      '',

    image: require('../assets/domcandido.jpg'),
    url: "https://www.domcandido.com.br/index"
  },

  // {
  //   name: 'Miolo',
  //   description:
  //     '',
  //   image: require('../assets/Miolo.jpg'),
  // },
  {
    name: 'Pizzato',
    description:
      '',
    image: require('../assets/Pizzato.jpg'),
    url: "https://www.pizzato.net/"
  },
]
