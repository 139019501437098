import { Subtext } from '../../../Screen/Text/Body'

export const Infos = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <article className="flex flex-col  gap-2">
        <Subtext>Fique atualizado!</Subtext>
        <input
          type="text"
          placeholder="✉ Email"
          className="bg-transparent w-full max-w-xs border-b py-2 focus:outline-none"
        />
      </article>
    </div>
  )
}
