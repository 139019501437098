import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { SvgStep4 } from '../assets/SvgStep4'

export const Step4 = ({ mediaQuery }) => {
  return (
    <article className="flex flex-col gap-4 my-10 lg:flex-row lg:justify-between lg:items-center">
      {mediaQuery && (
        <img
          src={require('../assets/step4.jpg')}
          alt="art"
          className="w-1/3 h-[20rem] rounded-3xl"
          loading="eager"
        />
      )}
      <div className="flex flex-col gap-3">
        <Text className="flex flex-row items-center gap-4">
          <SvgStep4 />
          <Text className="flex flex-col ">
            <Text className="flex flex-col text-white">
              Etapa 4
              <Heading className="text-attention"> Especialistas</Heading>
            </Text>
          </Text>
        </Text>
        <ul className="px-6 font-medium list-disc lg:text-lg">
          <li>
            <Text>
              Estudo por Especialistas para reconhecer os padrões da
              vinícola, respeitando suas fases
            </Text>
          </li>
          <li>
            <Text>Utiliza algoritmos e aprendizado de máquina</Text>
          </li>
          <li>
            <Text>Considera estudos de pigmentos, madeira, idade da uva</Text>
          </li>
          <li>
            <Text>
              Estudos de raio-x, luz multi espectral e outros métodos
              científicos de análise
            </Text>
          </li>
        </ul>
      </div>
    </article>
  )
}
