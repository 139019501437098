import styled from '@emotion/styled'

const SubmitButton = styled.button`
  display: block;
  width: 100%;
  font-size: inherit;
  background-color: ${(props) => (props.disabled ? '#6b3b08' : '#4931A1')};
  color: ${(props) => (props.disabled ? 'black' : 'white')};
  border-radius: 15px;
  border: none;
  color: white;
  font-weight: 600;
  padding: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-top: 1rem;
`

export default SubmitButton
