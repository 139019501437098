import './assets/page.css'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import SimpleFooter from '../../Components/SimpleFooter/Index'
import { auth } from '../../firebase'
import { InputForm } from './components/InputForm'
import { Checkbox } from './components/Checkbox'
import { SendButton } from './components/SendButton'
import { ArrowLeft } from 'phosphor-react'
import { useRegister } from '../../utils/firebase/register'
import { Heading } from '../../Components/Screen/Text/Heading'
import { createWallet } from '../../utils/web3/createWallet'
import { CircleLight } from '../../Components/Screen/circleLight'

export default function RegisterPage() {
  const { error, loading, register } = useRegister()
  const [registerEmail, setRegisterEmail] = useState('')
  const [registerPassword, setRegisterPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState()
  const [cnpj, setCnpj] = useState()
  const [zipCode, setZipCode] = useState('')
  const [address, setAddress] = useState('')
  const [number, setNumber] = useState('')
  const [complement, setComplement] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')
  const [role, setRole] = useState(0)

  const userId = auth?.currentUser?.uid
  const navigate = useNavigate()
  const [walletLoading, setWalletLoading] = useState(false)
  const [showPassword, setShowPassword] = useState('')

  useEffect(() => {
    if (userId) {
      navigate('/')
    }
  }, [navigate, userId])

  const props = {
    setRegisterEmail,
    setRegisterPassword,
    setPasswordConfirm,
    setFirstName,
    setLastName,
    setPhone,
    setCnpj,
    setZipCode,
    setAddress,
    setCity,
    setState,
    setCountry,
    setShowPassword,
    showPassword,
    registerEmail,
    registerPassword,
    passwordConfirm,
    firstName,
    lastName,
    phone,
    cnpj,
    zipCode,
    address,
    city,
    state,
    country,
    error,
    loading,
    register,
    walletLoading,
    number,
    setNumber,
    complement,
    setComplement,
    role
  }

  async function onRegister(e) {
    try {
      e.preventDefault()
      if (role === 0) return alert('Selecione o tipo de conta')
      setWalletLoading(true)
      const res = await createWallet()
      setWalletLoading(false)
      await register({
        email: registerEmail,
        password: registerPassword,
        firstName,
        lastName,
        phone: phone ?? 'nao informado',
        cnpj,
        zipCode,
        address,
        number,
        complement,
        city,
        state,
        country,
        role,
        wallet: res.wallet.address,
        key: res.privateKey,
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="bg-preto min-h-[calc(100vh_-_80px)] flex flex-col justify-between relative">
      <CircleLight position={'top-[10%] right-0'} />
      <CircleLight position={'bottom-[10%] left-0'} />
      <div className="flex flex-col items-center justify-center pt-10 lg:px-20 xl:px-40 px-4 w-full z-20">
        <div className="w-full self-start flex gap-2 items-center">
          <Link to="/">
            <ArrowLeft
              size={32}
              weight="bold"
              className="cursor-pointer text-attention"
            />
          </Link>
          <Heading className={'text-attention select-none'}>
            Registro de conta
          </Heading>
        </div>
        <form
          onSubmit={onRegister}
          className="flex flex-col lg:w-full gap-4 pb-10"
        >
          <InputForm {...props} />
          <Checkbox role={role} setRole={setRole} />
          <SendButton {...props} />
        </form>
      </div>
      <SimpleFooter />
    </div>
  )
}
