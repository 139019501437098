import { Spinner } from 'phosphor-react'
import React from 'react'
import { Text } from '../Text/Body'

export const Loading = ({ className }) => {
  return (
    <div
      className={`w-full ${
        className || 'min-h-[calc(100vh_-_80px)]'
      } bg-preto flex flex-1 items-center justify-center gap-2 `}
    >
      <Spinner size={40} className="animate-spin text-attention" />
      <Text>Carregando, por favor aguarde... </Text>
    </div>
  )
}
