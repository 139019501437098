import { Subtext } from '../Text/Body'

export const Button = ({ children, className, onClick, as, ...rest }) => {
  const Component = as || 'button'
  return (
    <Component
      className={`tracking-wider bg-primary disabled:bg-cinza disabled:cursor-not-allowed rounded-3xl px-4 py-2 hover:brightness-110 transition shadow-md shadow-black/50 ${className}`}
      onClick={onClick}
      {...rest}
    >
      <Subtext className={'text-zinc-50 !font-bold'}>{children}</Subtext>
    </Component>
  )
}

export const ButtonAlternative = ({ children, className, onClick, as, ...rest }) => {
  const Component = as || 'button'
  return (
    <Component
      className={`tracking-wider bg-attention disabled:bg-cinza disabled:cursor-not-allowed rounded-3xl px-4 py-2 hover:brightness-110 transition shadow-md shadow-black/50 ${className}`}
      onClick={onClick}
      {...rest}
    >
      <Subtext className={'text-zinc-50 !font-bold'}>{children}</Subtext>
    </Component>
  )
}

export const ButtonOutline = ({
  children,
  className,
  onClick,
  as,
  ...rest
}) => {
  const Component = as || 'button'
  return (
    <Component
      className={`tracking-wider bg-transparent border-attention border-2 rounded-3xl px-4 py-2 hover:bg-attention/70 disabled:border-cinza disabled:hover:bg-cinza disabled:cursor-not-allowed transition shadow-md shadow-black/50 ${className}`}
      onClick={onClick}
      {...rest}
    >
      <Subtext className={'text-zinc-50 font-bold'}>{children}</Subtext>
    </Component>
  )
}

export const ButtonSecondary = ({
  children,
  className,
  onClick,
  as,
  ...rest
}) => {
  const Component = as || 'button'
  return (
    <Component
      className={`tracking-wider bg-secondary disabled:bg-cinza disabled:cursor-not-allowed rounded-3xl px-4 py-2 hover:brightness-110 transition shadow-md shadow-black/50 ${className}`}
      onClick={onClick}
      {...rest}
    >
      <Subtext className={'text-zinc-50 !font-bold'}>{children}</Subtext>
    </Component>
  )
}


export const ButtonAccent = ({
  children,
  className,
  onClick,
  as,
  ...rest
}) => {
  const Component = as || 'button'
  return (
    <Component
      className={`tracking-wider bg-accent disabled:bg-cinza disabled:cursor-not-allowed rounded-3xl px-4 py-2 hover:brightness-110 transition shadow-md shadow-black/50 ${className}`}
      onClick={onClick}
      {...rest}
    >
      <Subtext className={'text-zinc-50 !font-bold'}>{children}</Subtext>
    </Component>
  )
}

