import { Bell } from 'phosphor-react';
import { returnTextByType } from '../../utils/logs/return-text-by-type';
import { useGlobalContext } from '../../Context/GlobalContext';
import { readLog } from '../../utils/logs/read-log';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../..';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';

export function NotificationDropdown({ notifications }) {
  const { farms, userData } = useGlobalContext()
  const farmsFormatted = farms && Object.values(farms)
  const navigate = useNavigate()
  const mutation = useMutation({
    mutationFn: () => true,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userLogs', 'harvestApprove', 'wineryApprove'] })
    },
  })
  //filter asc by date
  notifications?.sort((a, b) => new Date(b?.date) - new Date(a?.date))

  async function handleReadNotification(notification) {
    if (notification?.read === true) return
    await readLog(notification?.id, userData?.id)
    mutation.mutateAsync()
    notification.read = true
    // if (notification?.action === 'askForHarvest') {
    //   navigate(`/production/register/${notification?.farmId}?id=${notification?.harvestId}`)
    // }

    // if (notification?.action === 'warnFarmerAboutProductionFiles' || notification?.action === 'warnFarmerAboutUpdateProductionFiles') {
    //   navigate(`/farms/${notification?.farmId}`)
    // }

    if (notification?.action === 'wineryAddedDocumentsLog' || notification?.action === 'wineryUpdatedDocumentsLog') {
      window.location.href = `/production/approve/${notification?.requestId}`;
    }

    if (notification?.action === 'registerWineryToFarm') {
      navigate(`/farms/${notification?.farmId}`)
    }


  }

  return (
    <div className="dropdown dropdown-end">
      {auth?.currentUser?.uid &&
        <div tabIndex={0} role="button" className="relative">
          <Bell size={24} />
          {notifications?.filter(notification => notification?.read === false).length > 0 && <div className="bg-red-500 w-2 h-2 rounded-full absolute top-0 right-0"></div>}
        </div>
      }
      <ul tabIndex={0} className="dropdown-content z-[1] shadow bg-black border border-cinza/50 rounded-box  mt-4 w-80 h-80 overflow-auto flex flex-col p-4">
        {notifications?.length === 0 && <p className="text-white text-center self-center flex-1  flex justify-center items-center">Nenhuma notificação</p>}
        {notifications?.map((notification, index) => {
          //find farm name
          const farmFind = farmsFormatted?.find(farm => farm?.id?.toLowerCase() === notification?.farmId?.toLowerCase())
          return <li key={index} className={"w-full hover:bg-accent/30 cursor-pointer p-2 hover:rounded-lg relative border-b mb-1 hover:border"}
            onClick={() => handleReadNotification(notification)}
          >
            {notification?.read === false && <div className="bg-red-500 w-2 h-2 rounded-full absolute top-2 right-2"></div>}
            <p className={`
            ${notification?.read === false ? 'font-bold' : 'font-normal text-cinza'}
            
            `}>{returnTextByType(notification?.action, farmFind?.name ?? notification?.farmName, notification?.wineryName)}</p>
          </li>
        })}
      </ul>
    </div>
  );
}