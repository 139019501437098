export const ChardonnayCoords = [
  { lat: -29.182093, lng: -51.55473 },
  { lat: -29.182539, lng: -51.554657 },
  { lat: -29.182749, lng: -51.555178 },
  { lat: -29.182547, lng: -51.55524 },
  { lat: -29.182297, lng: -51.55552 },
  { lat: -29.182096, lng: -51.55554 },
  { lat: -29.181944, lng: -51.555295 },
  { lat: -29.182186, lng: -51.555313 },
  { lat: -29.182308, lng: -51.555269 },
  { lat: -29.182273, lng: -51.555167 },
];

export const ChardonnayCoords2 = [
  { lat: -29.182861, lng: -51.555659 },
  { lat: -29.182385, lng: -51.555714 },
  { lat: -29.182604, lng: -51.555399 },
  { lat: -29.182881, lng: -51.555236 },
];

export const MerlotCoords = [
  { lng: -51.555215, lat: -29.182913 },
  { lng: -51.554653, lat: -29.183091 },
  { lng: -51.554511, lat: -29.183385 },
  { lng: -51.555603, lat: -29.183413 },
  { lng: -51.555644, lat: -29.182885 },
];

export const MerlotCoords2 = [
  { lng: -51.555471, lat: -29.179693 },
  { lng: -51.555516, lat: -29.178564 },
  { lng: -51.555251, lat: -29.178569 },
  { lng: -51.554913, lat: -29.178838 },
  { lng: -51.554900, lat: -29.179249 },
  { lng: -51.554959, lat: -29.179387 },
  { lng: -51.555050, lat: -29.179473 },
];

export const CabernetSauvignonCoords = [
  { lat: -29.177543, lng: -51.555499 },
  { lat: -29.177549, lng: -51.55506 },
  { lat: -29.177615, lng: -51.555057 },
  { lat: -29.177651, lng: -51.554833 },
  { lat: -29.178165, lng: -51.554808 },
  { lat: -29.178567, lng: -51.555019 },
  { lat: -29.178459, lng: -51.555299 },
  { lat: -29.178509, lng: -51.555502 },
];

export const CabernetSauvignonCoords2 = [
  { lat: -29.181002, lng: -51.555715 },
  { lat: -29.180996, lng: -51.555263 },
  { lat: -29.181127, lng: -51.554949 },
  { lat: -29.181776, lng: -51.55531 },
  { lat: -29.181962, lng: -51.555502 },
  { lat: -29.181937, lng: -51.555713 },
];

export const CabernetSauvignonCoords3 = [
  { lat: -29.183609, lng: -51.55458 },
  { lat: -29.183822, lng: -51.554605 },
  { lat: -29.183853, lng: -51.555657 },
  { lat: -29.183683, lng: -51.555679 },
];
