import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { SvgStep1 } from '../assets/SvgStep1'

export const Step1 = ({ mediaQuery }) => {
  return (
    <article className="lg:flex lg:justify-between lg:items-center">
      <div className="lg:flex lg:flex-col">
        <Text className="flex flex-row gap-4 my-4 ">
          <SvgStep1 />
          <Text className="flex flex-col text-white">
            Etapa 1<Heading className="text-attention">Documentos</Heading>
          </Text>
        </Text>
        <ul className="px-6 font-medium list-disc lg:text-lg">
          <li>
            <Text>Prove a propriedade da vinícola e seus direitos</Text>
          </li>
          <li>
            <Text>
              A propriedade é presumida pela precedente comprovada com
              documentação
            </Text>
          </li>
          <li>
            <Text>
              Em caso de ausência desta, a propriedade é presumida pela posse
            </Text>
          </li>
          <li>
            <Text>Este passo requer uma prova de vida com código QR.</Text>
          </li>
        </ul>
      </div>
      {mediaQuery && (
        <img
          src={require('../assets/step1.jpg')}
          alt="art"
          className="w-1/3 h-[20rem] rounded-3xl"
          loading="eager"
        />
      )}
    </article>
  )
}
