import { child, get, ref } from "firebase/database";
import { db } from "../../firebase";

export async function getAllProducers() {
  const dbRef = ref(db)
  const usersSnapshot = await get(child(dbRef, `/users`))
  if (usersSnapshot?.exists()) {
    const usersSnap = usersSnapshot.val()
    const users = Object.values(usersSnap).filter(user => user?.role === 1)
    return users
  } else {
    return []
  }
}

export async function getAllWinerys() {
  const dbRef = ref(db)
  const usersSnapshot = await get(child(dbRef, `/users`))
  if (usersSnapshot?.exists()) {
    const usersSnap = usersSnapshot.val()
    const users = Object.values(usersSnap).filter(user => user?.role === 2)
    return users
  } else {
    return []
  }
}

export async function getAllAssociates() {
  const dbRef = ref(db)
  const usersSnapshot = await get(child(dbRef, `/users`))
  if (usersSnapshot?.exists()) {
    const usersSnap = usersSnapshot.val()
    const users = Object.values(usersSnap).filter(user => user?.role === 3)
    return users
  } else {
    return []
  }
}