import { Button } from '../../../Components/Screen/Button/button'
import { Text } from '../../../Components/Screen/Text/Body'
import { Heading } from '../../../Components/Screen/Text/Heading'

export const Header = () => {
  return (
    <div className="w-full flex flex-col-reverse md:flex-row gap-10 bg-black/20 p-10 rounded-2xl">
      <div className="flex-1 flex flex-col gap-5 justify-center items-center">
        <Heading>
          Sua ponte do negócio vinícola para{' '}
          <Heading className="text-attention"> web3.</Heading>
        </Heading>
        <Text>
          Compra, venda e transfira produção vinícola até o consumidor.
        </Text>
        <Button disabled title="Em construção">
          Comece a negociar
        </Button>
      </div>
      <div className="flex-1">
        <img
          src={require('../assets/farmie-background-2.png')}
          alt="Marketplace"
          className='rounded-2xl'
        />
      </div>
    </div>
  )
}
