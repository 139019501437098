import { Link } from 'react-router-dom'
import { Subtext } from '../../../Screen/Text/Body'

export const LinksMobile = () => {
  return (
    <section className="flex flex-row justify-evenly text-branco mt-6 text-xs gap-4 p-4 lg:gap-96 lg:px-20">
      <article className="flex flex-col  gap-10">
        <Link to="/provenance">
          <Subtext>Como funciona</Subtext>
        </Link>
        <Link to="/marketplace">
          <Subtext>Marketplace</Subtext>
        </Link>
        <Link to="/farms">
          <Subtext>Vinícolas</Subtext>
        </Link>
      </article>
      <article className="flex flex-col  gap-10">
        <a href="https://origemrs.gitbook.io/white-paper">
          <Subtext>Whitepaper</Subtext>
        </a>
      </article>
      <article className="flex flex-col  gap-2">
        <Subtext>Fique atualizado!</Subtext>
        <input
          type="text"
          placeholder="✉ Email"
          className="bg-transparent w-full max-w-xs border-b py-2"
        />
      </article>
    </section>
  )
}
