import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import useMediaQuery from '../../hooks/useMediaQuery'
import loginImage from './assets/loginImage.jpg'
import { auth } from '../../firebase'
import { ForgotPassModal } from './components/ForgotPassModal'
import { LoginButtons } from './components/LoginButtons'
import { InputForm } from './components/InputForm'
import SimpleFooter from '../../Components/SimpleFooter/Index'
import { CircleLight } from '../../Components/Screen/circleLight'
import { useLogin } from '../../utils/firebase/login'

export default function LoginPage() {
  const [showPassword, setShowPassword] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { error, loading, login } = useLogin()
  const navigate = useNavigate()

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    if (auth?.currentUser?.uid) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.currentUser?.uid])

  const mQuery = useMediaQuery('(min-width: 1024px)')

  const props = {
    showPassword,
    setShowPassword,
    handleClickShowPassword,
    mQuery,
    showModal,
    setShowModal,
    email,
    setEmail,
    password,
    setPassword,
    error,
    loading,
    login,
  }

  return (
    <div
      className={`bg-preto h-[calc(100vh_-_80px)] flex justify-between relative ${
        mQuery ? 'flex-row ' : 'flex-col  '
      }`}
    >
      <CircleLight
        left
        position={mQuery ? 'top-1/4 right-3/4' : 'bottom-1/2'}
      />

      <div className="bg-preto w-full flex justify-center items-center flex-grow flex-col">
        <section className="justify-center items-center flex flex-col h-full  z-20">
          <form
            onSubmit={(e) => {
              login(email, password, e)
            }}
            className="lg:px-20 flex flex-col lg:gap-4"
          >
            <InputForm {...props} />
            <LoginButtons {...props} />
          </form>
          <ForgotPassModal {...props} />
        </section>
        {mQuery && <SimpleFooter />}
      </div>
      {mQuery && (
        <img
          className="w-2/5 xl:2/4  object-cover"
          src={loginImage}
          alt="background"
          loading="eager"
        />
      )}
      {!mQuery && <SimpleFooter />}
    </div>
  )
}
