import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading, Subheading } from '../../../../Components/Screen/Text/Heading'
import { Logo } from '../../../../assets/logo'

export const TextLeft = () => {
  return (
    <>
      <Logo className={'self-center w-[80%] h-full mb-10'} />
      <Heading>
        <Heading className={'text-attention'}>Rastreabilidade</Heading> e{' '}
        <Heading className={'text-attention'}>certificação</Heading> de
        Denominação de Origem
      </Heading>
      <Subheading>
        Seu caminho para comercialização{' '}
        <Subheading className={'text-attention'}>
          da uva à taça!
        </Subheading>
      </Subheading>
      <Text>
        Fácil, imediato, transparente e validado no{' '}
        <Text className={'text-attention !font-bold'}>Blockchain</Text>.
      </Text>
    </>
  )
}
