import { useGlobalContext } from "../../Context/GlobalContext"

export const CircleLight = ({ bgColor = 'bg-accent', position, left }) => {
  const { mediaQuery } = useGlobalContext()
  return (
    mediaQuery === 'mobile' ? null : <div
      className={`${bgColor} rounded-full w-64 h-64 ${position} ${left ? 'left-30' : 'right-20'
        }  absolute blur-[230px]`}
    />
  )
}
