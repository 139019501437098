export function MapContainer({ location }) {
  const latOffset = 0.002; // Adjust as needed
  const lngOffset = 0.002; // Adjust as needed
  const minLat = location?.lat - latOffset;
  const maxLat = location?.lat + latOffset;
  const minLng = location?.lng - lngOffset;
  const maxLng = location?.lng + lngOffset;

  return (<>
    <iframe
      width="100%"
      height="350"
      title="Mapa da localização do vinho"
      src={`https://www.openstreetmap.org/export/embed.html?bbox=${minLng}%2C${minLat}%2C${maxLng}%2C${maxLat}&layer=mapnik&marker=${location.lat}%2C${location.lng}`}
      className="border rounded-lg"
    ></iframe>
    <br />
    <small>
      <a href={`https://www.openstreetmap.org/?mlat=${location.lat}&mlon=${location.lng}#map=18/${location.lat}/${location.lng}`}>Ver mapa ampliado</a>
    </small>

  </>
  )
}
