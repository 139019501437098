import { Link } from 'react-router-dom'
import { ButtonAccent } from '../../../../Components/Screen/Button/button'
import { Subheading } from '../../../../Components/Screen/Text/Heading'
import { useGlobalContext } from '../../../../Context/GlobalContext'
import { returnUserRole } from '../../../../utils/returnUserRole'

export const FarmImage = () => {
  const { userData } = useGlobalContext()
  const userRole = returnUserRole(userData?.role)

  return (
    <>
      <div className="relative lg:h-[400px]">
        <img
          src={require('../assets/imageArt.jpg')}
          alt="Art"
          className="w-full h-full rounded-3xl object-cover"
        />
        <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-preto rounded-b-2xl"></div>
        <div className="absolute z-10 text-left bottom-4 left-4 ">
          <Subheading style={{ fontSize: '32px' }}>Sua {userRole === 'Produtor' ? ' produção' : ' vinícola'}</Subheading>
        </div>
      </div>

      <Link to="/farm/register" className="w-full py-4">
        <ButtonAccent className="w-full">Registre sua {userRole === 'Produtor' ? ' produção' : ' vinícola'}</ButtonAccent>
      </Link>
    </>
  )
}
