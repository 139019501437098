export const Cabernet_Sauvignon_cords6 = [
  { lng: -51.60347942300005997, lat: -29.16739263575755103 },
  { lng: -51.60350876399679265, lat: -29.16830240431406907 },
  { lng: -51.60342926058984858, lat: -29.16832166784676872 },
  { lng: -51.60331007068508313, lat: -29.16820199421113102 },
  { lng: -51.60314749250940025, lat: -29.16803889639050951 },
  { lng: -51.60292202924024707, lat: -29.16791280691212052 },
  { lng: -51.60274520823139000, lat: -29.16780776627723881 },
  { lng: -51.60265184904496039, lat: -29.16775586843354873 },
  { lng: -51.60269684294659243, lat: -29.16801404253306984 },
  { lng: -51.60237819904332923, lat: -29.16805205530397060 },
  { lng: -51.60209498653296123, lat: -29.16799383118515010 },
  { lng: -51.60196283225253922, lat: -29.16797021162618009 },
  { lng: -51.60187544574677077, lat: -29.16738643778634099 },
  { lng: -51.60203250207310077, lat: -29.16739206112301019 },
  { lng: -51.60227128872043068, lat: -29.16754148215600040 },
  { lng: -51.60243574475335748, lat: -29.16763875972048936 },
  { lng: -51.60245189164041335, lat: -29.16742426180504921 },
  { lng: -51.60347942300005997, lat: -29.16739263575755103 }
];
export const Merlot_cords6 = [
  { lng: -51.60440176646555699, lat: -29.16801518747985966 },
  { lng: -51.60351776890608022, lat: -29.16829980868405059 },
  { lng: -51.60348673121544749, lat: -29.16739371663195968 },
  { lng: -51.60440553267316233, lat: -29.16737505618106852 },
  { lng: -51.60440176646555699, lat: -29.16801518747985966 }];
export const Malbec_cords6 = [
  { lng: -51.60143165802907816, lat: -29.1730369871036892 },
  { lng: -51.60153994634163865, lat: -29.1731624093728712 },
  { lng: -51.60167030451930259, lat: -29.17329952793771852 },
  { lng: -51.60167686810822119, lat: -29.17337857111154165 },
  { lng: -51.60172462473759936, lat: -29.17340417638505912 },
  { lng: -51.60175461285079734, lat: -29.17357314915620137 },
  { lng: -51.60180126758599073, lat: -29.17374714877864861 },
  { lng: -51.60185626149615956, lat: -29.17396696762829933 },
  { lng: -51.6017891169798375, lat: -29.17401449595558915 },
  { lng: -51.60165053630071696, lat: -29.1740147679074795 },
  { lng: -51.60133707975106176, lat: -29.17401098599378173 },
  { lng: -51.60126500596083332, lat: -29.1736531403315098 },
  { lng: -51.60123794295648025, lat: -29.17362993312595165 },
  { lng: -51.60119800750904062, lat: -29.17339775418554026 },
  { lng: -51.60119779818950292, lat: -29.1733653931460104 },
  { lng: -51.60113624400759136, lat: -29.17335131469140919 },
  { lng: -51.60112450983537968, lat: -29.1732984756391609 },
  { lng: -51.6011352545552171, lat: -29.17265965170462039 },
  { lng: -51.60129769332345262, lat: -29.17285193274565103 }];

export const Tannat_cords6 = [
  { lng: -51.60167360238708767, lat: -29.17490748236257048 },
  { lng: -51.60149625940282903, lat: -29.17441399794416057 },
  { lng: -51.60144631158795647, lat: -29.17420309695215863 },
  { lng: -51.60141923327689994, lat: -29.17418642117441152 },
  { lng: -51.60139681244090326, lat: -29.17403431707235129 },
  { lng: -51.60174687314214736, lat: -29.17403481364081941 },
  { lng: -51.60187808529572351, lat: -29.17404288373636945 },
  { lng: -51.60185928104181841, lat: -29.17381585920783138 },
  { lng: -51.60190974942346998, lat: -29.17382621251393005 },
  { lng: -51.60195981325176717, lat: -29.17382895838284895 },
  { lng: -51.60198017813800675, lat: -29.17383194100225907 },
  { lng: -51.60202327449430726, lat: -29.17429628031763045 },
  { lng: -51.60193411571682987, lat: -29.17444441276137823 },
  { lng: -51.60214587858722268, lat: -29.17504022974651079 },
  { lng: -51.60195173589720952, lat: -29.17512093280913987 },
  { lng: -51.60185653982934184, lat: -29.17515192624697917 },
  { lng: -51.60180544759172960, lat: -29.17499618853673127 },
  { lng: -51.60170238998274783, lat: -29.17498766855429082 },
  { lng: -51.60167360238708767, lat: -29.17490748236257048 }
];