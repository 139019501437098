import { ref, update } from "firebase/database"
import { db } from "../../firebase"

export async function readLog(logId, userId) {
  try {
    await update(ref(db, `/logs/${logId}`)
      , {
        read: true
      })
  } catch (err) {
    console.error(err)
  }
}