import { ref, update } from "firebase/database"
import { db } from "../../firebase"
import { v4 as uuidv4 } from 'uuid';

//both ids are from user id
export async function askForHarvestLog(wineryId, farmId, harvestId) {
  try {
    const logId = uuidv4()
    await update(ref(db, `logs/${logId}`), {
      id: logId,
      wineryId,
      farmId,
      harvestId,
      user_target: wineryId,
      action: 'askForHarvest',
      date: new Date().toISOString(),
      read: false,
    })

    return logId
  } catch (err) {
    console.error(err)
  }
}