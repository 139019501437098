import {
  EnvelopeSimple,
  Eye,
  EyeSlash,
  GlobeStand,
  IdentificationCard,
  Lock,
  MapPin,
  Phone,
  User,
} from 'phosphor-react'
import { InputMask } from 'primereact/inputmask'
import { Input, InputDiv } from '../../../Components/Screen/Input/Text'
import { getAddress } from '../../../utils/address/getAddressByCEP'

export const InputForm = (props) => {
  const {
    setRegisterEmail,
    setRegisterPassword,
    setPasswordConfirm,
    setFirstName,
    setLastName,
    setPhone,
    setCnpj,
    setZipCode,
    setAddress,
    setCity,
    setState,
    setCountry,
    setShowPassword,
    showPassword,
    registerEmail,
    registerPassword,
    passwordConfirm,
    firstName,
    lastName,
    zipCode,
    address,
    city,
    state,
    country,
    phone,
    cnpj,
    number,
    setNumber,
    complement,
    setComplement,
  } = props

  const handleZipCode = async (cep) => {
    setZipCode(cep)
    const data = await getAddress(cep)
    if (data) {
      setAddress(data?.address + ', ' + data?.district)
      setCity(data?.city)
      setState(data?.state)
      setCountry('Brasil')
    }
  }

  return (
    <section className="flex flex-col lg:grid lg:grid-cols-2 gap-3 lg:gap-8 pt-10 justify-items-center">
      <InputDiv>
        <User size={24} />
        <Input
          placeholder="Nome"
          value={firstName}
          required
          onChange={(e) => setFirstName(e.target.value)}
        />
      </InputDiv>
      <InputDiv>
        <User size={24} />
        <Input
          placeholder="Sobrenome"
          value={lastName}
          required
          onChange={(e) => setLastName(e.target.value)}
        />
      </InputDiv>
      <InputDiv>
        <EnvelopeSimple size={24} />
        <Input
          type="email"
          placeholder="Email"
          autoComplete="new-email"
          required
          value={registerEmail}
          onChange={(event) => {
            setRegisterEmail(event.target.value)
          }}
        />
      </InputDiv>
      <InputDiv>
        <Lock size={24} />
        <Input
          required
          type={showPassword ? 'text' : 'password'}
          placeholder="Senha"
          autoComplete="new-password"
          value={registerPassword}
          onChange={(event) => {
            setRegisterPassword(event.target.value)
          }}
        />
        <div
          className="cursor-pointer"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <EyeSlash size={24} /> : <Eye size={24} />}
        </div>
      </InputDiv>
      <InputDiv>
        <Lock size={24} />
        <Input
          required
          type={showPassword ? 'text' : 'password'}
          placeholder="Confirme sua senha"
          value={passwordConfirm}
          onChange={(event) => {
            setPasswordConfirm(event.target.value)
          }}
        />
        <div
          className="cursor-pointer"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <EyeSlash size={24} /> : <Eye size={24} />}
        </div>
      </InputDiv>
      <InputDiv>
        <Phone size={24} />

        <Input
          placeholder="Telefone"
          value={phone}
          onChange={(event) => {
            setPhone(event.target.value)
          }}
        />
      </InputDiv>
      <InputDiv>
        <IdentificationCard size={24} />
        <InputMask
          mask="99999999999?999"
          placeholder="CNPJ/CPF"
          required
          value={cnpj}
          onChange={(event) => {
            setCnpj(event.target.value)
          }}
        />
      </InputDiv>
      <InputDiv>
        <MapPin size={24} />
        <Input
          placeholder="CEP"
          required
          value={zipCode}
          onChange={(event) => {
            handleZipCode(event.target.value)
          }}
        />
      </InputDiv>
      <InputDiv>
        <MapPin size={24} />
        <Input
          placeholder="Endereço"
          required
          value={address}
          onChange={(event) => {
            setAddress(event.target.value)
          }}
        />
      </InputDiv>
      <InputDiv>
        <MapPin size={24} />
        <Input
          placeholder="Número"
          required
          value={number}
          onChange={(event) => {
            setNumber(event.target.value)
          }}
        />
      </InputDiv>
      <InputDiv>
        <MapPin size={24} />
        <Input
          placeholder="Complemento"
          value={complement}
          onChange={(event) => {
            setComplement(event.target.value)
          }}
        />
      </InputDiv>
      <InputDiv>
        <MapPin size={24} />
        <Input
          id="city"
          placeholder="Cidade"
          value={city}
          required
          onChange={(event) => {
            setCity(event.target.value)
          }}
        />
      </InputDiv>
      <InputDiv>
        <MapPin size={24} />
        <Input
          placeholder="Estado"
          required
          value={state}
          onChange={(event) => {
            setState(event.target.value)
          }}
        />
      </InputDiv>
      <InputDiv>
        <GlobeStand size={24} />
        <Input
          placeholder="País"
          required
          value={country}
          onChange={(event) => {
            setCountry(event.target.value)
          }}
        />
      </InputDiv>
    </section>
  )
}
