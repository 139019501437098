import { useQuery } from "@tanstack/react-query";
import { CircleLight } from "../../../Components/Screen/circleLight";
import { getAllHarvests } from "../../../utils/firebase/harvests";
import { useGlobalContext } from "../../../Context/GlobalContext";
import { FirstFormApproveModal } from "./FirstFormModal";
import { SecondFormApproveModal } from "./SecondFormModal";
import { Button } from "../../../Components/Screen/Button/button";
import { ref, update } from "firebase/database";
import { db } from "../../../firebase";
import { useState } from "react";
import { mintNFT } from "../../../utils/web3/mintNFT";
import { createMetadata } from "../../../utils/web3/createMetadata";
import { getWineryById } from "../../../utils/firebase/get-wineries-from-farm-id";
import { CreateNFTModal } from "./NFTmodal";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../Components/Screen/Loading/Loading";
import { getRequestById } from "../../../utils/firebase/requests";
import { getAllWinerys } from "../../../utils/firebase/get-types-of-user";
import StorageClient from "../../../utils/storageFile";
import { returnUserRole } from "../../../utils/returnUserRole";

export function ApproveProductionPage() {
  const { userData } = useGlobalContext()
  const [isReject, setIsReject] = useState(false)
  const params = useLocation()
  const id = params.pathname.split('/').pop()
  const navigate = useNavigate()
  const [motive, setMotive] = useState('')
  const [, setIsDone] = useState(false)
  const [loading, setLoading] = useState(false)
  const [nftCertificateImage, setNftCertificateImage] = useState(null)
  const [bottles_certified, setBottlesCertified] = useState(0)
  const [bottles_certified375, setBottlesCertified375] = useState(0)
  const [bottles_certified1500, setBottlesCertified1500] = useState(0)

  const { data: request } = useQuery({
    queryKey: ['request', id],
    queryFn: () =>
      getRequestById(id).then((items) => {
        if (bottles_certified > 0) return items
        const bottles = Math.round((items?.harvestDeclaration?.quantity * 1.25) / 1000) * 1000
        setBottlesCertified(bottles)
        return items
      })
    ,
    enabled: !!userData?.id
  })

  const { data: wineries } = useQuery({
    queryKey: ['wineries', userData?.id],
    queryFn: () =>
      getAllWinerys().then((items) => {
        return items
      })
    ,
    enabled: !!userData?.id
  })

  const { data: harvestsData } = useQuery({
    queryKey: ['harvests'],
    queryFn: () =>
      getAllHarvests().then((items) => {
        return items
      })
    ,
    enabled: !!userData?.id
  })


  const harvests = harvestsData?.filter(harvest => request?.harvestIds?.includes(harvest?.id))

  // const farmsFormatted = farms && Object.values(farms)
  // const farms_participating = farmsFormatted?.filter(farm => request?.farmIds?.includes(farm?.id))
  const wineriesIds = new Set(harvests?.map(harvest => harvest?.wineryId))
  const wineries_participating = wineries?.filter(winery => wineriesIds.has(winery?.id))
  const tons = request?.harvestDeclaration?.kiloQuantity / 1000
  const files = request?.productionFiles

  async function handleSubmitNFT(imageUrl) {
    const capitalize_wineType = request?.harvestDeclaration?.wineType.charAt(0).toUpperCase() + request?.harvestDeclaration?.wineType.slice(1)
    const imageUrl_nft = await new StorageClient().storeFiles(imageUrl);

    const winery = await getWineryById(wineries_participating[0]?.id)
    const safraYear = new Date(request?.wineDetails?.safraYear).getFullYear()
    const metadata = {
      name: request?.harvestDeclaration?.comercialBrand + ' ' + safraYear,
      image: imageUrl_nft,
      attributes: [
        {
          trait_type: "Marca Comercial",
          value: request?.harvestDeclaration?.comercialBrand,
        },
        {
          trait_type: "Safra",
          value: safraYear,
        },
        ...(bottles_certified1500 > 0 ? [{
          trait_type: "Garrafas Certificadas de 1500ml",
          value: bottles_certified1500 // get from nft modal form
        }] : []),
        ...(bottles_certified > 0 ? [{
          trait_type: "Garrafas Certificadas de 750ml",
          value: bottles_certified // get from nft modal form
        }] : []),
        ...(bottles_certified375 > 0 ? [{
          trait_type: "Garrafas Certificadas de 375ml",
          value: bottles_certified375 // get from nft modal form
        }] : []),
        {
          trait_type: "Vinho",
          value: capitalize_wineType, // wine type
        },
        {
          trait_type: "Variedade/s",
          value: request?.harvestDeclaration?.variety, // juntar os tipos de uva
        },
        {
          trait_type: "Vinicola",
          value: winery?.name,
        },
      ],
    };

    const ipfs = await createMetadata('metadata.json', metadata);

    const nft = ipfs;
    return nft;

  }


  async function handleApprove(type, imageUrl) {
    try {
      setLoading(true)

      const uri = await handleSubmitNFT(imageUrl)
      const winery = wineries_participating[0]
      let tries = 0

      try {
        if (tries > 4) throw new Error('error minting nft')
        await mintNFT(winery?.wallet, uri)
        // await sendToken(winery?.wallet, tons)
      } catch (err) {
        console.error(err)
        tries++
        if (tries > 4) throw new Error('error minting nft')
      }
      const newTons = Number(tons) + Number(winery?.tons || 0)
      update(ref(db, `users/${winery?.id}`), {
        tons: newTons,
      })

      await Promise.all(harvests.map(async (harvest) => {
        await update(ref(db, `harvests/${harvest?.id}`), {
          status: 'approved',
          updated_in: new Date().toISOString()
        })
      }))

      await update(ref(db, `requests/${request?.id}`), {
        status: 'approved',
        updated_in: new Date().toISOString()
      })

      navigate('/production/approve/success')
      setIsDone(true)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  async function handleReject() {
    try {
      setLoading(true)
      if (!isReject) return setIsReject(true)
      if (!motive) return alert('Por favor, preencha o motivo da rejeição')
      await Promise.all(harvests.map(async (harvest) => {
        await update(ref(db, `harvests/${harvest?.id}`), {
          status: 'rejected',
          reason: motive,
          updated_in: new Date().toISOString()
        })
      }))
      await update(ref(db, `requests/${request?.id}`), {
        status: 'rejected',
        reason: motive,
        updated_in: new Date().toISOString()
      })
      navigate('/production/approve/success')
      setIsDone(true)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  const userRole = returnUserRole(userData?.role)

  if (userRole && userRole !== "Associação") navigate('/profile')

  if (!request) return <Loading />

  return (
    <div className="bg-preto min-h-[calc(100vh_-_80px)] flex flex-col">
      <CircleLight />
      <CircleLight left position={'bottom-0'} />
      <section className="px-8 lg:px-20 items-center justify-center flex flex-col gap-5 pt-10">

        <div className="flex flex-col gap-3 ">
          <div className="flex flex-col lg:flex-row gap-12 items-stretch w-full">

            <div className="flex flex-col flex-[1.8]">
              {request && <FirstFormApproveModal harvest={request} />}
            </div>

            <div className="flex flex-col flex-[1]">
              {request && <SecondFormApproveModal harvest={request} />}
            </div>

          </div>
          <div className="flex flex-wrap items-center gap-5 max-w-[800px] ">
            <p>Arquivos: </p>
            {files?.map((file, index) => {
              return <div key={index} className="flex items-center gap-3">

                <Button onClick={() => window.open(file.url, '_blank')}>{file.name.length > 15 ? file.name.slice(0, 15) + '...' : file.name}</Button>
              </div>
            })}

          </div>

        </div>
        <div className="flex flex-col gap-3">
          {isReject && <>
            <p className="text-red-500">Você está rejeitando essa produção, preencha o campo e clique novamente no botão rejeitar</p>
            <label htmlFor="motive" className="text-white">Motivo da rejeição</label>
            <textarea
              type="text"
              id="motive"
              value={motive}
              onChange={(e) => setMotive(e.target.value)}
              className="w-full bg-preto border border-gray-500 rounded-2xl p-2"
            />
          </>
          }
        </div>
        <div className="flex flex-col md:flex-row gap-5 items-center pb-4">
          <span className="lg:text-2xl">Aprovar e emitir NFT:</span>
          <CreateNFTModal {...{
            handleApprove,
            nftCertificateImage,
            setNftCertificateImage,
            type: 'indication',
            setBottlesCertified,
            bottles_certified,
            bottles_certified375,
            setBottlesCertified375,
            setBottlesCertified1500,
            bottles_certified1500
          }} />
          <CreateNFTModal {...{
            handleApprove,
            nftCertificateImage,
            setNftCertificateImage,
            type: 'do',
            setBottlesCertified,
            bottles_certified,
            bottles_certified375,
            setBottlesCertified375,
            setBottlesCertified1500,
            bottles_certified1500
          }} />
          <Button onClick={handleReject} className={"bg-red-500"} disabled={loading}>Rejeitar</Button>
        </div>

      </section>
    </div>
  )
}