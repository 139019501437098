import 'primeicons/primeicons.css'
import 'primereact/resources/themes/arya-green/theme.css'
import 'primereact/resources/primereact.min.css'
import { useState } from 'react'
import { Subheading } from '../../../Components/Screen/Text/Heading'
import { Loading } from '../../../Components/Screen/Loading/Loading'
import { Text } from '../../../Components/Screen/Text/Body'
import { InputSwitch } from 'primereact/inputswitch'
import { InputCrop } from './FirstFormModal'

export function SecondFormApproveModal({ harvest }) {
  const [bookModal, setBookModal] = useState(true)
  const [addLoading, setAddLoading] = useState(false)
  const [, setFormIsDone] = useState(false)
  const [density,] = useState(harvest?.wineDetails?.density || 0)
  const [alcoholicContent,] = useState(harvest?.wineDetails?.alcoholicContent || 0)
  const [totalAcidity,] = useState(harvest?.wineDetails?.totalAcidity || 0)
  const [volatileAcidity,] = useState(harvest?.wineDetails?.volatileAcidity || 0)
  const [fixedAcidity,] = useState(harvest?.wineDetails?.fixedAcidity || 0)
  const [ph,] = useState(harvest?.wineDetails?.ph || 0)
  const [reducingSugars,] = useState(harvest?.wineDetails?.reducingSugars || 0)
  const [reducedDryExtract,] = useState(harvest?.wineDetails?.reducedDryExtract || 0)
  const [freeSO2,] = useState(harvest?.wineDetails?.freeSO2 || 0)
  const [totalSO2,] = useState(harvest?.wineDetails?.totalSO2 || 0)
  const [lotNumber,] = useState(harvest?.wineDetails?.lotNumber || '')
  const [city,] = useState(harvest?.wineDetails?.city || '')
  const [polyphenolIndex,] = useState(harvest?.wineDetails?.polyphenolIndex || 0)
  const [totalColorIntensity,] = useState(harvest?.wineDetails?.totalColorIntensity || 0)
  const [wineColor,] = useState(harvest?.harvestDeclaration?.wineType.charAt(0).toUpperCase() + harvest?.harvestDeclaration?.wineType.slice(1) || 'N/A')
  const [seedType,] = useState(harvest?.harvestDeclaration?.variety || 'N/A')
  const [safraYear,] = useState(harvest?.wineDetails?.safraYear || 'N/A')

  async function handleSubmit(event) {
    event.preventDefault()
    setAddLoading(true)
    setFormIsDone(true)
    setAddLoading(false)
    setBookModal(false)
  }
  return (
    <>

      {bookModal && (
        <div className="">
          <div className="relative  bg-preto border border-gray-500 overflow-auto rounded-3xl p-8 ">

            <Subheading
              as="h1"
              className="mb-4 text-2xl font-bold text-center text-attention lg:text-4xl"
            >
              Informações da uva
            </Subheading>
            {addLoading ? (
              <Loading />
            ) : (
              <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                <div className="w-full flex flex-row gap-12">
                  <div className='flex flex-col'>
                    <Text>
                      Tipo de Semente: {seedType}
                    </Text>
                    <Text>
                      Cor do Vinho: {wineColor}
                    </Text>
                    {wineColor === 'Tinto' && (
                      <>
                        <InputCrop
                          readOnly
                          name={"Índice de polifenóis totais - IPT (280nm) "}
                          value={polyphenolIndex}
                        />
                        <InputCrop
                          readOnly
                          name={"Intensidade Total de Cor"}
                          value={totalColorIntensity}
                        />
                      </>
                    )}

                    <InputCrop
                      readOnly
                      name={'Densidade (20/20ºC)'}
                      value={density}
                    />
                    <InputCrop
                      readOnly
                      name={'Teor alcoólico (%v/v - 20ºC)'}
                      value={alcoholicContent}
                    />
                    <InputCrop
                      readOnly
                      name={'Acidez Total (mEq/l)'}
                      value={totalAcidity}
                    />

                    <InputCrop
                      readOnly
                      name={'Acidez Volátil (mEq/l)'}
                      value={volatileAcidity}
                    />
                    <InputCrop
                      readOnly
                      name={'Acidez Fixa (mEq/l)'}
                      value={fixedAcidity}
                    />
                    <InputCrop
                      readOnly
                      name={'Ph'}
                      value={ph}
                    />
                  </div>

                  <div className='flex flex-col'>
                    <InputCrop
                      readOnly
                      name={'Ano da safra'}
                      value={new Date(safraYear)?.getFullYear()}
                    />
                    <InputCrop
                      readOnly
                      name={'Açúcares redutores totais (g/L)'}
                      value={reducingSugars}
                    />
                    <InputCrop
                      readOnly
                      name={'Extrato Seco Reduzido (g/L)'}
                      value={reducedDryExtract}
                    />

                    <InputCrop
                      readOnly
                      name={'SO2 Livre (mg/L)'}
                      value={freeSO2}

                    />
                    <InputCrop
                      readOnly
                      name={'SO2 Total (mg/L)'}
                      value={totalSO2}

                    />
                    <InputCrop
                      readOnly
                      name={'Nº Lote'}
                      value={lotNumber}

                    />
                    <InputCrop
                      readOnly
                      name={'Município'}
                      value={city}

                    />
                  </div>
                </div>

              </form>
            )}
          </div>
        </div>
      )}

    </>
  )
}

export const InputSwitchForm = ({ name, value, setValue, ...rest }) => {
  return (
    <div className={`flex gap-5 justify-between ${value === true ? '' : 'w-full'}`}>
      <Text as="label" htmlFor={name} className="flex text-branco">
        {name}
      </Text>
      <InputSwitch
        id={name}
        checked={value}
        className='!flex-none'
        {...rest}
      />
    </div>
  )
}
