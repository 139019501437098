import { CopySimple, Gear, SignOut, User } from 'phosphor-react'
import { useState } from 'react'
import { ButtonOutline } from '../../../../Components/Screen/Button/button'
import { Subtext, Text } from '../../../../Components/Screen/Text/Body'
import { ChangeProfilePic } from '../ChangeProfilePic'
import { useNavigate } from 'react-router-dom'
import { returnUserRole } from '../../../../utils/returnUserRole'

export const UserInfo = (props) => {
  const navigate = useNavigate()
  const [copied, setCopied] = useState(false)
  const {
    userData,
    collection,
    allNfts,
    handleGear,
    logoutUser,
    isGearOpen,
  } = props

  const userRole = returnUserRole(userData?.role)
  return (
    <div
      className="flex flex-col gap-5  w-80 items-center justify-start absolute top-20 left-20 z-20 bg-cinzaescuro rounded-lg backdrop-blur-2xl py-10"
      style={{
        background:
          'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(165, 239, 255, 0.2) 0%, rgba(110, 191, 244, 0.0447917) 77.08%, rgba(70, 144, 213, 0) 100%)',
      }}
    >
      <div className="text-cinza flex flex-col font-light">
        {userData?.profilePicture ? (
          <img
            src={userData?.profilePicture}
            alt="Avatar"
            className="rounded-full h-72 w-72 object-cover object-center cursor-pointer"
            loading="eager"
            onClick={handleGear}
          />
        ) : (
          <div
            className="bg-gray-600 rounded-full p-10 cursor-pointer"
            onClick={handleGear}
          >
            <User size={200} color="white" />
          </div>
        )}
        {isGearOpen && <ChangeProfilePic {...props} />}
        <Subtext
          className=" cursor-pointer  w-fit text-gray-200 flex gap-2 hover:text-attention mt-5"
          onClick={() => {
            navigate('/profile/editProfile')
          }}
        >
          <Gear size={24} /> Editar Perfil
        </Subtext>
      </div>
      <div className="text-cinza flex flex-col font-light  w-full ml-10 gap-2">
        <Text className="text-white text-lg">{`${userData?.firstName || ''} ${userData?.lastName || ''
          }`}</Text>
        <div className="flex gap-1">
          <Subtext
            className={'text-white truncate overflow-hidden max-w-[70%]'}
            title={userData?.wallet}
          >
            Carteira: {userData?.wallet}
          </Subtext>
          <CopySimple
            className="text-white cursor-pointer hover:text-attention ml-2"
            size={24}
            onClick={() => {
              navigator.clipboard.writeText(userData?.wallet)
              setCopied(true)
              setTimeout(() => setCopied(false), 2000)
            }}
          />
        </div>
        {copied && <Subtext className="text-primary">Copiado!</Subtext>}
        <p className='text-white'>
          <span className='font-bold text-secondary'>
            {userRole} {userData?.name && `${userData?.name}`}
          </span>
        </p>
      </div>
      <div className='flex'>
      </div>
      <div className="flex flex-col items-start justify-start w-full ml-10 pr-16">
        <div className="justify-between flex items-center w-full text-white text-lg">
          <Text>Produção: </Text> {collection?.length || 0}
        </div>
        <div className={`justify-between flex items-center w-full text-white text-lg ${userRole !== 'Vinícola' && "hidden"}`}>
          <Text>Coleção de NFTs:</Text>
          {allNfts?.length || 0}
        </div>

        <div className={`justify-between flex items-center w-full text-white text-lg ${userRole !== 'Vinícola' && "hidden"}`}>
          <Text>Toneladas Geradas: </Text> {userData?.tons?.toFixed(2) || 0}
        </div>
      </div>

      <div>
        <ButtonOutline
          onClick={logoutUser}
          className=" !border-red-500 hover:!bg-red-500"
        >
          <p className="flex items-center gap-2">
            <SignOut size={20} /> Deslogar
          </p>
        </ButtonOutline>
      </div>
    </div>
  )
}
