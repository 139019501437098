import axios from 'axios'

export const getNftById = async ({ token_id }) => {
  const options = {
    method: 'GET',
    url: `https://${process.env.REACT_APP_alchemy_chain}.g.alchemy.com/nft/v3/${process.env.REACT_APP_alchemy_apiKey}/getNFTMetadata?contractAddress=${process.env.REACT_APP_nft_contract}&tokenId=${token_id}&refreshCache=false`,
    data: {
    },
  }

  return axios
    .request(options)
    .then(function (response) {
      const nft = response?.data
      const data = {
        ...nft,
        token_id: nft?.tokenId,
        token_uri: nft?.tokenUri,
        metadata: JSON?.stringify(nft?.raw?.metadata),
      }
      return data
    })
    .catch(function (error) {
      console.error(error)
    })
}
