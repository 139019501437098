import 'primereact/resources/primereact.min.css'
import { useState } from 'react'
// import { useGlobalContext } from '../../../Context/GlobalContext'
import { Subheading } from '../../../Components/Screen/Text/Heading'
import { Check, File, FilePlus, X } from 'phosphor-react'
import { Loading } from '../../../Components/Screen/Loading/Loading'
import { Button, ButtonOutline } from '../../../Components/Screen/Button/button'
import { validateSize } from '../../../utils/validateSizeFile'
// import { storage } from '../../../firebase'
// import { v4 as uuidv4 } from 'uuid';
// import { getDownloadURL, uploadBytesResumable, ref as refStorage } from 'firebase/storage'
import { Text } from '../../../Components/Screen/Text/Body'
// import { Input } from '../../../Components/Screen/Input/Text'
import { InputNumber } from 'primereact/inputnumber'


export function CreateNFTModal({ handleApprove, nftCertificateImage, setNftCertificateImage, type, setBottlesCertified, bottles_certified, bottles_certified375,
  setBottlesCertified375,
  setBottlesCertified1500,
  bottles_certified1500
}) {
  // const { userData } = useGlobalContext()
  const [certificateModal, setCertificateModal] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const [certificateImage, setCertificateImage] = useState(null)
  const [error, setError] = useState('')
  const [uploadProgress,] = useState(0)

  function handleModal() {
    setCertificateModal(!certificateModal)
  }

  async function handleSubmit(event) {
    try {
      event.preventDefault()
      setAddLoading(true)
      await handleApprove(type, certificateImage)
      setCertificateModal(false)
    } catch (err) {
      console.error(err)
    } finally {
      setAddLoading(false)
    }
  }

  return (
    <>
      {type === 'indication' ?
        <ButtonOutline className={"border-primary hover:bg-primary/70 w-[200px]"} onClick={handleModal} disabled={addLoading}>Indicação Geográfica</ButtonOutline>
        : <ButtonOutline onClick={handleModal} disabled={addLoading}>Denominação de origem</ButtonOutline>
      }
      {certificateModal && (
        <div className="modal modal-open w-full backdrop-blur">
          <div className="relative bg-preto border border-gray-500 rounded-3xl p-8 flex items-center flex-col justify-center">
            <div className=' w-full max-w-[500px] max-h-[85vh] overflow-y-auto scroll-smooth'>


              {!addLoading && (
                <X
                  size={32}
                  onClick={handleModal}
                  className="absolute  text-attention right-4 top-2 hover:cursor-pointer z-30"
                />
              )}

              <Subheading as="h1" className=" text-attention my-4 text-center lg:text-4xl">
                Pronto para criar seu NFT ?
              </Subheading>

              <Text as="p" className="text-center text-white">
                Grave o vinho na pedra do Blockchain nestes passos simples.
              </Text>

              <ul className="flex flex-col gap-2 text-white my-4">
                <li className="flex items-center gap-2">
                  <Check size={24} />
                  <span>Carregue a imagem do certificado de origem</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check size={24} />
                  <span>Insira a quantidade de garrafas certificadas</span>
                </li>
                <li className="flex items-center gap-2 pb-2">
                  <Check size={24} />
                  <span>Confirme a criação do NFT no botão "Queimar"</span>
                </li>
              </ul>


              {addLoading ? (
                <Loading className={"overflow-hidden"} />
              ) : (
                <form onSubmit={handleSubmit} className="flex flex-col gap-2">

                  {/* {!certificateImage && <img src={require('./nftimage.png')} alt="nft" className="w-[200px] self-center" />} */}

                  <input
                    type="file"
                    id="certificateImage"
                    accept="image/jpg, image/png, image/jpeg, image/tiff"
                    className="hidden"
                    onChange={(e) => {
                      validateSize(e.target.files[0], setError, setCertificateImage)
                      setCertificateImage(e.target.files[0])
                    }
                    }
                  />

                  {error && <p className="text-red-500">{error}</p>}

                  <div className='flex gap-10 items-center justify-center'>
                    <div className='flex flex-col items-center relative  py-2'>
                      {certificateImage && (
                        <div className="flex justify-center">
                          <img
                            src={URL.createObjectURL(certificateImage)}
                            alt="preview"
                            className="object-cover object-center w-full h-32"
                            loading="eager"
                          />
                        </div>
                      )}
                      <label
                        htmlFor="certificateImage"
                        className="cursor-pointer text-secondary flex items-center flex-col gap-3 pt-3"
                        onClick={() => setError(null)}
                      >
                        {certificateImage ? <File size={40} /> : <FilePlus size={40} />}
                        <Button type="button">
                          Upload do certificado*
                        </Button>
                      </label>
                      {uploadProgress < 1 && certificateImage &&
                        <X
                          size={28}
                          onClick={() => setCertificateImage(null)}
                          className="text-error cursor-pointer absolute -top-5 right-0"
                        />
                      }
                    </div>
                  </div>
                  <div className="w-full flex flex-col ">
                  <Text>
                      Quantidade de garrafas certificadas de 1500ml*
                    </Text>
                    <InputNumber
                      name={"Quantidade em garrafas de 1500ml*"}
                      value={bottles_certified1500}
                      onValueChange={(e) => setBottlesCertified1500(e.value)}
                      className="calendarInput"
                    />
                    <Text>
                      Quantidade de garrafas certificadas de 750ml*
                    </Text>
                    <InputNumber
                      name={"Quantidade em garrafas de 750ml*"}
                      value={bottles_certified}
                      onValueChange={(e) => setBottlesCertified(e.value)}
                      className="calendarInput"
                    />
                    <Text className={"mt-4"}>
                      Quantidade de garrafas certificadas de 375ml*
                    </Text>
                    <InputNumber
                      name={"Quantidade em garrafas de 375ml*"}
                      value={bottles_certified375}
                      onValueChange={(e) => setBottlesCertified375(e.value)}
                      className="calendarInput"
                    />
                  </div>
                  <div className="flex items-center justify-center pt-8">
                    <ButtonOutline type="submit" disabled={addLoading || !certificateImage || bottles_certified <= 0}>
                      {addLoading ? 'Criando seu NFT por favor aguarde, pode levar até alguns minutos...' : <p>Queimar 🔥</p>}
                    </ButtonOutline>
                  </div>
                  <span className='max-w-[500px] text-xs pt-6'>Nota: Cálculo em garrafas de 750ml com base na quantidade de litros declarada pela vinícola</span>
                </form>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

