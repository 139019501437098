import BePart from '../../Components/BePart/BePart'
import Footer from '../../Components/Footer/Index'
import { Market } from './components/marketplace/market'
import { Ecosystem } from './components/Ecosystem/Ecosystem'
import { Header } from './components/Header/Header'
import { BgCircles } from './components/assets/bgCircles'
import { useGlobalContext } from '../../Context/GlobalContext'

export default function App() {
  const { mediaQuery } = useGlobalContext()
  return (
    <div
      className="min-h-[calc(100vh_-_80px)] flex flex-col relative text-gray-50 bg-preto"
   
    >
      {mediaQuery === 'mobile' ? null : <BgCircles />}
      <div className="z-10 flex flex-col gap-12">
        <Header />
        <Market />
        <Ecosystem />
        <BePart />
        <Footer />
      </div>
    </div>
  )
}
