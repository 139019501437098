import { ref, update } from 'firebase/database'
import { useCallback, useEffect, useState } from 'react'

import ButtonCheckout from '../../../../Components/Checkout/ButtonCheckout'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import { auth, db } from '../../../../firebase'
import { ArrowLeft } from 'phosphor-react'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { Text } from '../../../../Components/Screen/Text/Body'
import {
  Button,
  ButtonOutline,
} from '../../../../Components/Screen/Button/button'
import { Input, InputDiv } from '../../../../Components/Screen/Input/Text'
import { useGlobalContext } from '../../../../Context/GlobalContext'

export default function ArtRegisterStep1Page({setStep}) {
  const [artTickets, setArtTickets] = useState(0)
  const [mintTickets, setMintTickets] = useState(0)
  const [isBuying, setIsBuying] = useState(false)
  const [showSkipButton, setShowSkipButton] = useState(false)
  const [userArtTickets, setUserArtTickets] = useState(0)
  const [userMintTickets, setUserMintTickets] = useState(0)
  const uid = auth?.currentUser?.uid
  const mediaQueryDesktop = useMediaQuery('(min-width: 1023px)')
  const [handleSubmitIsOn, setHandleSubmitIsOn] = useState(false)
  const { updateUser, userData } = useGlobalContext()
  const [totalAmount, setTotalAmount] = useState(0)
  const [cupom, setCupom] = useState('')

  useEffect(() => {
    if (mintTickets < 0) {
      setMintTickets(0)
    }
    if (artTickets < 0) {
      setArtTickets(0)
    }
  }, [mintTickets, artTickets])

  async function artFree(entradasArt) {
    await update(ref(db, 'users/' + uid), {
      artTicket: entradasArt,
    })
  }
  async function mintFree(entradasMint) {
    await update(ref(db, 'users/' + uid), {
      mintTicket: entradasMint,
    })
  }
  const handleSubmit = async (event) => {
    try {
      event.preventDefault()
      setHandleSubmitIsOn(true)
      if (userArtTickets < 1 && userMintTickets < 1) {
        const entradasArt = 1
        const entradasMint = 1
        artFree(entradasArt)
        mintFree(entradasMint)
      } else if (userArtTickets < 1) {
        const entradasArt = 1
        artFree(entradasArt)
      } else if (userMintTickets < 1) {
        const entradasMint = 1
        mintFree(entradasMint)
      }
      await updateUser()
      setHandleSubmitIsOn(false)
    } catch (err) {
      console.error(err)
    }
  }

  const handleisBuying = useCallback(() => {
    setIsBuying(!isBuying)
  }, [isBuying])

  const props = {
    userMintTickets,
    userArtTickets,
    setUserMintTickets,
    setUserArtTickets,
    setIsBuying,
    isBuying,
    handleSubmit,
    handleisBuying,
    price: totalAmount,
    artTickets,
    mintTickets,
    setArtTickets,
    setMintTickets,
  }

  useEffect(() => {
    if (userData) {
      setUserMintTickets(userData.mintTicket || 0)
      setUserArtTickets(userData.artTicket || 0)
    }
  }, [userData])

  useEffect(() => {
    setTotalAmount(artTickets * 10 + mintTickets * 10)
    if (artTickets === '' && mintTickets === '') {
      setArtTickets(0)
      setMintTickets(0)
    } else if (artTickets === '') {
      setArtTickets(0)
    } else if (mintTickets === '') {
      setMintTickets(0)
    }
  }, [artTickets, mintTickets, setTotalAmount])

  useEffect(() => {
    if (cupom === 'register free') {
      setTotalAmount(0)
      setMintTickets(0)
      setArtTickets(0)
      setShowSkipButton(true)
    } else {
      setShowSkipButton(false)
    }
  }, [cupom, setTotalAmount])

  return (
    <div className="text-white md:px-20 w-full z-20">
      <section className="flex flex-col pt-5 pb-12 text-white">
        <div className="flex items-center w-full px-5 mt-10 text-xl ">
          <ArrowLeft
            size={32}
            weight="bold"
            onClick={() => setStep(1)}
            className="text-attention hover:cursor-pointer"
          />
          <Heading className="ml-4 text-attention">
            Comprar passe de registro
          </Heading>
        </div>

        <form className="relative w-full md:w-1/2 px-5 mt-8">
          <label htmlFor="artTickets">
            <Text>Quantos ingressos WINE? </Text>
          </label>
          <InputDiv>
            <Input
              type="number"
              min={0}
              id="artTickets"
              onKeyDown={(evt) =>
                ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
              }
              value={artTickets || ''}
              onChange={(event) => setArtTickets(event.target.value)}
            />
          </InputDiv>

          <label htmlFor="mintTickets">
            <Text>Quantos ingressos de certificação?</Text>
          </label>
          <InputDiv>
            <Input
              type="number"
              id="mintTickets"
              onKeyDown={(evt) =>
                ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
              }
              value={mintTickets || ''}
              min={0}
              onChange={(event) => setMintTickets(event.target.value)}
            />
          </InputDiv>

          <label htmlFor="cupom">
            <Text>Cupom</Text>
          </label>
          <InputDiv>
            <Input
              type="text"
              id="cupom"
              value={cupom}
              onChange={(event) => setCupom(event.target.value.toLowerCase())}
            />
          </InputDiv>

          <div className="flex justify-end w-full gap-4 mt-20">
            <Button
              type="button"
              onClick={handleisBuying}
              disabled={!artTickets && !mintTickets}
            >
              Comprar
            </Button>
            {showSkipButton && (
              <ButtonOutline
                type="button"
                onClick={handleSubmit}
                disabled={handleSubmitIsOn}
              >
                Free pass
              </ButtonOutline>
            )}
          </div>

          {userArtTickets > 0 && (
            <div className="flex justify-end w-full gap-4 mt-10">
              <Button
                type="button"
                onClick={() => {
                  setStep(3)
                }}
              >
                Continuar com passe livre ({userArtTickets}x)
              </Button>
            </div>
          )}
        </form>
        {mediaQueryDesktop && (
          <div className="absolute right-0 pl-10 2xl:pl-[10%] w-[50%] h-[300px] top-48 ">
            <img
              src={require('../assets/step1.jpg')}
              alt="Art"
              className="rounded-3xl"
              loading="eager"
            />
          </div>
        )}

        <ButtonCheckout props={props} />
      </section>
    </div>
  )
}
