import { Text } from '../../../../Components/Screen/Text/Body'

export const ListCollection = ({ item, handleInfos }) => {
  return (
    <div className="flex select-none items-center justify-between border-b border-b-cinza p-4">
      <img
        src={item?.crocImage || "https://images.unsplash.com/photo-1553361371-9b22f78e8b1d?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHdpbmV8ZW58MHx8MHx8fDA%3D"}
        alt="farm"
        className="w-16 h-16 rounded-full object-cover object-center"
      />
      <Text>{item.name}</Text>
      <Text
        className="hover:cursor-pointer hover:text-attention text-gray-50"
        onClick={() => handleInfos(item)}
      >
        {item?.matchingHarvestsCount > 0 ? `Solicitações selecionadas (${item?.matchingHarvestsCount})` : "Ver"}
      </Text>
    </div>
  )
}
