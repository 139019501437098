export function returnTextByType(type, farmId, wineryId) {
  switch (type) {
    case 'registerWineryToFarm':
      return `Sua vinícola foi registrada na fazenda ${farmId}`
    case 'askForHarvest':
      return `A fazenda ${farmId} solicitou uma safra para sua vinícola`
    case 'wineryAddedDocumentsLog':
      return `A vinícola ${wineryId} adicionou documentos a fazenda ${farmId}, verifique!`
    case 'warnFarmerAboutProductionFiles':
      return `A vinícola ${wineryId} adicionou arquivos a sua solicitação`
    case 'warnFarmerAboutUpdateProductionFiles':
      return `A vinícola ${wineryId} atualizou arquivos da sua solicitação`
    case 'wineryUpdatedDocumentsLog':
      return `A vinícola ${wineryId} enviou uma solicitação na data ${new Date().toLocaleDateString()}, verifique! `
    default:
      return 'Ação desconhecida'

  }
}