import { child, get, ref, update } from "firebase/database";
import { db } from "../../firebase";

export async function getNftByIdDb(token_id) {
    const dbRef = ref(db);
    const snapshot = await get(child(dbRef, `/nfts`));
    if (snapshot?.exists()) {
        const nftsSnap = snapshot.val();
        const nft = Object.values(nftsSnap).find(nft => nft?.token_id === token_id?.toString());
        return nft;
    } else {
        return {}
    }
}

export async function getUserByNftId(token_id) {
    const dbRef = ref(db);
    const snapshot = await get(child(dbRef, `/users`));
    if (snapshot?.exists()) {
        const usersSnap = snapshot.val();
        const users = Object.values(usersSnap).filter(user => user?.nfts);
        const user = users.find(user => user?.nfts?.find(nft => nft?.token_id === token_id?.toString()));
        return user;
    } else {
        return {}
    }
}

export async function getAllNfts() {
    const dbRef = ref(db);
    const snapshot = await get(child(dbRef, `/nfts`));
    if (snapshot?.exists()) {
        const nftsSnap = snapshot.val();
        const nfts = Object.values(nftsSnap);
        return nfts;
    } else {
        return []
    }
}

export async function saveNft(nft) {
    try {
        const dbRef = ref(db, `/nfts/${nft.token_id}`);
        return await update(dbRef, nft);
    } catch (error) {
        console.error(error)
    }
}

export async function saveUri(token_id, uri) {
    try {
        // Remove os campos com '0.' do URI antes de salvar
        const cleanUri = removeZeroFieldsFromUri(uri);
        const dbRef = ref(db, `/nfts/${token_id}`);
        return await update(dbRef, { metadata: cleanUri });
    } catch (error) {
        console.error(error)
    }
}

export async function saveTransactionHash(token_id, hash) {
    try {
        const dbRef = ref(db, `/nfts/${token_id}`);
        return await update(dbRef, { hash });
    } catch (error) {
        console.error(error)
    }
}

export function removeZeroFieldsFromUri(uri) {
    // Converte o URI para objeto
    const metadata = uri

    // Remove os campos com '0.' do objeto
    const cleanMetadata = removeZeroFields(metadata);

    // Converte o objeto limpo de volta para URI
    return cleanMetadata
}

export function removeZeroFields(obj) {
    const cleanObj = {};

    for (const key in obj) {
        // Verifica se a chave não começa com '0.'
        if (!key.startsWith('0.')) {
            // Se não começar com '0.', copia para o novo objeto
            cleanObj[key] = obj[key];
        }
    }

    return cleanObj;
}