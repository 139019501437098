import 'primereact/resources/primereact.min.css'
import React, { useEffect, useState } from 'react'
import { InputNumber } from 'primereact/inputnumber'
import { Chips } from 'primereact/chips'
import { Check, WarningCircle, X } from 'phosphor-react'
import { Heading, Subheading } from '../../../../Components/Screen/Text/Heading'
import { Text } from '../../../../Components/Screen/Text/Body'
import { Input, InputDiv } from '../../../../Components/Screen/Input/Text'
import { ButtonOutline } from '../../../../Components/Screen/Button/button'
import { Loading } from '../../../../Components/Screen/Loading/Loading'
import { InputSwitchForm } from './SecondFormModal'
import { useGlobalContext } from '../../../../Context/GlobalContext'
import { InputSwitch } from 'primereact/inputswitch'
// import { getWineColorByType } from '../../../../utils/get-wine-color-by-type'
import { getProducerByFarmId } from '../../../../utils/firebase/farms'
import { Dropdown } from 'primereact/dropdown'
// import { useRegister } from '../../../../utils/firebase/register'
// import { formatSeedTypeName } from '../../../../utils/format-seed-type-name'

export function FirstFormModal({ props }) {
  const { userData } = useGlobalContext()
  const { certificateModal, setCertificateModal, formData, setFormData, harvests } = props
  const [certificateDone, setCertificateDone] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const [winesGuard, setWinesGuard] = useState(formData?.harvestDeclaration?.winesGuard || false)
  const [renovation, setRenovation] = useState(formData?.harvestDeclaration?.renovation || false)
  const [renovationQuantity, setRenovationQuantity] = useState(formData?.harvestDeclaration?.renovationQuantity || '')
  const [wineType, setWineType] = useState(formData?.harvestDeclaration?.wineType || '')
  const [yieldEstimation, setYieldEstimation] = useState(formData?.harvestDeclaration?.yieldEstimation || '')
  const [comercialBrand, setComercialBrand] = useState(formData?.harvestDeclaraton?.comercialBrand || '')
  const [quantity, setQuantity] = useState(formData?.harvestDeclaration?.quantity || '')
  const [responsible, setResponsible] = useState(formData?.harvestDeclaration?.responsible || '')
  const [responsibles, setResponsibles] = useState(formData?.harvestDeclaration?.responsibles || [])
  // const [orderNumber, setOrderNumber] = useState(formData?.harvestDeclaration?.orderNumber || '')
  // const [invoiceNumber, setInvoiceNumber] = useState(formData?.harvestDeclaration?.invoiceNumber || '')
  const [variety, setVariety] = useState(formData?.harvestDeclaration?.variety || '')
  const [kiloQuantityTotal, setKiloQuantityTotal] = useState(formData?.harvestDeclaration?.kiloQuantityTotal || '')
  const [performanceAverage, setPerformanceAverage] = useState(formData?.harvestDeclaration?.performanceAverage || '')
  const [plantAverage, setPlantAverage] = useState(formData?.harvestDeclaration?.plantAverage || '')
  const [babo, setBabo] = useState(formData?.harvestDeclaration?.babo || '')
  const [suppliers, setSuppliers] = useState(formData?.harvestDeclaration?.suppliers || '')
  const [chaptalization, setChaptalization] = useState(formData?.harvestDeclaration?.chaptalization || false)

  function handleModal() {
    setCertificateModal(!certificateModal)
  }

  async function handleSubmit(event) {
    event.preventDefault()
    const harvestDeclaration = {
      winesGuard,
      renovation,
      renovationQuantity,
      wineType,
      yieldEstimation,
      comercialBrand,
      quantity,
      responsibles,
      // orderNumber,
      // invoiceNumber,
      chaptalization,
      variety,
      kiloQuantity: kiloQuantityTotal,
      performance: performanceAverage,
      plant: plantAverage,
      babo,
      supplier: suppliers,
      userId: userData?.id
    }

    setCertificateDone(true)
    setFormData((prevState) => ({ ...prevState, harvestDeclaration }))
    setAddLoading(false)
    setCertificateModal(false)
  }

  useEffect(() => {
    if (harvests?.length > 0) {
      Promise.all(
        harvests.map(async (harvest) => {
          const producer = await getProducerByFarmId(harvest.farmId)
          return producer
        })
      ).then((producers) => {
        const suppliers = producers?.map((producer) => {
          return {
            id: producer.id,
            name: `${producer.firstName} ${producer.lastName}`
          }
        })
        setSuppliers(suppliers)
      })

      const seed_types = harvests.map((harvest) => harvest.seed_type)
      const seed_types_set = new Set(seed_types)
      const seed_types_array = Array.from(seed_types_set)
      const seed_types_string = seed_types_array.join(' - ')
      setVariety(seed_types_string)
    }
  }, [harvests])

  function handleSupplierChange() {
    if (suppliers?.length > 0) {
      const kiloQuantityTotal = suppliers?.reduce((acc, supplier) => acc + supplier.kiloQuantity, 0)
      const performanceAverage = suppliers?.reduce((acc, supplier) => acc + supplier.performance, 0) / suppliers?.length
      const plantAverage = suppliers?.reduce((acc, supplier) => acc + supplier.plant, 0) / suppliers?.length
      setKiloQuantityTotal(kiloQuantityTotal)
      setPerformanceAverage(performanceAverage)
      setPlantAverage(plantAverage)
    }
  }

  return (
    <>
      <div className="flex items-start flex-col w-full max-w-[500px] gap-8">
        <Heading className="cursor-pointer text-branco flex items-center" onClick={handleModal}>
          <span>1. Declaração da safra:</span> <i
            onClick={handleModal}
            className={`ml-2 ${certificateDone ? 'text-attention' : ' text-warning'} text-4xl hover:cursor-pointer`}
          >
            {certificateDone ? <Check size={36} /> : <WarningCircle size={36} />}
          </i>
        </Heading>

        <Subheading className={"text-zinc-400"}>
          O primeiro passo é inserir os dados da sua safra. Aqui você declara os detalhes e registros da produção, rendimento, quantidades, etc.
        </Subheading>

        <ButtonOutline onClick={handleModal} className="w-[200px] self-center">
          Acessar formulário
        </ButtonOutline>
      </div>
      {certificateModal && (
        <div className="modal modal-open w-full backdrop-blur">
          <div className="relative bg-preto border border-gray-500 overflow-auto rounded-3xl p-8">
            {!addLoading && (
              <X
                size={32}
                onClick={handleModal}
                className="absolute  text-attention right-4 top-4 hover:cursor-pointer z-30"
              />
            )}

            <Subheading as="h1" className=" text-attention mb-4 text-center lg:text-4xl">
              Declaração da Safra
            </Subheading>

            {addLoading ? (
              <Loading />
            ) : (
              <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                <div className='flex flex-col md:flex-row w-full gap-12'>
                  <div className='flex flex-col w-full gap-2'>


                    <div className="w-full flex flex-col gap-3 ">
                      <InputSwitchForm
                        name="Vinho de Guarda​"
                        value={winesGuard}
                        setValue={setWinesGuard}
                      />

                      <div className='flex items-center justify-between gap-5'>

                        <div className={`flex flex-col md:flex-row gap-5 justify-between ${renovation === true ? '' : 'w-full'} flex-none items-center`}>
                          <Text as="label" htmlFor={'renovation'} className="flex  text-branco">
                            Renovação
                          </Text>
                          {renovation &&
                            <InputNumber
                              value={renovationQuantity}
                              onValueChange={(e) => setRenovationQuantity(e.value)}
                              suffix=" Garrafas"
                              min={1}
                              className="calendarInput md:!max-w-[120px]"
                              placeholder='Garrafas'
                            />
                          }
                          <InputSwitch
                            onChange={(e) => setRenovation(e.value)}
                            id={'renovation'}
                            checked={renovation}

                          />
                        </div>

                      </div>

                      <InputSwitchForm
                        name="Chaptalização até 1º"
                        value={chaptalization}
                        setValue={setChaptalization}
                      />

                      <Text as="label" htmlFor={'Crop Type'}>
                        Tipo de vinho
                      </Text>
                      <Dropdown
                        value={wineType}
                        options={[
                          {
                            "name": "Tinto",
                            "value": "Tinto"
                          },
                          {
                            "name": "Branco",
                            "value": "Branco"
                          },
                          {
                            "name": "Espumante",
                            "value": "Espumante"
                          }
                        ]}
                        onChange={(e) => setWineType(e.value)}
                        optionLabel="name"
                        editable
                        className="calendarInput"
                      />
                    </div>


                    <InputCrop
                      name={'Tanque/Barril nºs.'}
                      value={yieldEstimation}
                      setValue={setYieldEstimation}
                    />


                    <div className="w-full flex flex-col ">
                      <Text as="label" htmlFor={'Yield Estimation'}>
                        Quantidade
                      </Text>
                      <InputNumber
                        value={quantity}
                        onValueChange={(e) => setQuantity(e.value)}
                        min={1}
                        suffix=' L'
                        className="calendarInput"
                      />
                    </div>

                    <InputCrop
                      name={'Responsável'}
                      value={responsible}
                      setValue={setResponsible}
                    />

                    <div className="w-full flex flex-col ">
                      <Text as="label" htmlFor={'Parcel IDs'}>
                        Acompanhantes Autorizados (separados por vírgula)
                      </Text>
                      <Chips
                        value={responsibles}
                        onChange={(e) => setResponsibles(e.value)}
                        separator=","
                        className="calendarInput"

                      />
                    </div>
                  </div>
                  <div className='flex flex-col w-full gap-2'>
                    <InputCrop
                      name={'Variedade'}
                      value={variety}
                      setValue={() => { "" }}
                    />

                    <div className="w-full flex flex-col flex-none">
                      <table className="w-full my-4">
                        <thead className='border border-gray-500'>
                          <tr>
                            <th className='border-r border-gray-500'>Fornecedor</th>
                            <th className='border-r border-gray-500'>Nº Ordem</th>
                            <th className='border-r border-gray-500'>Kg</th>
                            <th className='border-r border-gray-500'>Rendimento</th>
                            <th className='border-r border-gray-500'>Planta</th>
                            <th>Nº NF</th>
                          </tr>
                        </thead>
                        <tbody>
                          {suppliers?.length > 0 && suppliers?.map((supplier, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <td rowSpan={1}>{supplier.name}</td>
                                <td>
                                  <div className='flex flex-col mt-2'>
                                    <InputDiv>
                                      <Input
                                        name={'Nº Ordem'}
                                        value={supplier.orderNumber}
                                        onChange={(e) => {
                                          setSuppliers((prevState) => {
                                            const newState = [...prevState]
                                            newState[index].orderNumber = e.target.value
                                            return newState
                                          })
                                        }}
                                        required
                                        placeholder='1'
                                      />
                                    </InputDiv>
                                  </div>
                                </td>
                                <td>
                                  <InputNumber
                                    value={supplier.kiloQuantity}
                                    onValueChange={
                                      (e) => {
                                        setSuppliers((prevState) => {
                                          const newState = [...prevState]
                                          newState[index].kiloQuantity = e.value
                                          return newState
                                        })
                                        handleSupplierChange()
                                      }
                                    }
                                    suffix=" kg"
                                    placeholder="1 kg"
                                    // min={1}
                                    // minFractionDigits={2} maxFractionDigits={5}
                                    className="calendarInput"
                                    required
                                  />
                                </td>
                                <td>
                                  <InputNumber
                                    value={supplier.performance}
                                    onValueChange={(e) => {
                                      setSuppliers((prevState) => {
                                        const newState = [...prevState]
                                        newState[index].performance = e.value
                                        return newState
                                      })
                                      handleSupplierChange()
                                    }}
                                    placeholder='1 kg/ha'
                                    suffix=" kg/ha"
                                    // min={1}
                                    minFractionDigits={2} maxFractionDigits={5}
                                    className="calendarInput"
                                    required
                                  />
                                </td>
                                <td>
                                  <InputNumber
                                    value={supplier.plant}
                                    onValueChange={(e) => {
                                      setSuppliers((prevState) => {
                                        const newState = [...prevState]
                                        newState[index].plant = e.value
                                        return newState
                                      })
                                      handleSupplierChange()
                                    }}
                                    placeholder='1 kg/planta'
                                    suffix=" kg/planta"
                                    // min={1}
                                    minFractionDigits={2} maxFractionDigits={5}
                                    className="calendarInput"
                                    required
                                  />
                                </td>

                                <td>
                                  <div className='flex flex-col mt-2'>
                                    <InputDiv>
                                      <Input
                                        name={'Nº NF'}
                                        value={supplier.invoiceNumber}
                                        onChange={(e) => {
                                          setSuppliers((prevState) => {
                                            const newState = [...prevState]
                                            newState[index].invoiceNumber = e.target.value
                                            return newState
                                          })
                                        }}
                                        placeholder='123456'
                                        required
                                      />
                                    </InputDiv>
                                  </div>
                                </td>

                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                      <InputCrop
                        name={'Kg Total'}
                        value={isNaN(kiloQuantityTotal) ? "Por favor, preencha todos os campos dos fornecedores." : kiloQuantityTotal && kiloQuantityTotal?.toFixed(2)}
                        required
                        readOnly
                      />

                      <InputCrop
                        name={'Rendimento Médio por Hectare'}
                        value={isNaN(performanceAverage) ? "Por favor, preencha todos os campos dos fornecedores." : performanceAverage && performanceAverage?.toFixed(2)}
                        readOnly
                        required
                      />

                      <InputCrop
                        name={'Rendimento Médio por Planta'}
                        value={isNaN(plantAverage) ? "Por favor, preencha todos os campos dos fornecedores." : plantAverage && plantAverage?.toFixed(2)}
                        readOnly
                        required
                      />


                    </div>

                    <InputCrop
                      name={'º Babo'}
                      value={babo}
                      setValue={setBabo}
                    />

                    <InputCrop
                      name={'Marca Comercial'}
                      value={comercialBrand}
                      setValue={setComercialBrand}
                    />


                  </div>
                </div>



                <div className="flex items-center justify-center pt-4">
                  <ButtonOutline type="submit" disabled={addLoading}>
                    {addLoading ? 'Carregando...' : 'Salvar'}
                  </ButtonOutline>
                </div>

              </form>
            )}
          </div>
        </div >
      )
      }
    </>
  )
}

export const InputCrop = ({ name, value, setValue, ...rest }) => {
  return (
    <div className='flex flex-col gap-1'>
      <Text as={'label'} htmlFor={name}>
        {name}
      </Text>
      <InputDiv>
        <Input
          id={name}
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          required
          {...rest}
        />
      </InputDiv>
    </div>
  )
}
