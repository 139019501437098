import React from 'react';
import { ButtonOutline } from '../../../../Components/Screen/Button/button';
import { X } from 'phosphor-react';
import { Subheading } from '../../../../Components/Screen/Text/Heading';
import { Text } from '../../../../Components/Screen/Text/Body';

export const InstructiveModal = ({ instructiveModal, setInstructiveModal }) => {
    function handleModal() {
        setInstructiveModal(!instructiveModal);
    }
    return (
        <>
            <ButtonOutline onClick={handleModal} type="button">Instrutivo</ButtonOutline>
            {instructiveModal && (
                <div className="modal modal-open backdrop-blur ">
                    <div className="relative h-[50%]  bg-preto border border-gray-500 rounded-3xl p-4 lg:p-8 mx-5 md:max-w-[50%]">

                        <X
                            size={32}
                            onClick={handleModal}
                            className="absolute  text-attention right-4 top-4 hover:cursor-pointer z-30"
                        />

                        <Subheading as="h1" className=" text-attention mb-4 text-center lg:text-4xl">
                            Instrutivos de documentos
                        </Subheading>

                        <div className="flex flex-col gap-2 overflow-y-auto h-[90%]">
                            <Text>
                                1. Carregue seu pacote inicial que inclui georreferenciamento emitido por agrimensor homologado Documento Poligonal da área referenciada
                                último CAR
                            </Text>
                            <Text>
                                2. Insira os dados da sua propriedade incluindo: CPF, matricula, CPR, RG, etc
                            </Text>
                            <Text>
                                3. Insira a documentação dos polígonos descritivos da área quanto da produção, caso não tenha esta documentação, nos podemos auxilia-lo!
                            </Text>
                            <Text>
                                Considerações: Insira os dados da internalização da estrutura - Id de objeto do conjunto poligonal considerando matricula associada aos vértice descritivos.
                            </Text>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
};