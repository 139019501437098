import { child, get, ref } from "firebase/database";
import { db } from "../../firebase";

export async function getHarvestById(harvestId) {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `/harvests`));
  if (snapshot?.exists()) {
    const harvestsSnap = snapshot.val();
    const harvest = Object.values(harvestsSnap).find(harvest => harvest?.id === harvestId);
    return harvest;
  } else {
    return []
  }
}

export async function getHarvestsFromFarmId(farmId) {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `/harvests`));
  if (snapshot?.exists()) {
    const harvestsSnap = snapshot.val();
    const harvests = Object.values(harvestsSnap).filter(harvest => harvest?.farmId === farmId);
    return harvests;
  } else {
    return []
  }
}

export async function getHarvestsFromWineryId(wineryId) {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `/harvests`));
  if (snapshot?.exists()) {
    const harvestsSnap = snapshot.val();
    const harvests = Object.values(harvestsSnap).filter(harvest => harvest?.wineryId === wineryId);
    return harvests;
  } else {
    return []
  }
}

export async function getHarvestsFromWineCellarId(wineCellarId) {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `/harvests`));
  if (snapshot?.exists()) {
    const harvestsSnap = snapshot.val();
    const harvests = Object.values(harvestsSnap).filter(harvest => harvest?.wine_cellar_id === wineCellarId);
    return harvests;
  } else {
    return []
  }
}

// status: pending, in_progress, finished
export async function getHarvestsFromStatus(status) {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `/harvests`));
  if (snapshot?.exists()) {
    const harvestsSnap = snapshot.val();
    const harvests = Object.values(harvestsSnap).filter(harvest => harvest?.status === status);
    return harvests;
  } else {
    return []
  }
}

export async function getHarvestsFromSeedType(seedType) {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `/harvests`));
  if (snapshot?.exists()) {
    const harvestsSnap = snapshot.val();
    const harvests = Object.values(harvestsSnap).filter(harvest => harvest?.seed_type === seedType);
    return harvests;
  } else {
    return []
  }
}

export async function getHarvestsFromResponsibleId(responsibleId) {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `/harvests`));
  if (snapshot?.exists()) {
    const harvestsSnap = snapshot.val();
    const harvests = Object.values(harvestsSnap).filter(harvest => harvest?.responsibleId === responsibleId);
    return harvests;
  } else {
    return []
  }
}

export async function getAllHarvests() {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `/harvests`));
  if (snapshot?.exists()) {
    const harvestsSnap = snapshot.val();
    const harvests = Object.values(harvestsSnap);
    return harvests;
  } else {
    return []
  }
}