export function returnStatusHarvest(status) {
  switch (status) {
    case 'pending':
      return 'Em andamento na vinícola'
    case 'in_progress':
      return 'Em análise pela associação'
    case 'approved':
      return 'Aprovado pela associação'
    case 'rejected':
      return 'Rejeitado pela associação'
    default:
      return 'Pendente'
  }
}