import { toast } from "react-toastify"
import { Button } from "../../../Components/Screen/Button/button"
import { Copy } from "phosphor-react"

export const InfosNFTComponent = ({ nft, token_id, hash }) => {
    const url = process.env.REACT_APP_LOCAL_ENV === 'dev' ? `https://amoy.polygonscan.com/tx/${hash?.transaction_hash}` : `https://polygonscan.com/tx/${hash?.transaction_hash}`

    function copyToClipboard(text) {
        navigator.clipboard.writeText(text)
        toast('Hash copiado para a área de transferência', {
            type: 'success',
        })
    }
    return (
        <>
            <ul>
                <h1 className="text-white text-2xl font-bold">{nft?.name} - id: {token_id}</h1>
                {nft?.attributes?.map((attribute) => (
                    <li
                        className="text-sm text-white"
                        key={attribute?.trait_type}
                    >
                        {attribute?.trait_type}: {attribute?.value}
                    </li>
                ))}
                <li className="text-sm text-white mb-2 md:hidden flex gap-2 items-center">
                    Blockchain hash: {hash?.transaction_hash?.slice(0, 10)}...{hash?.transaction_hash?.slice(-10)} <Copy size={28} onClick={() => copyToClipboard(hash?.transaction_hash)} />
                </li>
                <li className="text-sm text-white mb-2 hidden md:flex gap-2 items-center ">
                    Blockchain hash: {hash?.transaction_hash} <Copy size={28} onClick={() => copyToClipboard(hash?.transaction_hash)} />
                </li>
                <a
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center justify-center"
                    title="Clique para acessar a transação no PolygonScan"
                >
                    <Button>
                        Acessar transação
                    </Button>
                </a>
            </ul>
        </>
    )
}