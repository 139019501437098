import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { SvgStep2 } from '../assets/SvgStep2'

export const Step2 = ({ mediaQuery }) => {
  return (
    <article className="flex flex-col gap-4 my-10">
      <div className="lg:flex lg:justify-between lg:gap-24 lg:items-center">
        {mediaQuery && (
          <img
            src={require('../assets/step2.jpg')}
            alt="art"
            className="w-1/3 h-[20rem] rounded-3xl"
            loading="eager"
          />
        )}
        <div className="lg:flex lg:flex-col gap-3 ">
          <Text className="flex flex-row items-center gap-4">
            <SvgStep2 />
            <Text className="flex flex-col text-white">
              Etapa 2 <Heading className="text-attention">Perícia</Heading>
            </Text>
          </Text>
          <ul className="px-6 font-medium list-disc lg:text-lg">
            <li>
              <Text>
                Estudos e relatórios por especialistas na vinícola/proprietário
              </Text>
            </li>
            <li>
              <Text>
                A Origem RS mantém um banco de dados de especialistas, currículo e
                trabalhos reconhecidos por eles
              </Text>
            </li>
            <li>
              <Text>
                Todos os estudos são acessíveis à comunidade e serão avaliados
                por eles, podendo ser abertas inquirições formais.
              </Text>
            </li>
            <li>
              <Text>
                No caso de perguntas formais, elas serão encaminhadas ao
                proprietário da vinícola, que poderá responder com explicações ou
                novos documentos
              </Text>
            </li>
            <li>
              <Text>
                Com o tempo, cada especialista poderá acessar a plataforma para
                validar seus estudos
              </Text>
            </li>
          </ul>
        </div>
      </div>
    </article>
  )
}
