import { useRef } from 'react'
import { ClipboardText, X } from 'phosphor-react'
import useOnClickOutside from '../../../../utils/clickOutside'
import { Subheading } from '../../../../Components/Screen/Text/Heading'
import { Text } from '../../../../Components/Screen/Text/Body'
import { useGlobalContext } from '../../../../Context/GlobalContext'
import { returnStatusHarvest } from '../../../../utils/return-status-harvest'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getHarvestsFromFarmId } from '../../../../utils/firebase/harvests'
import { ButtonOutline } from '../../../../Components/Screen/Button/button'
import { formatSeedTypeName } from '../../../../utils/format-seed-type-name'

export function AssociateModal({ showModalDocuments, setShowModalDocuments, farm }) {
  const { userData } = useGlobalContext()
  const { data: harvests } = useQuery({
    queryKey: ['harvestsAssociate'],
    queryFn: () =>
      getHarvestsFromFarmId(farm?.id).then((harvests) => {
        return harvests
      })
    ,
    enabled: !!userData?.id
  })

  const ref = useRef()

  useOnClickOutside(ref, () => {
    setShowModalDocuments(false)
  })



  return (
    <>
      <div className="flex gap-2">
        <ClipboardText
          size={48}
          className="bg-cinzaescuro text-branco rounded-full p-2"
        />
        <div className="flex flex-col items-start justify-center">
          <Subheading>Solicitações de plantio ({harvests?.length})</Subheading>

          <Text className={'text-attention cursor-pointer'} onClick={() => { setShowModalDocuments(true) }}>Ver solicitações</Text>
        </div>
      </div>
      {showModalDocuments &&
        <div className="modal modal-open w-full backdrop-blur">
          <div className="relative bg-preto border border-gray-500 overflow-auto rounded-3xl p-8 lg:min-w-[70%]"
            ref={ref}
          >
            <span
              className="text-attention  absolute right-4 top-4 cursor-pointer"
              onClick={() => setShowModalDocuments(!setShowModalDocuments)}
            >
              <X size={32} />
            </span>
            <div className="flex justify-center mb-5">

            </div>
            <div className='w-full flex flex-1 items-center justify-center'>
              <Subheading className="mb-4 text-attention lg:!text-4xl">
                Perfil do plantio
              </Subheading>
            </div>
            {harvests?.length > 0 ? (
              harvests?.map((document) => {
                const crocImage = document?.crocImage ?? 'https://images.unsplash.com/photo-1543051932-6ef9fecfbc80?q=80&w=1924&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                const status = returnStatusHarvest(document?.status)
                return <><div
                  key={document?.id}
                  className="flex text-center flex-col md:flex-row items-center gap-1 md:gap-6 mb-4 w-full lg:text-xl select-none"
                >
                  {crocImage && (
                    <div className="flex justify-center pt-2"
                      onClick={() => window.open(crocImage, '_blank')}
                    >
                      <img
                        src={crocImage}
                        alt="preview"
                        className="object-cover object-center w-32 h-32 hover:cursor-pointer rounded-3xl"
                        title='Clique para visualizar a imagem em tamanho maior'
                        loading="eager" />
                    </div>
                  )}
                  <div className="flex flex-col flex-1 font-semibold text-sm md:text-lg text-start ">
                    <Text>Vinícola: {farm?.name} id:{document?.id.slice(0, 4)}</Text>
                    <Text>Responsável na vinícola: {document?.responsibleName}</Text>
                    <Text>Uva: {formatSeedTypeName(document?.seed_type)}</Text>
                    <Text>Cadastro Vitícola: {document?.wine_cellar_id}</Text>
                  </div>

                  <div className="flex flex-col flex-1  text-xs md:text-base ">

                    <Text className={``}>Status: <span className={`
                    ${document?.status === "approved" && "!text-green-500"}
                    ${document?.status === "pending" && "!text-blue-500"}
                    ${document?.status === "in_progress" && "!text-yellow-500"}
                    ${document?.status === "rejected" && "!text-red-500"}
                    `}>{status}</span></Text>

                    <Text>Data: {new Date(document?.date).toLocaleDateString()}</Text>
                    {document?.status === 'in_progress' &&
                      <Link to={`/production/approve/${farm?.id}?id=${document?.id}`} className={"mt-2"}>
                        <ButtonOutline>Avaliar</ButtonOutline>
                      </Link>}
                  </div>
                </div><div className="border-t-2 border-gray-500 w-full my-8"></div></>
              })
            ) : (
              <div className="flex flex-col items-center justify-center">
                <Text>Sem documentos</Text>
              </div>
            )}
          </div>
        </div>
      }
    </>
  )
}
