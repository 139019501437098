import { ArrowUpRight } from 'phosphor-react'
import { Link } from 'react-router-dom'
import { Text } from '../../../Components/Screen/Text/Body'

export const Checkbox = ({ role, setRole }) => {
  function handleChangeRole(e) {
    const value = e.target.value;
    setRole(value);
  }

  return (
    <div className="lg:flex lg:items-center lg:justify-center lg:flex-row w-full flex flex-col gap-3 lg:gap-6 mt-4 ">
      <label className="flex justify-start items-center gap-2">
        <select
          className="bg-transparent border-2 border-primary text-white w-full rounded-3xl p-2"
          required
          value={role}
          onChange={handleChangeRole}
        >
          <option value={0} className='bg-black'>Role</option>
          <option value={1} className='bg-black'>Produtor</option>
          <option value={2} className='bg-black'>Vinícola</option>
          {/* <option value={3} className='bg-black'>Associação</option> */}
        </select>
      </label>
      <label className="flex justify-start items-center lg:text-2xl gap-2">
        <input
          type="checkbox"
          className="checkbox checkbox-primary rounded-xl"
          required
        />
        <Text>Eu aceito os termos de uso</Text>
        <Link to="#" title="Abrir os termos em outra aba do navegador">
          <ArrowUpRight size={24} className="text-attention" />
        </Link>
      </label>
      <label className="flex justify-start items-center gap-2 lg:text-2xl ">
        <input
          type="checkbox"
          className="checkbox checkbox-primary rounded-xl"
          required
        />
        <Text>Eu aceito a política de privacidade</Text>
        <Link
          to="#"
          title="Abrir a política de privacidade em outra aba do navegador"
        >
          <ArrowUpRight size={24} className="text-attention" />
        </Link>
      </label>
    </div>
  )
}
