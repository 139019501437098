import { Heading, Subheading } from '../../../../Components/Screen/Text/Heading'
import { CountStats } from './CountStats'
import { ImageContainer } from './imageContainer'

export const Market = () => {
  return (
    <section id="marketplace" className="w-full h-full flex flex-col gap-10">
      <CountStats />
      <div className="flex flex-col text-center">
        <Subheading>
          Seu acesso ao mundo de Blockchain para sua produção vinícola.
        </Subheading>
        <Heading className="text-attention">
          NFTs com certificado
          <Heading> de D.O. </Heading>
        </Heading>
      </div>
      <div className="relative flex items-center justify-center w-full">
        <ImageContainer />
      </div>
     
    </section>
  )
}
