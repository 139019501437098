import Avatar from '../Avatar'
import DropdownMobile from './Dropdown'
import { Logo } from '../../../assets/logo'

export default function NavbarMobile() {
  return (
    <nav
      className="fixed z-[60] flex items-center justify-around w-full text-branco h-20 border-b border-b-zinc-700"
      style={{
        background:
          'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(0,0,0, 0.7) 0%, rgba(0,0,0, 0.5447917) 77.08%, rgba(70, 144, 213, 0) 100%)',
        backdropFilter: 'blur(20px)',
      }}
    >
      <Logo sebraex size={100} />
      <section className={`flex items-center gap-6`}>
        <Avatar />

        <DropdownMobile />
      </section>
    </nav>
  )
}
