import { child, get, ref } from "firebase/database"
import { db } from "../../firebase"

export async function getLogsByUserId(userId) {
  const dbRef = ref(db);
  const usersSnapshot = await get(child(dbRef, `/logs`));
  if (usersSnapshot?.exists()) {
    const logsSnap = usersSnapshot.val();
    const logs = Object.values(logsSnap);
    return logs.filter((log) => log.user_target === userId);
  } else {
   return []
  }
}
