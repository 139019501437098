import { Gear, User } from 'phosphor-react'
import { Text } from '../../../../Components/Screen/Text/Body'
import { ChangeProfilePic } from '../ChangeProfilePic'
import { useNavigate } from 'react-router-dom'

export const UserInfo = (props) => {
  const { userData, isGearOpen, handleGear } = props
  const navigate = useNavigate()

  return (
    <article
      className={`flex flex-col gap-4 absolute z-20 top-48 p-10 w-[80%] lg:w-[40%] bg-cinza bg-opacity-30 backdrop-blur justify-center items-center rounded-lg`}
    >
      {isGearOpen && <ChangeProfilePic {...props} />}
      <i className="absolute right-4 cursor-pointer top-4">
        <Gear size={24} onClick={() => navigate('/profile/editProfile')} />
      </i>
      {userData?.profilePicture ? (
        <img
          src={userData?.profilePicture}
          alt="Avatar"
          className="rounded-full h-36 w-36 object-cover bg-gray-600"
          loading="eager"
          onClick={handleGear}
        />
      ) : (
        <div className="bg-gray-600 rounded-full p-10" onClick={handleGear}>
          <User size={80} color="white" />
        </div>
      )}

      <Text className="font-light text-gray-50 flex items-center justify-center text-center h-full w-full">{`${userData?.firstName} ${userData?.lastName}`}</Text>
    </article>
  )
}
