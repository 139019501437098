import axios from 'axios'

export async function getAddress(cep) {
  try {
    if (cep.length === 8) {
      // const res = await axios(`https://viacep.com.br/ws/${cep}/json/`);
      const res = await axios(`https://cep.awesomeapi.com.br/json/${cep}`)
      const { data } = res
      return data
    }
  } catch (error) {
    console.error(error)
  }
}
