export function returnUserRole(role) {
  switch (role) {
    case 1:
      return 'Produtor'
    case 2:
      return 'Vinícola'
    case 3:
      return 'Associação'
    default:
      return ''
  }
}