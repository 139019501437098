import { useState } from 'react'

export default function NFTList({ nft }) {
  const [show, setShow] = useState(false)
  const [errorImage, setErrorImage] = useState(false)
  const item = JSON.parse(nft?.metadata)

  const HandleGetType = () =>
    item?.attributes?.map((item) => {
      return (
        <span key={item.trait_type}>
          {item.trait_type} : {item.value}
        </span>
      )
    })

  const Image = () => {
    return (
      <>
        <img
          src={item?.image}
          alt={item?.name}
          className={`w-20 h-20 rounded-full ${show ? '' : 'animate-pulse bg-white/40'
            }`}
          onLoad={() => setShow(true)}
          onError={() => {
            setErrorImage(true)
            setShow(true)
          }}
          loading="eager"
        />
        {errorImage && (
          <div className="absolute top-[50%] pl-5  z-40 text-white">
            Falha ao carregar, por favor recarregue a página
          </div>
        )}
        {!show && (
          <div className="absolute z-40 text-white top-[50%] pl-5 ">
            Carregando a partir da Blockchain, por favor aguarde...
          </div>
        )}
      </>
    )
  }

  // function getOpenSeaLink() {
  //   if (process.env.REACT_APP_LOCAL_ENV === "dev") {
  //     return `https://testnets.opensea.io/assets/amoy/${process.env.REACT_APP_nft_contract}/${nft?.token_id}`
  //   }
  //   return `https://opensea.io/assets/matic/${process.env.REACT_APP_nft_contract}/${nft?.token_id}`
  // }

  function viewNFTDetails() {
    window.open('/nft/' + nft?.token_id, '_blank', 'noopener', 'noreferrer')
  }


  return (
    <div className="flex flex-row items-center justify-around w-full select-none">
      <Image alt="" />
      <div className="flex flex-col text-xs text-cinza">
        <span className="text-branco font-light text-xl cursor-pointer" onClick={viewNFTDetails}
          title='Clique para ver mais detalhes'>{item?.name}</span>
        <HandleGetType />
      </div>
    </div>
  )
}
