import React from 'react'
import { GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { CabernetSauvignonCoords, CabernetSauvignonCoords2, CabernetSauvignonCoords3, ChardonnayCoords, ChardonnayCoords2, MerlotCoords, MerlotCoords2 } from '../../../utils/locations-dc';
import { Cabernet_Sauvignon_cords6, Malbec_cords6, Merlot_cords6, Tannat_cords6 } from '../../../utils/locations-piz';

const containerStyle = {
  width: '100%',
  maxWidth: '600px',
  height: '500px',
  borderRadius: "12px",
};

function WineMap({ nftId }) {
  let center;
  nftId === 1 && (center = { lat: -29.182496, lng: -51.555272 });
  nftId === 2 && (center = { lat: -29.1805022, lng: -51.5560335 });
  (nftId === 3 || nftId === 4) && (center = { lat: -29.1810306, lng: -51.5556838 });
  nftId === 6 && (center = { lng: -51.602916, lat: -29.171228 });


  let zoom;
  nftId === 1 && (zoom = 18);
  nftId === 2 && (zoom = 16);
  (nftId === 3 || nftId === 4) && (zoom = 16);
  nftId === 6 && (zoom = 16);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCWKI_QgQfZEcirIAT-rq3drF_GWFWMUf4"
  })

  let paths = []
  let options = {}

  if (nftId === 1) {
    paths.push(ChardonnayCoords);
    paths.push(ChardonnayCoords2);
    options = {
      strokeColor: "#9aff17",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#9aff17",
      fillOpacity: 0.35,
    }
  }

  if (nftId === 2) {
    paths.push(CabernetSauvignonCoords);
    paths.push(CabernetSauvignonCoords2);
    paths.push(CabernetSauvignonCoords3);
    options = {
      strokeColor: "#a40532",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#a40532",
      fillOpacity: 0.35,
    }

  }

  if (nftId === 3 || nftId === 4) {
    paths.push(MerlotCoords);
    paths.push(MerlotCoords2);
    options = {
      strokeColor: "#8d5a99",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#8d5a99",
      fillOpacity: 0.35,
    }
  }
  if (nftId === 6) {
    paths.push(Cabernet_Sauvignon_cords6)
    paths.push(Malbec_cords6)
    paths.push(Merlot_cords6)
    paths.push(Tannat_cords6)

    options = {
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#8d5a99",
      fillOpacity: 0.35,
    }
  }

  if (nftId !== 1 && nftId !== 2 && nftId !== 3 && nftId !== 4 && nftId !== 6) return null

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      options={{
        mapTypeId: "satellite",
      }}
    >
      <Polygon paths={paths} options={options} />
    </GoogleMap>
  ) : <></>
}

export default React.memo(WineMap)