import { Link } from 'react-router-dom'
import { Subtext } from '../../../Screen/Text/Body'

export const Links = () => {
  return (
    <div className="flex gap-20 items-center">
      <article className="flex flex-col justify-center gap-3">
        <Link to="/provenance">
          <Subtext className={"hover:link-hover"}>Como funciona</Subtext>
        </Link>
        <Link to="/marketplace">
          <Subtext className={"hover:link-hover"} >Marketplace</Subtext>
        </Link>
        <Link to="/farms">
          <Subtext className={"hover:link-hover"} >Vinícolas</Subtext>
        </Link>
        <a href="/whitepaper">
          <Subtext className={"hover:link-hover"} >Whitepaper</Subtext>
        </a>
      </article>
    </div>
  )
}
