import { ref, update } from "firebase/database"
import { db } from "../../firebase"
import { v4 as uuidv4 } from 'uuid';

//both ids are from user id
export async function wineryAddedDocumentsLog(wineryId, associateId, wineryName, farmName, farmId, harvestId) {
  try {
    const logId = uuidv4()
    await update(ref(db, `logs/${logId}`), {
      id: logId,
      wineryId,
      action: 'wineryAddedDocumentsLog',
      date: new Date().toISOString(),
      user_target: associateId,
      associateId,
      wineryName,
      farmName,
      harvestId,
      read: false,
    })



    return logId
  } catch (err) {
    console.error(err)
  }
}

export async function wineryUpdatedDocumentsLog(wineryId, associateId, wineryName, requestId) {
  try {
    const logId = uuidv4()
    await update(ref(db, `logs/${logId}`), {
      id: logId,
      wineryId,
      action: 'wineryUpdatedDocumentsLog',
      date: new Date().toISOString(),
      associateId,
      user_target: associateId,
      wineryName,
      requestId,
      read: false,
    })

    return logId
  } catch (err) {
    console.error(err)
  }
}

export async function warnFarmerAboutProductionFiles(farmerId, wineryId, wineryName, farmId) {
  try {
    const logId = uuidv4()
    await update(ref(db, `logs/${logId}`), {
      id: logId,
      farmerId,
      wineryId,
      wineryName,
      user_target: farmerId,
      action: 'warnFarmerAboutProductionFiles',
      date: new Date().toISOString(),
      farmId,
      read: false,
    })


    return logId
  } catch (err) {
    console.error(err)
  }
}

export async function warnFarmerAboutUpdateProductionFiles(farmerId, wineryId, wineryName, farmId) {
  try {
    const logId = uuidv4()
    await update(ref(db, `logs/${logId}`), {
      id: logId,
      farmerId,
      wineryId,
      wineryName,
      user_target: farmerId,
      action: 'warnFarmerAboutUpdateProductionFiles',
      date: new Date().toISOString(),
      farmId,
      read: false,
    })

    return logId
  } catch (err) {
    console.error(err)
  }
}