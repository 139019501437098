import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination, EffectCoverflow } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';

import ImageOne from '../../../Marketplace/assets/aprovale.jpg'
// import ImageTwo from '../../../Marketplace/assets/Miolo.jpg'
import ImageThree from '../../../Marketplace/assets/domcandido.jpg'
import ImageFour from '../../../Marketplace/assets/Pizzato.jpg'
import { useGlobalContext } from '../../../../Context/GlobalContext'

const images = [ImageOne, ImageThree, ImageFour]

export const ImageContainer = () => {
  const { mediaQuery } = useGlobalContext()
  return (
    <Swiper
      centeredSlides={true}
      effect={'coverflow'}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      slidesPerView={
        mediaQuery === 'mobile' ? 1 : 4
      }
      spaceBetween={20}
      className="mySwiper carousel w-[90%]"
      loop={true}
      autoplay={{
        delay: 2000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }}
      modules={[Navigation, Pagination, Autoplay, EffectCoverflow]}
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <img src={image} alt={``} className="object-fill w-full h-64 rounded-3xl" />

        </SwiperSlide>
      ))
      }


    </Swiper>
  )
}
