import { Gear } from 'phosphor-react'
import { ChangeBackgroundPic } from '../ChangeBackgroundPic'

export const BgImage = (props) => {
  const { isGearBackgroundOpen, handleGearBackground, userData } = props
  return (
    <div className="relative object-cover object-center w-full h-64">
      {isGearBackgroundOpen && <ChangeBackgroundPic {...props} />}
      <img
        src={
          userData?.backgroundPicture ||
          'https://trustbridge.com/wp-content/uploads/2022/01/Wine-glass-web-banner-2.png'
        }
        alt="Background"
        className="object-cover object-center w-full h-64"
        loading="eager"
      />

      <i
        className="absolute right-2 z-40 cursor-pointer top-2 text-branco bg-black/30 backdrop-blur flex justify-center items-center p-2 rounded-2xl"
        onClick={handleGearBackground}
      >
        <Gear size={24} />
      </i>
    </div>
  )
}
