import Avatar from '../Avatar'
import { Link, useLocation } from 'react-router-dom'
import { useGlobalContext } from '../../../Context/GlobalContext'
import { useQuery } from '@tanstack/react-query'
import { getLogsByUserId } from '../../../utils/logs/get-logs'
import { NotificationDropdown } from '../../Notifications/Notifications'
import { ReactComponent as Logo } from '../../../assets/xlabblue.svg'
import { useNavigate } from 'react-router-dom'

export default function NavbarMobile() {
  const { userData } = useGlobalContext()
  const { data: notifications } = useQuery({
    queryKey: ['userLogs'],
    queryFn: () =>
      getLogsByUserId(userData?.id).then((res) => {
        return res
      })
    ,
    enabled: !!userData?.id,
    refetchInterval: 10000
  })


  const color = 'text-xl lg:text-2xl'
  const location = useLocation()
  const navigate = useNavigate()


  return (
    <div className="text-branco flex flex-row items-center fixed w-full z-[60] h-20 border-b border-b-zinc-700 bg-black/70 backdrop-blur">
      <section className="flex flex-row items-center font-light w-full justify-between px-5 lg:px-10 xl:px-20">
        <Logo height={70} width={200} className='cursor-pointer' onClick={
          () => navigate('/')
        } />

        <div className="flex flex-row  items-center gap-10">
          <div className="flex gap-12 items-center">
            <Link to="/" className={location.pathname === '/' ? color : ''}>
              Início
            </Link>
            <Link
              to="/marketplace"
              className={location.pathname === '/marketplace' ? color : ''}
            >
              Marketplace
            </Link>
            <Link
              to="/provenance"
              className={location.pathname === '/provenance' ? color : ''}
            >
              Como funciona
            </Link>
            <Link
              to="/whitepaper"
              className={location.pathname === '/whitepaper' ? color : ''}
            >
              Whitepaper
            </Link>
          </div>
          <div className="flex items-center gap-4">
            <Avatar />
            <NotificationDropdown notifications={notifications} />
          </div>
        </div>
      </section>
    </div>
  )
}
