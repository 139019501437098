import { ArrowRight } from 'phosphor-react'
import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import '../assets/page.css'
import { useGlobalContext } from '../../../../Context/GlobalContext'

export function Step0ArtRegister({ setStep }) {
  const { userData } = useGlobalContext()
  return (
    <div>
      <section className="flex flex-col items-center justify-between pt-10 w-full h-full">
        <div className="w-full p-5 text-center flex flex-col">
          <Heading className="text-attention mb-3">
            Caro(a) <span className="font-bold">{userData?.firstName}</span>
          </Heading>
          <Text className="text-branco font-light">
            Vamos começar registrando sua produção <br />
            São apenas 5 simples passos até você obter sua certificação D.O. no Blockchain!
          </Text>
        </div>

        <div className="w-5/6">
          <div className="steps">
            <div className="item blue">
              <h5>1º</h5>
              <span>
                Registro
                <br />
                Área
              </span>
            </div>
            <div className="item bege">
              <h5>2º</h5>
              <span>
               Registro
                <br />
                Safra
              </span>
            </div>
            <div className="item accent">
              <h5>3º</h5>
              <span>
                Enviar
                <br /> 
                Documentos
              </span>
            </div>
            <div className="item rosa">
              <h5>4º</h5>
              <span>
                Detalhes da
                <br />
                Safra e Uva
              </span>
            </div>
            <div className="item darkblue">
              <h5>5º</h5>
              <span>
              Certificação D.O.
                <br /> no Blockchain
              </span>
            </div>
          </div>
        </div>

        <div className="w-full flex items-center pt-10 text-right pr-6 md:pr-32 justify-end">
          <ArrowRight
            size={48}
            weight="bold"
            className="text-attention hover:cursor-pointer"
            onClick={() => setStep(2)}
          />
        </div>
      </section>
    </div>
  )
}
