import { useState } from 'react'
import { FirstFormModal } from './FirstFormModal'
import { SecondFormModal } from './SecondFormModal'
import { Check, WarningCircle, X } from 'phosphor-react'
import { Heading, Subheading } from '../../../../Components/Screen/Text/Heading'
import { Text } from '../../../../Components/Screen/Text/Body'
import { Button, ButtonOutline } from '../../../../Components/Screen/Button/button'
import { CircleLight } from '../../../../Components/Screen/circleLight'
import { useQuery } from '@tanstack/react-query'
import { getAllHarvests } from '../../../../utils/firebase/harvests'
import { useGlobalContext } from '../../../../Context/GlobalContext'
import { getDownloadURL, uploadBytesResumable, ref as refStorage } from 'firebase/storage'
import { storage, db } from '../../../../firebase'
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom'
import { ref, update } from 'firebase/database'
import { Loading } from '../../../../Components/Screen/Loading/Loading'
import { getAllUsers } from '../../../../utils/firebase/users'
import { getAllAssociates } from '../../../../utils/firebase/get-types-of-user'
import { wineryUpdatedDocumentsLog } from '../../../../utils/logs/winery-added-documents'

export default function HarvestRegisterPage() {
  const params = new URLSearchParams(window.location.search)
  const navigate = useNavigate()
  const harvestsProps = params.get('selected')

  const { userData, farms } = useGlobalContext()
  const { data: harvests } = useQuery({
    queryKey: ['harvests'],
    queryFn: () =>
      getAllHarvests()
        .then((items) => {
          return items.filter((item) => harvestsProps?.includes(item.id))
        })
    ,
    enabled: !!harvestsProps && !!userData?.id,
  })

  const { data: users } = useQuery({
    queryKey: ['users'],
    queryFn: () =>
      getAllUsers()
        .then((items) => {
          return items
        })
    ,
    enabled: !!harvestsProps && !!userData?.id,
  })

  const [provenanceModal, setProvenanceModal] = useState(false)
  const [artExhibitionModal, setArtExhibitionModal] = useState(false)
  const [certificateModal, setCertificateModal] = useState(false)
  const [bookModal, setBookModal] = useState(false)
  const [productionFiles, setProductionFiles] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [formData, setFormData] = useState({})

  async function handleSubmit(event) {
    try {
      event.preventDefault()
      setLoading(true)
      let downloadURLs = []
      let totalProgress = 0
      let previousProgress = new Array(productionFiles?.length).fill(0)

      for (let i = 0; i < productionFiles.length; i++) {
        const file = productionFiles[i]
        const metadata = {
          contentType: file.type,
        }
        const randomId = uuidv4()
        const storageRef = refStorage(storage, `productionFiles/${userData?.id}/${randomId}`)

        const uploadTask = uploadBytesResumable(storageRef, file, metadata)

        // eslint-disable-next-line no-loop-func
        const url = await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // Calculate the progress of the current file
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              // Subtract the previous progress of the current file from the total progress
              totalProgress -= previousProgress[i] / productionFiles.length
              // Add the new progress of the current file to the total progress
              totalProgress += progress / productionFiles.length
              // Update the previous progress of the current file
              previousProgress[i] = progress
              setUploadProgress(totalProgress)
            },
            (error) => {
              reject(error)
            },
            async () => {
              await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                resolve(url)
              })
            }
          )
        })

        downloadURLs.push({
          name: file.name,
          url: url,
        })
      }

      const request_id = uuidv4()

      await update(ref(db, `requests/${request_id}`), {
        ...formData,
        farmIds: harvests.map((harvest) => harvest.farmId),
        harvestIds: harvests.map((harvest) => harvest.id),
        id: request_id,
        productionFiles: downloadURLs,
        status: 'in_progress',
        timesFilled: 0,
        bottles_certified: 0,
        created_at: new Date().toISOString()
      })
      const associates = await getAllAssociates()

      await Promise.all(harvests.map(async (harvest) => {
        await update(ref(db, `harvests/${harvest.id}`), {
          request_id: request_id,
          status: 'in_progress',
          updated_in: new Date().toISOString()
        })
      }))

      for await (const associate of associates) {
        await wineryUpdatedDocumentsLog(userData?.id, associate?.id, userData?.name, request_id)
      }


      setLoading(false)
      navigate('/production/register/success')
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }
  const props = {
    provenanceModal,
    setProvenanceModal,
    artExhibitionModal,
    setArtExhibitionModal,
    certificateModal,
    setCertificateModal,
    bookModal,
    setBookModal,
    formData,
    setFormData,
    harvests,
    farms,
    users
  }

  if (!harvests && !users) return <Loading />

  return (
    <div className="bg-preto min-h-[calc(100vh_-_80px)] flex flex-col justify-between">
      <CircleLight position={'top-0'} />
      <CircleLight left position={'bottom-0'} />

      <section className="w-full max-w-screen-xl flex flex-col gap-5 self-center p-10">

        <div className='w-full flex justify-between items-center gap-5'>

          <FirstFormModal props={props} />

          <img
            src={require("./assets/2.jpg")}
            className="w-full max-w-[500px] rounded-3xl"
            alt=""
          />
        </div>

        <div className='w-full flex justify-between items-center gap-5'>
          <img
            src={require("./assets/3.jpg")}
            className="w-full max-w-[500px] rounded-3xl"
            alt=""
          />
          <SecondFormModal props={props} />
        </div>

        <div className='w-full flex justify-between items-center gap-5'>

          <div className="flex flex-col gap-8 ">
            <Heading className={"flex items-center text-zinc-50"}>
              3. Carregamento da documentação:  <i
                className={`ml-2 ${productionFiles ? 'text-attention' : ' text-warning'
                  } text-4xl hover:cursor-pointer`}
              >
                {productionFiles ? <Check size={36} /> : <WarningCircle size={36} />}
              </i>
            </Heading>
            <Subheading className={"text-zinc-400 max-w-[500px]"}>Hora de inserir suas evidencias como nota fiscal e documentos adicionais que possam oferecer o máximo de transparência e credibilidade para sua declaração. Você pode carregar um ou vários documentos​</Subheading>


            <input
              type="file"
              id="documentosCultivo"
              multiple
              accept="image/jpg, image/png, image/jpeg, application/pdf, image/tiff"
              className="hidden"
              onChange={(e) => setProductionFiles(e.target.files)}
            />
            <ButtonOutline
              as="label"
              htmlFor="documentosCultivo"
              className="cursor-pointer max-w-fit self-center"
            >
              Carregar  documentos
            </ButtonOutline>

            {productionFiles && productionFiles?.length > 0 && (
              <div className="flex gap-2 w-full flex-wrap pt-2">
                {Array.from(productionFiles).map((file, index) => (
                  <Text key={index} className="text-gray-300 border p-2 rounded-3xl">
                    {file.name.length > 12 ? file.name.slice(0, 12) + '...' : file.name}
                  </Text>
                ))}
              </div>
            )}
            {productionFiles && productionFiles?.length > 0 && (
              <div className="flex items-center gap-1">
                {productionFiles?.length || '0'} arquivo
                {productionFiles?.length > 1 ? 's' : ''} selecionado
                {productionFiles?.length > 1 ? 's' : ''}
                <X
                  size={28}
                  onClick={() => setProductionFiles(null)}
                  className="text-error cursor-pointer"
                />
              </div>
            )}

          </div>


          <img
            src={require("./assets/1.jpg")}
            className="w-full max-w-[500px] rounded-3xl"
            alt=""
          />
        </div>
        {
          uploadProgress > 0 && (
            <div className="w-full flex flex-col gap-2">
              <Text className="text-gray-300">
                Enviando arquivos...
              </Text>
              <div className="w-full bg-gray-300 h-2 rounded-3xl">
                <div
                  className="bg-primary h-2 rounded-3xl"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            </div>
          )
        }
        <div className="w-full flex flex-row-reverse pt-10">
          <Button
            disabled={!productionFiles || isLoading}
            onClick={handleSubmit}
          >
            <p className='lg:text-2xl'>Concluir</p>
          </Button>
        </div>
      </section >
    </div >
  )
}
