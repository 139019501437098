import { child, get, ref } from "firebase/database";
import { db } from "../../firebase";

export async function getUserById(userId) {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `/users`));
  if (snapshot?.exists()) {
    const usersSnap = snapshot.val();
    const user = Object.values(usersSnap).find(user => user?.id === userId);
    return user;
  } else {
    return []
  }
}

export async function getAllUsers() {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `/users`));
  if (snapshot?.exists()) {
    const usersSnap = snapshot.val();
    const users = Object.values(usersSnap);
    return users;
  } else {
    return []
  }
}

export async function getUsersByRole(role) {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `/users`));
  if (snapshot?.exists()) {
    const usersSnap = snapshot.val();
    const users = Object.values(usersSnap).filter(user => user?.role === role);
    return users;
  } else {
    return []
  }
}

