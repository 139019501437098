import { useGlobalContext } from '../../../../Context/GlobalContext'
import { useNavigate } from 'react-router-dom'
import { User } from 'phosphor-react'

export const InfosMobile = () => {
  const { userData: user } = useGlobalContext()
  const navigate = useNavigate()
  function handleProfile(navigate) {
    return navigate(`/profile/`)
  }
  return (
    <div className="flex flex-row gap-2 items-center">
      {user ? (
        <img
          src={user?.profilePicture}
          alt="Avatar"
          className="w-8 h-8 rounded-full cursor-pointer object-cover object-center"
          onClick={() => handleProfile(navigate)}
        />
      ) : (
        <User size={24} />
      )}
    </div>
  )
}
