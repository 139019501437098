import { child, get, ref } from "firebase/database";
import { db } from "../../firebase";

export async function getAllRequests() {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `/requests`));
  if (snapshot?.exists()) {
    const requestsSnap = snapshot.val();
    const requests = Object.values(requestsSnap)
    return requests;
  } else {
    return []
  }
}

export async function getRequestById(requestId) {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `/requests`));
  if (snapshot?.exists()) {
    const requestsSnap = snapshot.val();
    const request = Object.values(requestsSnap).find(request => request?.id === requestId);
    return request;
  } else {
    return []
  }
}