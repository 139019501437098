import 'primeicons/primeicons.css'
import 'primereact/resources/themes/arya-green/theme.css'
import 'primereact/resources/primereact.min.css'
import { useEffect, useState } from 'react'
import { InputNumber } from 'primereact/inputnumber'
import { Check, WarningCircle, X } from 'phosphor-react'
import { Heading, Subheading } from '../../../../Components/Screen/Text/Heading'
import { Text } from '../../../../Components/Screen/Text/Body'
import { ButtonOutline } from '../../../../Components/Screen/Button/button'
import { Loading } from '../../../../Components/Screen/Loading/Loading'
import { InputSwitch } from 'primereact/inputswitch'
import { InputCrop } from './FirstFormModal'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
// import { getWineColorByType } from '../../../../utils/get-wine-color-by-type'
// import seeds from '../../../../assets/seeds.json'
// import { Dropdown } from 'primereact/dropdown'
// import { set } from 'firebase/database'

export function SecondFormModal({ props }) {
  const { bookModal, setBookModal, formData, setFormData, harvests } = props
  const [addLoading, setAddLoading] = useState(false)
  const [formIsDone, setFormIsDone] = useState(false)
  const [seedType, setSeedType] = useState(formData?.wineDetails?.seedType || '')
  const [density, setDensity] = useState(formData?.wineDetails?.density || '')
  const [alcoholicContent, setAlcoholicContent] = useState(formData?.wineDetails?.alcoholicContent || '')
  const [totalAcidity, setTotalAcidity] = useState(formData?.wineDetails?.totalAcidity || '')
  const [volatileAcidity, setVolatileAcidity] = useState(formData?.wineDetails?.volatileAcidity || '')
  const [fixedAcidity, setFixedAcidity] = useState(formData?.wineDetails?.fixedAcidity || '')
  const [ph, setPh] = useState(formData?.wineDetails?.ph || '')
  const [reducingSugars, setReducingSugars] = useState(formData?.wineDetails?.reducingSugars || '')
  const [reducedDryExtract, setReducedDryExtract] = useState(formData?.wineDetails?.reducedDryExtract || '')
  const [freeSO2, setFreeSO2] = useState(formData?.wineDetails?.freeSO2 || '')
  const [totalSO2, setTotalSO2] = useState(formData?.wineDetails?.totalSO2 || '')
  const [lotNumber, setLotNumber] = useState(formData?.wineDetails?.lotNumber || '')
  const [city, setCity] = useState(formData?.wineDetails?.city || '')
  const [polyphenolIndex, setPolyphenolIndex] = useState(formData?.wineDetails?.polyphenolIndex || '')
  const [totalColorIntensity, setTotalColorIntensity] = useState(formData?.wineDetails?.totalColorIntensity || '')
  const [safraYear, setSafraYear] = useState(formData?.harvestDeclaration?.year || new Date())



  function handleModal() {
    setBookModal(!bookModal)
  }


  async function handleSubmit(event) {
    event.preventDefault()
    setAddLoading(true)

    const wineDetails = {
      seedType,
      density,
      alcoholicContent,
      totalAcidity,
      volatileAcidity,
      fixedAcidity,
      ph,
      reducingSugars,
      reducedDryExtract,
      freeSO2,
      totalSO2,
      lotNumber,
      city,
      polyphenolIndex,
      totalColorIntensity,
      safraYear
    }

    setFormIsDone(true)
    setFormData((prevState) => ({ ...prevState, wineDetails }))
    setAddLoading(false)
    setBookModal(false)

  }
  const wineColor = formData?.harvestDeclaration?.wineType === 'Tinto' ? 'Tinto' : 'Branco/Espumante'

  useEffect(() => {
    if (harvests?.length === 0) return
    const seed_types = harvests.map((harvest) => harvest.seed_type)
    const seed_types_set = new Set(seed_types)
    const seed_types_array = Array.from(seed_types_set)
    const seed_types_string = seed_types_array.join(' - ')
    setSeedType(seed_types_string)

  }, [harvests])

  return (
    <>
      <div className="flex items-start flex-col w-full max-w-[500px] gap-8">
        <Heading className="cursor-pointer text-branco flex items-center" onClick={handleModal}>
          2. Informações do vinho: <i
            onClick={handleModal}
            className={`ml-2 ${formIsDone ? 'text-attention' : ' text-warning'
              } text-4xl hover:cursor-pointer`}
          >
            {formIsDone ? <Check size={36} /> : <WarningCircle size={36} />}
          </i>
        </Heading>

        <Subheading className={"text-zinc-400"}>
          Agora vamos inserir as especificações da uva. Esta informação será muito útil para poder analisar componentes e propriedades do seu produto no processo de certificação.
        </Subheading>

        <ButtonOutline onClick={handleModal} className="w-[200px] self-center">
          Acessar formulário
        </ButtonOutline>
      </div>
      {bookModal && (
        <div className="modal modal-open w-full backdrop-blur overflow-auto">
          <div className="relative  bg-preto border border-gray-500  rounded-3xl w-[90%] max-w-[650px] flex flex-col justify-center items-center py-10 my-10">
            {!addLoading && (
              <X
                size={32}
                onClick={handleModal}
                className="absolute  text-attention right-4 top-4 hover:cursor-pointer z-30"
              />
            )}

            {addLoading ? (
              <Loading />
            ) : (
              <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                <Subheading
                  as="h1"
                  className="text-2xl font-bold text-center text-attention lg:text-4xl"
                >
                  Informações do vinho
                </Subheading>

                <Text className={"lg:text-xl text-zinc-50"}>Tipo: {formData?.harvestDeclaration?.wineType ||
                  <span className='text-red-500'>

                    Preencha o tipo de vinho no formulário anterior
                  </span>
                }
                </Text>
                <div className="w-full flex flex-row gap-12">

                  <div className='flex flex-col gap-2'>

                    {wineColor === 'Tinto' && (
                      <>
                        <InputCrop
                          name={"Índice de polifenóis totais – IPT (280nm) "}
                          value={polyphenolIndex}
                          setValue={setPolyphenolIndex}
                        />
                        <InputCrop
                          name={"Intensidade Total de Cor"}
                          value={totalColorIntensity}
                          setValue={setTotalColorIntensity}
                        />
                      </>
                    )}



                    <InputCrop
                      name={'Uva'}
                      value={seedType}
                      setValue={() => { '' }}
                    />

                    <InputCrop
                      name={'Densidade (20/20ºC)'}
                      value={density}
                      setValue={setDensity}
                    />
                    <InputCrop
                      name={'Teor alcoólico (%v/v - 20ºC)'}
                      value={alcoholicContent}
                      setValue={setAlcoholicContent}
                    />
                    <InputCrop
                      name={'Acidez Total (mEq/l)'}
                      value={totalAcidity}
                      setValue={setTotalAcidity}
                    />

                    <div className="w-full flex flex-col">
                      <Text
                        as={'label'}
                        htmlFor={'Yield Estimation'}
                        className="flex gap-2 text-branco mb-2"
                      >
                        Acidez Volátil (mEq/l)
                      </Text>
                      <InputNumber
                        value={volatileAcidity}
                        onValueChange={(e) => setVolatileAcidity(e.value)}
                        suffix=" mEq/l"
                        min={1}
                        minFractionDigits={2} maxFractionDigits={5}
                        className="calendarInput"
                      />
                    </div>

                    <div className="w-full flex flex-col">
                      <Text
                        as={'label'}
                        htmlFor={'Yield Estimation'}
                        className="flex gap-2 text-branco mb-2"
                      >
                        Acidez Fixa (mEq/l)
                      </Text>
                      <InputNumber
                        value={fixedAcidity}
                        onValueChange={(e) => setFixedAcidity(e.value)}
                        suffix=" mEq/l"
                        min={1}
                        minFractionDigits={2} maxFractionDigits={5}
                        className="calendarInput"
                      />
                    </div>
                    {wineColor !== 'Tinto' &&
                      <InputCrop
                        name={'Ph'}
                        value={ph}
                        setValue={setPh}
                      />
                    }
                  </div>

                  <div className='flex flex-col gap-3'>
                    {wineColor === 'Tinto' &&
                      <InputCrop
                        name={'Ph'}
                        value={ph}
                        setValue={setPh}
                      />
                    }
                    <div className="w-full flex flex-col ">
                      <Text as="label" htmlFor={'Harvest Year'}>
                        Ano da safra
                      </Text>
                      <Calendar
                        id="yearpicker"
                        name="yearpicker"
                        value={safraYear}
                        onChange={(e) => setSafraYear(e.value)}
                        view="year"
                        dateFormat="yy"
                        className="calendarInput"
                        maxDate={new Date(+new Date() + (31536000000 * 2))}
                      />
                    </div>
                    <InputCrop
                      name={'Açúcares redutores totais (g/L)'}
                      value={reducingSugars}
                      setValue={setReducingSugars}
                    />
                    <InputCrop
                      name={'Extrato Seco Reduzido (g/L)'}
                      value={reducedDryExtract}
                      setValue={setReducedDryExtract}
                    />

                    <InputCrop
                      name={'SO2 Livre (mg/L)'}
                      value={freeSO2}
                      setValue={setFreeSO2}
                    />
                    <InputCrop
                      name={'SO2 Total (mg/L)'}
                      value={totalSO2}
                      setValue={setTotalSO2}
                    />
                    <InputCrop
                      name={'Nº Lote'}
                      value={lotNumber}
                      setValue={setLotNumber}
                    />
                    <Text as="label">
                      Município
                    </Text>
                    <Dropdown
                      value={city}
                      options={[
                        { name: 'Garibaldi', value: 'Garibaldi' },
                        { name: 'Bento Gonçalves', value: 'Bento Gonçalves' },
                        { name: 'Monte Belo do Sul', value: 'Monte Belo do Sul' },
                      ]}
                      onChange={(e) => setCity(e.value)}
                      optionLabel="name"
                      editable
                      className="calendarInput"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center mt-8">
                  <ButtonOutline>
                    {addLoading ? 'Carregando...' : 'Salvar'}
                  </ButtonOutline>
                </div>
              </form>
            )}
          </div>
        </div>
      )}

    </>
  )
}

export const InputSwitchForm = ({ name, value, setValue }) => {
  return (
    <div className={`flex gap-5 justify-between ${value === true ? '' : 'w-full'}`}>
      <Text as="label" htmlFor={name} className="flex  text-branco">
        {name}
      </Text>
      <InputSwitch
        onChange={(e) => setValue(e.value)}
        id={name}
        checked={value}
      />
    </div>
  )
}

