import { CircleLight } from '../../../../Components/Screen/circleLight'

export const BgCircles = () => {
  return (
    <>
      <CircleLight position="top-[10%]" left bgColor="bg-accent" />

      <CircleLight position="top-[20%]" bgColor="bg-accent" />

      <CircleLight position="top-[30%]" left bgColor="bg-accent" />

      <CircleLight position="top-[40%]" bgColor="bg-accent" />

      <CircleLight position="top-[50%]" left bgColor="bg-accent" />

      <CircleLight position="top-[60%]" bgColor="bg-accent" />

      <CircleLight position="top-[70%]" left bgColor="bg-accent" />

      <CircleLight position="top-[80%]" bgColor="bg-accent" />
    </>
  )
}
