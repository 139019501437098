import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { SvgStep3 } from '../assets/SvgStep3'

export const Step3 = ({ mediaQuery }) => {
  return (
    <article className="flex flex-col gap-4 my-10 lg:flex-row lg:justify-between lg:items-center">
      <div className="flex flex-col gap-3">
        <Text className="flex flex-row items-center gap-4">
          <SvgStep3 />
          <Text className="flex flex-col text-white">
            Etapa 3 <Heading className="text-attention">Prova de origem</Heading>
          </Text>
        </Text>
        <ul className="px-6 font-medium list-disc lg:text-lg">
          <li>
            <Text>
              Toda a traçabilidade disponível da vinícola até o atual
              proprietário
            </Text>
          </li>
          <li>
            <Text>Histórico da vinícola</Text>
          </li>
          <li>
            <Text>
              Todo o caminho que a vinícola percorreu, proprietário a
              proprietário, da vinícola até o atual proprietário.
            </Text>
          </li>
          <li>
            <Text>
              Incluindo, quando possível, as galerias onde foram negociadas
            </Text>
          </li>
          <li>
            <Text>
              As informações muitas vezes são difíceis de rastrear, provenientes
              de estudos do trabalho da vinícola e relatórios
            </Text>
          </li>
          <li>
            <Text>
              Sempre sujeito à aprovação e questionamento da comunidade
            </Text>
          </li>
          <li>
            <Text>
              Também considera exposições públicas, certificados e citações em
              livros
            </Text>
          </li>
        </ul>
      </div>
      {mediaQuery && (
        <img
          src={require('../assets/step3.jpg')}
          alt="art"
          className="w-1/3 h-[20rem] rounded-3xl"
          loading="eager"
        />
      )}
    </article>
  )
}
