import { ref, update } from 'firebase/database'
import {
  getDownloadURL,
  uploadBytesResumable,
  ref as refStorage,
} from 'firebase/storage'
import { File, FilePlus } from 'phosphor-react'
import { useState } from 'react'
import { auth, db, storage } from '../../../firebase'
import { validateSize } from '../../../utils/validateSizeFile'
import { useGlobalContext } from '../../../Context/GlobalContext'

export const ChangeBackgroundPic = (props) => {
  const { setIsGearBackgroundOpen } = props
  const [backgroundFile, setBackgroundFile] = useState(null)
  const [loadingBackground, setLoadingBackground] = useState(false)
  const [backgroundChangeIsDone, setBackgroundChangeIsDone] = useState(false)
  const [backgroundError, setBackgroundError] = useState(null)
  const { updateUser } = useGlobalContext()

  async function changeBackgroundPicture(e) {
    e.preventDefault()
    setLoadingBackground(true)
    const file = backgroundFile
    const userId = auth?.currentUser?.uid
    const metadata = {
      contentType: 'image/jpeg',
    }
    let downloadURL
    const backgroundPicture = await new Promise((resolve, reject) => {
      const storageRef = refStorage(
        storage,
        'images/' + userId + '/profile/' + file?.name,
      )
      const uploadTask = uploadBytesResumable(storageRef, file, metadata)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.info('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case 'paused':
              console.info('Upload is paused')
              break
            case 'running':
              console.info('Upload is running')
              break
            default:
              break
          }
        },
        (error) => {
          reject(error)
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            downloadURL = url
            resolve(downloadURL)
          })
        },
      )
    })

    if (backgroundPicture) {
      await update(ref(db, 'users/' + userId), {
        backgroundPicture,
      })
      await updateUser()
      setLoadingBackground(false)
      setBackgroundChangeIsDone(true)
    }
  }

  if (backgroundChangeIsDone) {
    setTimeout(() => {
      setBackgroundChangeIsDone(false)
      setBackgroundFile(null)
      setIsGearBackgroundOpen(false)
    }, 3000)
    clearTimeout()
  }

  if (backgroundError) {
    setTimeout(() => {
      setBackgroundError(null)
    }, 3000)
    clearTimeout()
  }
  return (
    <div
      className={`absolute top-1 lg:top-0 right-1 lg:right-0 bg-cinzaescuro/50 backdrop-blur-lg flex flex-col gap-4 w-[58%]  rounded-lg font-light text-white p-4 z-30`}
    >
      <div className="relative flex flex-col gap-7 pt-5 lg:pt-0">
        Mudar imagem de fundo
        <label
          htmlFor="file"
          className="cursor-pointer flex items-center justify-center hover:text-attention"
        >
          {backgroundFile ? <File size={32} /> : <FilePlus size={32} />}
        </label>
        <input
          type="file"
          id="file"
          accept="image/*"
          onChange={(e) =>
            validateSize(
              e.target.files[0],
              setBackgroundError,
              setBackgroundFile,
            )
          }
          className="hidden"
          disabled={loadingBackground}
        />
        {backgroundFile && (
          <div className="flex justify-center">
            <img
              src={URL.createObjectURL(backgroundFile)}
              alt="preview"
              className="object-cover object-center w-full h-32"
              loading="eager"
            />
          </div>
        )}
        {backgroundError && (
          <p className="text-red-500 text-center font-semibold">
            {backgroundError}
          </p>
        )}
        <button
          disabled={loadingBackground || !backgroundFile}
          onClick={changeBackgroundPicture}
          className="p-2 ml-2 select-none bg-attention font-medium text-preto rounded-2xl hover:bg-attention/80 w-1/2 self-center"
        >
          {loadingBackground
            ? 'Carregando...'
            : backgroundChangeIsDone
            ? 'Done!'
            : 'Upload'}
        </button>
      </div>
    </div>
  )
}
