export function mapLocation(winery) {
  switch (winery.toLowerCase()) {
    case "dom candido": {
      return {
        lat: -29.177038166736263,
        lng: -51.555054158663125
      }
    }
    case "pizzato": {
      return {
        lat: -29.17574707851116,
        lng: -51.60261548404595
      }

    }
    default: {
      return {
        lat: -29.19672532789907,
        lng: -51.55859850173308
      }
    }
  }
}

