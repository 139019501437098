import { useQuery } from "@tanstack/react-query";
import { CircleLight } from "../../../Components/Screen/circleLight";
import { useGlobalContext } from "../../../Context/GlobalContext";
import { FirstFormViewModal } from "./FirstFormModal";
import { SecondFormApproveModal } from "./SecondFormModal";
import { Button, ButtonOutline } from "../../../Components/Screen/Button/button";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../Components/Screen/Loading/Loading";
import { getRequestById } from "../../../utils/firebase/requests";

export function ViewProductionPage() {
  const { userData } = useGlobalContext()
  const params = useLocation()
  const id = params.pathname.split('/').pop()
  const navigate = useNavigate()
  const [bottles_certified, setBottlesCertified] = useState(0)

  const { data: request } = useQuery({
    queryKey: ['request', id],
    queryFn: () =>
      getRequestById(id).then((item) => {
        if (bottles_certified > 0) return item
        const bottles = Math.round((item?.harvestDeclaration?.quantity * 1.25) / 1000) * 1000
        setBottlesCertified(bottles)
        item.bottles_certified = bottles
        return item
      })
    ,
    enabled: !!userData?.id
  })

  const files = request?.productionFiles

  if (!request) return <Loading />

  return (
    <div className="bg-preto min-h-[calc(100vh_-_80px)] flex flex-col">
      <CircleLight />
      <CircleLight left position={'bottom-0'} />
      <section className="px-8 lg:px-20 items-center justify-center flex flex-col gap-5 pt-10">

        <div className="flex flex-col lg:flex-row gap-12 items-stretch">

          {request && <FirstFormViewModal harvest={request} />}
          <div className="flex flex-col space-y-10">
            {request && <SecondFormApproveModal harvest={request} />}
            <div className="flex flex-wrap items-center gap-5 max-w-[800px] ">
              <p>Arquivos: </p>
              {files?.map((file, index) => {
                return <div key={index} className="flex items-center gap-3">

                  <Button onClick={() => window.open(file.url, '_blank')}>{file.name.length > 15 ? file.name.slice(0, 15) + '...' : file.name}</Button>
                </div>
              })}

            </div>
          </div>
        </div>
        <ButtonOutline onClick={() => navigate('/profile')}>Voltar</ButtonOutline>
      </section>
    </div>
  )
}