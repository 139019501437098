import 'primeicons/primeicons.css'
import { InputMask } from 'primereact/inputmask'
import { ref, update } from 'firebase/database'
import { getDownloadURL, uploadBytesResumable, ref as refStorage } from 'firebase/storage'
import { useCallback, useEffect, useState } from 'react'
import { auth, storage, db } from '../../../../firebase'
import { File, FilePlus, Spinner, X } from 'phosphor-react'
import { validateSize } from '../../../../utils/validateSizeFile'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { InputDiv, Input as InputMy } from '../../../../Components/Screen/Input/Text'
import { getAddress } from '../../../../utils/address/getAddressByCEP'
import { Button, ButtonSecondary } from '../../../../Components/Screen/Button/button'
import { InstructiveModal } from './InstructiveModal'
import { useGlobalContext } from '../../../../Context/GlobalContext'
import { NotLogged } from '../../../Error/NotLogged'
import { CircleLight } from '../../../../Components/Screen/circleLight'
import { useNavigate } from 'react-router-dom'
import { debounce } from '../../../../utils/debounce'
import { returnUserRole } from '../../../../utils/returnUserRole'
import { Loading } from '../../../../Components/Screen/Loading/Loading'
import { Step0ArtRegister } from '../Step0/ArtRegisterStep0'
import ArtRegisterStep1Page from '../Step1/ArtRegisterStep1'
import { v4 as uuidv4 } from 'uuid'
import { Dropdown } from 'primereact/dropdown'
import { Text } from '../../../../Components/Screen/Text/Body'


export default function RegisterFarmPage() {
  const [step, setStep] = useState(1)
  const navigate = useNavigate()
  const { userData, updateUser } = useGlobalContext()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const [name, setName] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [address, setAddress] = useState('')
  const [matricula, setMatricula] = useState('')
  const [stateInscription, setStateInscription] = useState('')
  const [localization, setLocalization] = useState('')
  const [localization2, setLocalization2] = useState('')
  const [car, setCar] = useState('')
  const [haveCar, setHaveCar] = useState(false)
  const [pra, setPra] = useState('')
  const [havePra, setHavePra] = useState(false)
  const [rented, setRented] = useState(false)
  const [rentedName, setRentedName] = useState('')
  const [rentedCpf, setRentedCpf] = useState('')

  const [propertyFiles, setPropertyFiles] = useState([])
  const [instructiveModal, setInstructiveModal] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0);
  const [crocImage, setCrocImage] = useState(null)
  const userId = auth?.currentUser?.uid
  const userRole = returnUserRole(userData?.role)


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetAddress = useCallback(
    debounce((zip) => {
      if (zip.length === 8 && !zip.includes('_') && !zip.includes('-') && zip !== zipCode) {
        getAddress(zip).then((res) => {
          res?.lat && setLocalization(res?.lat);
          res?.lng && setLocalization2(res?.lng);
          res?.address && setAddress(res?.address);
        });
      }
    }, 500),
    [], // will be created only once initially
  );

  const handleInputChange = (event) => {
    const zipCodeInput = event.target.value.replaceAll('-', '').replaceAll('_', '');
    const zip = zipCodeInput
    setZipCode(zip);
    debouncedGetAddress(zip);
  };

  function formatZipToShow(zip) {
    if (zip.length > 8) {
      return zip.slice(0, 8);
    }
    return zip.replace(/(\d{5})(\d{3})/, '$1-$2')
  }

  function formatCnpjToShow(cnpj) {
    if (cnpj.length > 18) {
      return cnpj.slice(0, 18)
    }
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  }

  async function saveFarmInFirebase(artToSave) {
    try {
      const randomId = uuidv4()
      const farms = userData?.farms || []
      await update(ref(db, `users/${userId}`), {
        farms: [...farms, randomId]
      })
      await update(ref(db, `farms/${randomId}`), {
        ...artToSave,
        ownerId: userId,
        id: randomId
      })

      await updateUser()
      return randomId
    } catch (err) {
      console.error(err)
    }
  }

  async function handleSubmit(event) {
    try {
      event.preventDefault()
      setLoading(true)
      let downloadURLs = []
      let totalProgress = 0
      let previousProgress = new Array(propertyFiles?.length).fill(0)

      if (propertyFiles?.length > 0) {
        for (let i = 0; i < propertyFiles?.length; i++) {
          const file = propertyFiles[i]
          const metadata = {
            contentType: file.type,
          }

          const storageRef = refStorage(storage, 'images/' + auth?.currentUser?.uid + '/' + name + car + '/' + file?.name)

          const uploadTask = uploadBytesResumable(storageRef, file, metadata)

          // eslint-disable-next-line no-loop-func
          const url = await new Promise((resolve, reject) => {
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                // Calculate the progress of the current file
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                // Subtract the previous progress of the current file from the total progress
                totalProgress -= previousProgress[i] / propertyFiles.length
                // Add the new progress of the current file to the total progress
                totalProgress += progress / propertyFiles.length
                // Update the previous progress of the current file
                previousProgress[i] = progress
                setUploadProgress(totalProgress)
              },
              (error) => {
                reject(error)
              },
              async () => {
                await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                  resolve(url)
                })
              }
            )
          })

          downloadURLs.push(url)
        }
      }

      let crocImageUrl = ''
      if (crocImage) {
        const metadata = {
          contentType: crocImage.type,
        }

        const storageRef = refStorage(storage, 'images/' + auth?.currentUser?.uid + '/' + name + car + '/' + crocImage?.name)

        const uploadTask = uploadBytesResumable(storageRef, crocImage, metadata)

        crocImageUrl = await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // Calculate the progress of the current file
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              setUploadProgress(progress)
            },
            (error) => {
              reject(error)
            },
            async () => {
              await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                resolve(url)
              })
            }
          )
        })
      }

      const newFarm = { name, address, matricula, car, pra, propertyFiles: downloadURLs, crocImage: crocImageUrl, cnpj, zipCode, stateInscription, localization, rented, rentedName, rentedCpf, localization2, haveCar, havePra }
      await saveFarmInFirebase(newFarm)

      setLoading(false)
      navigate('/farm/register/success')
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (haveCar === false) {
      setCar('')
    }
    if (havePra === false) {
      setPra('')
    }
    if (rented === false) {
      setRentedName('')
      setRentedCpf('')
    }
    // const zip = zipCode.replaceAll('-', '').replaceAll('_', '')

    // if (zip.length === 8) {
    //   getAddress(zip).then((res) => {
    //     res?.lat && setLocalization(res?.lat)
    //     res?.lng && setLocalization2(res?.lng)
    //     res?.address && setAddress(res?.address)
    //   })
    // }
  }, [haveCar, havePra, rented])

  if (!userId) return <NotLogged />

  if (!userData?.role) return <Loading />

  if (userData?.role && userRole !== 'Produtor') return (
    <div className="bg-preto min-h-[calc(100vh_-_80px)] flex flex-col justify-between">
      <CircleLight />
      <CircleLight left position={'bottom-0'} />

      <Heading className={'text-zinc-50 text-center px-12 pt-4 lg:pt-12 justify-center items-center flex-1 flex'}>
        Você não tem permissão para acessar essa página, é necessário ser um produtor para acessar.
      </Heading>
    </div>
  )

  if (step === 1) return (
    <div className="bg-preto min-h-[calc(100vh_-_80px)] flex flex-col justify-between">
      <CircleLight />
      <CircleLight left position={'bottom-0'} />

      <Step0ArtRegister {...{ setStep }} />
    </div>
  )

  if (step === 2) return (
    <div className="bg-preto min-h-[calc(100vh_-_80px)] flex flex-col justify-between">
      <CircleLight />
      <CircleLight left position={'bottom-0'} />
      <ArtRegisterStep1Page {...{ setStep }} />
    </div>
  )

  if (step === 3) return (

    <div className="bg-preto min-h-[calc(100vh_-_80px)] flex flex-col justify-between">
      <CircleLight />
      <CircleLight left position={'bottom-0'} />

      <section
        className="flex flex-col text-white w-full max-w-7xl mx-auto min-h-[calc(100vh-80px)]"
        id="container"
      >
        <Heading className={'text-attention text-center lg:text-start  pt-4 lg:pt-12 lg:pl-24'}>
          Dados da propriedade
        </Heading>
        <form
          onSubmit={handleSubmit}
          className="p-4 flex flex-col md:flex-row gap-3 lg:grid lg:grid-cols-2 lg:gap-4 lg:px-20 w-full lg:pt-10"
        >
          <div className="flex flex-col gap-3 w-full">
            <Input label="Nome da propriedade" value={name} onChange={setName} />

            <div className="w-full mx-auto max-w-lg  z-30">
              <label htmlFor='zipCode' className="text-lg text-branco">
                CNPJ
              </label>
              <InputDiv>
                <InputMy
                  name="zipCode"
                  id="zipCode"
                  value={formatCnpjToShow(cnpj)}
                  onChange={e => setCnpj(e.target.value)}
                  className="w-full pb-2 text-sm bg-transparent placeholder:font-extralight placeholder:text-branco focus:outline-none"
                  required
                />
              </InputDiv>
            </div>

            <div className="w-full mx-auto max-w-lg  z-30">
              <label htmlFor='zipCode' className="text-lg text-branco">
                CEP
              </label>
              <InputDiv>
                <InputMy
                  name="zipCode"
                  id="zipCode"
                  value={formatZipToShow(zipCode)}
                  onChange={handleInputChange}
                  className="w-full pb-2 text-sm bg-transparent placeholder:font-extralight placeholder:text-branco focus:outline-none"
                  required
                />
              </InputDiv>
            </div>

            <Input label="Endereço" value={address} onChange={setAddress} />

            <div className="w-full mx-auto max-w-lg  z-30 flex flex-col gap-2">
              <Text as="label">
                Região do Vale dos Vinhedos
              </Text>
              <Dropdown
                value={matricula}
                options={[
                  { name: 'Garibaldi', value: 'Garibaldi' },
                  { name: 'Bento Gonçalves', value: 'Bento Gonçalves' },
                  { name: 'Monte Belo do Sul', value: 'Monte Belo do Sul' },
                ]}
                onChange={(e) => setMatricula(e.value)}
                optionLabel="name"
                editable
                className="calendarInput"
              />
            </div>
            <Input
              label="Estado"
              value={stateInscription}
              onChange={setStateInscription}
            />
            <Input
              label="Latitude"
              value={localization}
              onChange={setLocalization}
            />

            <Input
              label="Longitude"
              value={localization2}
              onChange={setLocalization2}
            />
          </div>

          <div className="flex flex-col gap-3 w-full z-30">
            <div className="flex items-center">
              <Input
                label="CAR"
                value={car}
                onChange={setCar}
                disabled={!haveCar}
              />
              <div className="flex flex-col items-center w-20 text-xs">
                <label htmlFor="haveCar">CAR</label>
                <input
                  id="haveCar"
                  type="checkbox"
                  className="checkbox checkbox-primary"
                  checked={haveCar}
                  onChange={() => setHaveCar(!haveCar)}
                />
              </div>
            </div>
            <div className="flex items-center">
              <Input
                label="PRA"
                value={pra}
                onChange={setPra}
                disabled={!havePra}
              />
              <div className="flex flex-col items-center w-20 text-xs">
                <label htmlFor="havePra">PRA</label>
                <input
                  id="havePra"
                  className="checkbox checkbox-primary"
                  type="checkbox"
                  checked={havePra}
                  onChange={() => setHavePra(!havePra)}
                />
              </div>
            </div>

            <div className="flex items-center">
              <Input
                label="Nome do Arrendatário"
                value={rentedName}
                onChange={setRentedName}
                disabled={!rented}
              />
              <div className="flex flex-col items-center w-20 text-xs">
                <label htmlFor="arrendado">Arrendado</label>
                <input
                  id="arrendado"
                  className="checkbox checkbox-primary"
                  type="checkbox"
                  checked={rented}
                  onChange={() => setRented(!rented)}
                />
              </div>
            </div>
            <div className="flex items-center">
              <InputMasked
                label="CPF do Arrendatário"
                value={rentedCpf}
                onChange={setRentedCpf}
                disabled={!rented}
                mask="999.999.999-99"
              />
              <div className="flex flex-col items-center w-20 text-xs">
                <label htmlFor="arrendado">Arrendado</label>
                <input
                  id="arrendado"
                  className="checkbox checkbox-primary"
                  type="checkbox"
                  checked={rented}
                  onChange={() => setRented(!rented)}
                />
              </div>
            </div>

            <div className="lg:mx-auto flex items-center flex-col gap-4 pt-16">

              <input
                type="file"
                id="fileWithoutQR"
                multiple
                accept="image/jpg, image/png, image/jpeg, application/pdf, image/tiff"
                className="hidden"
                onChange={async (e) => {
                  for (const file of e.target.files) {
                    validateSize(file, setError, setPropertyFiles)
                  }
                  setPropertyFiles([...e.target.files])
                }
                }
              />
              <input
                type="file"
                id="crocImage"
                accept="image/jpg, image/png, image/jpeg, image/tiff"
                className="hidden"
                onChange={(e) => {
                  validateSize(e.target.files[0], setError, setCrocImage)
                  setCrocImage(e.target.files[0])
                }
                }
              />


              {error && <p className="text-red-500">{error}</p>}
              <div className='flex gap-10 items-end'>
                <div className='flex flex-col items-center relative'>
                  <label
                    htmlFor="fileWithoutQR"
                    className="cursor-pointer text-secondary flex items-center flex-col gap-3"
                    onClick={() => setError(null)}
                  >
                    {propertyFiles?.length > 0 ? <File size={40} /> : <FilePlus size={40} />}
                    <Button type="button">
                      Documentos
                    </Button>
                  </label>
                  {uploadProgress < 1 && propertyFiles && propertyFiles?.length > 0 && (
                    <div className="flex items-center justify-center gap-1 absolute -bottom-10">
                      <p className="text-xs text-center">
                        {propertyFiles?.length || '0'} arquivo
                        {propertyFiles?.length > 1 ? 's' : ''} selecionado
                        {propertyFiles?.length > 1 ? 's' : ''}
                      </p>
                      {
                        <X
                          size={28}
                          onClick={() => setPropertyFiles(null)}
                          className="text-error cursor-pointer"
                        />
                      }
                    </div>
                  )}
                </div>
                <div className='flex gap-10 items-end'>
                  <div className='flex flex-col items-center relative'>
                    {crocImage && (
                      <div className="flex justify-center pt-2">
                        <img
                          src={URL.createObjectURL(crocImage)}
                          alt="preview"
                          className="object-cover object-center w-full h-32"
                          loading="eager"
                        />
                      </div>
                    )}
                    <label
                      htmlFor="crocImage"
                      className="cursor-pointer text-secondary flex items-center flex-col gap-3"
                      onClick={() => setError(null)}
                    >
                      {crocImage ? <File size={40} /> : <FilePlus size={40} />}
                      <Button type="button">
                        Polígono
                      </Button>
                    </label>
                    {uploadProgress < 1 && crocImage &&
                      <X
                        size={28}
                        onClick={() => setCrocImage(null)}
                        className="text-error cursor-pointer absolute -bottom-10"
                      />
                    }


                  </div>
                </div>

                <InstructiveModal
                  {...{ instructiveModal, setInstructiveModal }}
                />
              </div>
              <div className={`
            ${uploadProgress > 0 ? '' : 'hidden'}
                  pt-4 flex flex-col gap-2
                `}>
                <p>Total de arquivos: {propertyFiles?.length ?? 0}</p>
                <p>Progresso de upload: {Math.round(uploadProgress) ?? 0}%</p>
                <div className='h-2 rounded-3xl w-full bg-slate-100'>
                  <div
                    style={{
                      width: `${Math.round(uploadProgress)}%`,
                    }}
                    className='h-2 rounded-3xl bg-secondary transition-all'
                  />
                </div>
              </div>
            </div>

            <div className="text-right lg:self-end  w-full pt-10 ">
              <ButtonSecondary disabled={loading} type="submit"><p className='lg:text-2xl flex items-center gap-2'>{loading && <Spinner className='animate-spin' />}Concluir</p></ButtonSecondary>
            </div>
          </div>
        </form>
      </section>
    </div >
  )

}

const Input = ({ label, value, onChange, disabled, ...rest }) => {
  return (
    <div className="w-full mx-auto max-w-lg  z-30">
      <label htmlFor={label} className="text-lg text-branco">
        {label}
      </label>
      <InputDiv>
        <InputMy
          type="text"
          disabled={disabled}
          name={label}
          id={label}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          className="w-full text-sm bg-transparent placeholder:font-extralight placeholder:text-branco focus:outline-none"
          required
          {...rest}
        />
      </InputDiv>
    </div>
  )
}

const InputMasked = ({ label, value, onChange, mask, disabled, ...rest }) => {
  return (
    <div className="w-full mx-auto max-w-lg  z-30">
      <label htmlFor={label} className="text-lg text-branco">
        {label}
      </label>
      <InputDiv>
        <InputMask
          disabled={disabled}
          mask={mask}
          name={label}
          id={label}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          className="w-full pb-2 text-sm bg-transparent placeholder:font-extralight placeholder:text-branco focus:outline-none"
          required
          {...rest}
        />
      </InputDiv>
    </div>
  )
}
