import 'primereact/resources/primereact.min.css'
import { useState } from 'react'


import { InputSwitchForm } from './SecondFormModal'
import { useGlobalContext } from '../../../Context/GlobalContext'
import { Subheading } from '../../../Components/Screen/Text/Heading'
import { Loading } from '../../../Components/Screen/Loading/Loading'
import { Text } from '../../../Components/Screen/Text/Body'
import { Input, InputDiv } from '../../../Components/Screen/Input/Text'
import { getUserById } from '../../../utils/firebase/users'
import { useQuery } from '@tanstack/react-query'
import { InputSwitch } from 'primereact/inputswitch'

export function FirstFormViewModal({ harvest }) {
  const { userData } = useGlobalContext()
  const [certificateModal, setCertificateModal] = useState(true)
  const [, setCertificateDone] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const [winesGuard,] = useState(harvest?.harvestDeclaration?.winesGuard || false)
  const [renovation,] = useState(harvest?.harvestDeclaration?.renovation || false)
  const [renovationQuantity,] = useState(harvest?.harvestDeclaration?.renovationQuantity || '')
  const [wineType,] = useState(harvest?.harvestDeclaration?.wineType.charAt(0).toUpperCase() + harvest?.harvestDeclaration?.wineType.slice(1) || '')
  const [yieldEstimation,] = useState(harvest?.harvestDeclaration?.yieldEstimation || '')
  const [comercialBrand,] = useState(harvest?.harvestDeclaration?.comercialBrand || '')
  const [quantity,] = useState(harvest?.harvestDeclaration?.quantity || '')
  const [responsibles,] = useState(harvest?.harvestDeclaration?.responsibles || [])
  // const [orderNumber,] = useState(harvest?.harvestDeclaration?.orderNumber || '')
  // const [invoiceNumber,] = useState(harvest?.harvestDeclaration?.invoiceNumber || '')
  const [variety,] = useState(harvest?.harvestDeclaration?.variety || '')
  const [kiloQuantity,] = useState(harvest?.harvestDeclaration?.kiloQuantity || '')
  const [performance,] = useState(harvest?.harvestDeclaration?.performance || '')
  const [plant,] = useState(harvest?.harvestDeclaration?.plant || '')
  const [babo,] = useState(harvest?.harvestDeclaration?.babo || '')
  const [supplier,] = useState(harvest?.harvestDeclaration?.supplier || '')
  const [bottles_certified,] = useState(harvest?.bottles_certified || 0)
  const [chaptalization,] = useState(harvest?.harvestDeclaration?.chaptalization || false)


  const { data: responsible } = useQuery({
    queryKey: ['responsible'],
    queryFn: () =>
      getUserById(harvest?.harvestDeclaration?.userId).then((user) => {
        return user
      })
    ,
    enabled: !!userData?.id
  })



  async function handleSubmit(event) {
    event.preventDefault()

    setCertificateDone(true)

    setAddLoading(false)
    setCertificateModal(false)
  }
  const invoiceNumber = harvest?.harvestDeclaration?.supplier.map((supplier) => supplier.invoiceNumber).join(', ')
  const orderNumber = harvest?.harvestDeclaration?.supplier.map((supplier) => supplier.orderNumber + " - " + supplier.name).join(', ')

  return (
    <>

      {certificateModal && (
        <div className="">
          <div className="relative bg-preto border border-gray-500 overflow-auto rounded-3xl p-8">

            <Subheading as="h1" className=" text-attention mb-4 text-center lg:text-4xl">
              Declaração da Safra
            </Subheading>

            {addLoading ? (
              <Loading />
            ) : (
              <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                <div className='flex flex-row w-full gap-3 lg:gap-20'>
                  <div className='flex flex-col w-full gap-2'>


                    <div className="w-full flex flex-col gap-3 ">
                      <InputSwitchForm
                        name="Vinho de Guarda​"
                        value={winesGuard}
                        readOnly
                      />

                      <div className={`flex gap-5 justify-between ${renovation === true ? '' : 'w-full'}`}>
                        <Text as="label" htmlFor={'renovation'} className="flex  text-branco">
                          Renovação
                        </Text>

                        <InputSwitch
                          id={'renovation'}
                          checked={renovation}
                          readOnly
                        />
                      </div>

                      {renovation &&
                        <InputCrop
                          readOnly
                          name={'Quantidade de Renovação'}
                          value={renovationQuantity}
                        />
                      }
                    </div>

                    <div className={`flex gap-5 justify-between ${chaptalization === true ? '' : 'w-full'}`}>
                      <Text as="label" htmlFor={'chaptalization'} className="flex  text-branco">
                        Chaptalização até 1º
                      </Text>

                      <InputSwitch
                        id={'chaptalization'}
                        checked={chaptalization}
                        readOnly
                      />
                    </div>

                    <InputCrop
                      readOnly
                      value={wineType}
                      name={"Tipo de Vinho"}
                    />

                    <InputCrop
                      readOnly
                      name={"Tanque/Barril nºs."}
                      value={yieldEstimation}
                    />

                    <InputCrop
                      readOnly
                      name={'Marca Comercial'}
                      value={comercialBrand}
                    />

                    <InputCrop
                      readOnly
                      name={'Quantidade'}
                      value={quantity}
                    />
                    <InputCrop
                      readOnly
                      name={'Quantidade de Garrafas Certificadas'}
                      value={bottles_certified}
                    />
                    <InputCrop
                      readOnly
                      name={'Responsável'}
                      value={responsible?.firstName + ' ' + responsible?.lastName}
                    />

                    <InputCrop
                      readOnly
                      name={'Acompanhantes Autorizados'}
                      value={responsibles}
                    />
                  </div>
                  <div className='flex flex-col w-full gap-2'>

                    <InputCrop
                      readOnly
                      name={'Nº Ordem'}
                      value={orderNumber}
                    />
                    <InputCrop
                      readOnly
                      name={'Nº Nota Fiscal'}
                      value={invoiceNumber}

                    />
                    <InputCrop
                      readOnly
                      name={'Variedade'}
                      value={variety}

                    />

                    <InputCrop
                      readOnly
                      name={'Kg de Uva Total'}
                      value={kiloQuantity}

                    />
                    <InputCrop
                      readOnly
                      name={'Rendimento médio por hequitare'}
                      value={performance}
                    />

                    <InputCrop
                      readOnly
                      name={'Rendimento médio por planta'}
                      value={plant}
                    />
                    <InputCrop
                      readOnly
                      name={'º Babo'}
                      value={babo}
                    />
                    <InputCrop
                      readOnly
                      name={'Fornecedores'}
                      value={supplier.map((supplier) => supplier.name).join(', ')}
                    />
                  </div>
                </div>


              </form>
            )}
          </div>
        </div >
      )
      }
    </>
  )
}

export const InputCrop = ({ name, value, setValue, ...rest }) => {
  return (
    <div className='flex flex-col gap-2'>
      <Text as={'label'} htmlFor={name}>
        {name}
      </Text>
      <InputDiv>
        <Input
          id={name}
          value={value}
          {...rest}

        />
      </InputDiv>
    </div>
  )
}
