import React from 'react'
import { Text } from '../../../../Components/Screen/Text/Body'

export const Infos = (props) => {
  const { farm } = props
  return (
    <div className="flex flex-col gap-4 px-2 lg:px-0">
      <div className="flex gap-3 items-center justify-center w-full lg:text-2xl">
        <div className="flex flex-col gap-3">
          <Text className="text-attention">
            Nome: <Text>{farm?.name}</Text>
          </Text>
          <Text className="text-attention">
            Registro: <Text>{farm?.matricula || "Não informado"}</Text>
          </Text>
          <Text className="text-attention">
            CAR: <Text>{farm?.car || "Não informado"}</Text>
          </Text>
          <Text className="text-attention">
            PRA: <Text>{farm?.pra || "Não informado"}</Text>
          </Text>
        </div>
      </div>
    </div>
  )
}
