import { Logo } from '../../assets/logo'
import useMediaQuery from '../../hooks/useMediaQuery'
import SimpleFooter from '../SimpleFooter/Index'
import { Links } from './components/Desktop/Links'
import { Infos } from './components/Desktop/Infos'
import { LinksMobile } from './components/Mobile/Links'
import { InfosMobile } from './components/Mobile/Infos'

export default function Footer() {
  const mediaQuery = useMediaQuery('(min-width: 768px)')

  const Mobile = () => {
    return (
      <>
        <div className="flex justify-around pt-3">
          <Logo sebraex={100} />
          <InfosMobile />
        </div>
        <LinksMobile />
        <SimpleFooter />
      </>
    )
  }

  const Desktop = () => {
    return (
      <div className="flex flex-col gap-3 py-3">
        <div className="flex justify-around">
          <Logo sebraex width={250} />
          <Links />
          <Infos />
        </div>
        <SimpleFooter />
      </div>
    )
  }

  return (
    <div
      style={{
        background:
          'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(0,0,0, 1) 0%, rgba(0,0,0, 0.7) 67.08%, rgba(70, 144, 213, 0) 100%)',
      }}
      className="w-full z-20"
    >
      {mediaQuery ? <Desktop /> : <Mobile />}
    </div>
  )
}
