import { createContext, useEffect, useState, useContext } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import useMediaQuery from '../hooks/useMediaQuery'
import { auth, db } from '../firebase'
import { child, get, ref } from 'firebase/database'
import { useMutation, useQuery } from '@tanstack/react-query'
import { queryClient } from '..'

export const GlobalContext = createContext({})

export const GlobalContextProvider = ({ children }) => {
  const userId = auth?.currentUser?.uid
  const [mediaQuery, setMediaQuery] = useState('')
  const [currentUser, setCurrentUser] = useState({})
  const mediaQueryMobile = useMediaQuery('(max-width: 768px)')
  const mediaQueryTablet = useMediaQuery('(min-width: 768px) and (max-width: 1280px)')
  const mediaQueryDesktop = useMediaQuery('(min-width: 1280px)')

  const mutation = useMutation({
    mutationFn: () => getUserData(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userData'] })
    },
  })

  async function getUserData() {
    try {
      const dbRef = ref(db)
      const snapshot = await get(child(dbRef, `/users/${userId}`))
      if (snapshot?.exists()) {
        const user = snapshot.val()
        return user
      } 
    } catch (error) {
      console.error(error)
    }
  }

  async function getFarms() {
    try {
      const dbRef = ref(db)
      const snapshot = await get(child(dbRef, `/farms`))
      if (snapshot?.exists()) {
        const farms = snapshot.val()
        return farms
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function updateUser() {
    try {
      await mutation.mutateAsync()
    } catch (error) {
      console.error(error)
    }
  }

  const { data: userData } = useQuery({
    queryKey: ['userData'],
    queryFn: () =>
      getUserData().then((userData) => {
        return userData
      })
    ,
    enabled: !!userId
  })

  const { data: farms } = useQuery({
    queryKey: ['farms'],
    queryFn: () =>
      getFarms().then((farms) => {
        return farms
      })
    ,
    enabled: !!userId
  })


  useEffect(() => {
    const unSub = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })
    return () => {
      unSub()
    }
  }, [])

  useEffect(() => {
    if (mediaQueryMobile) {
      setMediaQuery('mobile')
    } else if (mediaQueryTablet) {
      setMediaQuery('tablet')
    } else if (mediaQueryDesktop) {
      setMediaQuery('desktop')
    }

  }, [mediaQueryMobile, mediaQueryTablet, mediaQueryDesktop])



  return (
    <GlobalContext.Provider
      value={{
        mediaQuery,
        currentUser,
        setCurrentUser,
        updateUser,
        userData,
        farms
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(GlobalContext)
  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalContextProvider',)
  }
  return context
}
