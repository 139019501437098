import { ArrowRight } from 'phosphor-react'
import { Text } from '../../../../Components/Screen/Text/Body'
import { Heading } from '../../../../Components/Screen/Text/Heading'
import { CircleLight } from '../../../../Components/Screen/circleLight'

export default function ApproveHarvestSuccess() {
  return (
    <div className="bg-preto min-h-[calc(100vh_-_80px)] flex flex-col justify-between">
      <CircleLight />
      <CircleLight left position={'bottom-0'} />
      <section className="flex flex-col items-center justify-center md:items-start  md:px-20 xl:px-40 h-[70vh]">
        <div className="w-full flex items-center mt-10 px-5">
          <Heading as="h1" className="text-branco">
            Sua solicitação foi enviada com sucesso!
          </Heading>
        </div>

        <Text as="p" className="px-5 text-branco font-light mt-4">
          <span className="text-attention">Fique atualizado</span> sobre os processos
          no seu perfil.
        </Text>

        <Text as="p" className="px-5 text-branco font-light mt-4">
          A História da uva <span className="text-attention"> agradece </span>
          pela sua contribuição!
        </Text>

        <div className="flex flex-col gap-8 md:gap-0 md:flex-row justify-between items-center w-full mt-20 pt-20 px-5">

          <a href="/profile">
            <div className="text-branco font-light text-xs flex flex-col items-center gap-2 hover:text-attention">
              <div className="text-branco flex items-center gap-2 lg:text-2xl ">
                Voltar para
                <span className="font-bold hover:text-attention">Origem RS</span>
                <i className="text-attention lg:text-4xl">
                  <ArrowRight size={24} />
                </i>
              </div>
            </div>
          </a>
        </div>
      </section>
    </div>
  )
}
