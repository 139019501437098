import { Link } from 'react-router-dom'
import { Button } from '../../../Components/Screen/Button/button'
import { Text } from '../../../Components/Screen/Text/Body'

export const LoginButtons = (props) => {
  const { loading, showModal, setShowModal, error } = props
  return (
    <div className="text-center pt-4 mb-12 pb-1 flex flex-col gap-6 items-center">
      {error && (
        <Text className="text-error">
          Ocorreu um erro, verifique os dados
          <br /> e tente novamente por gentileza
        </Text>
      )}
      <Button className="w-full lg:w-1/2" type="submit" disabled={loading}>
        {loading ? 'Carregando...' : 'Entrar'}
      </Button>
      <section className="flex flex-row gap-4 justify-between px-4 w-full">
        <Link to="/register">
          <Text type="button" className="text-primary hover:brightness-110">
            Registrar
          </Text>
        </Link>
        <div onClick={() => setShowModal(!showModal)}>
          <Text className="cursor-pointer hover:brightness-110">
            Esqueceu sua senha
          </Text>
        </div>
      </section>
    </div>
  )
}
