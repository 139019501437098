import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { ref, update } from 'firebase/database'
import { useEffect, useState } from 'react'

import BillingDetailsFields from './prebuilt/BillingDetailsFields'
import CheckoutError from './prebuilt/CheckoutError'
import Row from './prebuilt/Row'
import SubmitButton from './prebuilt/SubmitButton'
import axios from 'axios'
import styled from '@emotion/styled'
import { auth, db } from '../../firebase'
import { useGlobalContext } from '../../Context/GlobalContext'

const CheckoutForm = ({ props }) => {
  const {
    price,
    handleisBuying,
    artTickets,
    mintTickets,
    userArtTickets,
    userMintTickets,
    setMintTickets,
    setArtTickets,
  } = props
  const { updateUser } = useGlobalContext()
  const [isProcessing, setProcessingTo] = useState(false)
  const [checkoutError, setCheckoutError] = useState()

  const uid = auth?.currentUser?.uid

  const stripe = useStripe()
  const elements = useElements()

  const onSuccessfulCheckout = async () => {
    async function writeUserData() {
      const entradas = parseInt(userMintTickets) + parseInt(mintTickets)
      await update(ref(db, 'users/' + uid), {
        mintTicket: entradas,
      })
      const entradas2 = parseInt(userArtTickets) + parseInt(artTickets)
      await update(ref(db, 'users/' + uid), {
        artTicket: entradas2,
      })
    }
    await writeUserData()
    setProcessingTo(false)
    setMintTickets(0)
    setArtTickets(0)
  }

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError()
  }

  const handleFormSubmit = async (ev) => {
    ev.preventDefault()

    const billingDetails = {
      name: ev.target.name.value,
      email: ev.target.email.value,
      address: {
        city: ev.target.city.value,
        line1: ev.target.address.value,
        state: ev.target.state.value,
        postal_code: ev.target.zip.value,
      },
    }

    setProcessingTo(true)

    try {
      const { data: clientSecret } = await axios.post(
        process.env.REACT_APP_services_api,
        {
          amount: price * 100,
        },
      )
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      })

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message)
        setProcessingTo(false)
        return
      }

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      })

      if (error) {
        setCheckoutError(error.message)
        setProcessingTo(false)
        return
      }

      onSuccessfulCheckout()
      await updateUser()
      setProcessingTo(false)
      handleisBuying()
    } catch (err) {
      setCheckoutError(err.message)
      setProcessingTo(false)
    }
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    window.scrollTo(0, 0)
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <form onSubmit={(e) => handleFormSubmit(e)} className="relative px-4">
      <span style={xButton} onClick={handleisBuying}>
        X
      </span>
      <Row>
        <BillingDetailsFields />
      </Row>
      <Row>
        <CardElementContainer>
          <CardElement
            options={cardElementOpts}
            onChange={handleCardDetailsChange}
          />
        </CardElementContainer>
      </Row>
      {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
      <Row>
        <SubmitButton disabled={isProcessing || !stripe}>
          {isProcessing ? 'Processing...' : <span>Pagar R${price}</span>}
        </SubmitButton>
      </Row>
    </form>
  )
}

export default CheckoutForm

const iframeStyles = {
  base: {
    color: '#fff',
    fontSize: '16px',
    iconColor: '#fff',
    '::placeholder': {
      color: '#fff',
    },
  },
  invalid: {
    iconColor: '#FFC7EE',
    color: '#FFC7EE',
  },
  complete: {
    iconColor: '#cbf4c9',
  },
}

const cardElementOpts = {
  iconStyle: 'solid',
  style: iframeStyles,
  hidePostalCode: true,
}

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`

const xButton = {
  color: 'white',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  textAlign: 'center',
  top: '-22px',
  right: '16px',
  position: 'absolute',
  background: 'rgba(0,0,0,0.5)',
  borderRadius: '14px',
  padding: '0.5rem 1rem',
  cursor: 'pointer',
}
